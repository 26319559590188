import { Box, Flex, Text } from "@chakra-ui/react";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ChatFile } from "../../types";
import { getChatFilesApi } from "../../api";



interface IFileUploadContext {
  open: () => void;
  isDragActive: boolean;
  files: ChatFile[];
  deleteFile: (index: number) => void;
  deleteAllFiles: () => void;
  renderContainerHeight: number,
  images: {
    [ index: string ]: string
  }
}


export const FileUploadContext = React.createContext<IFileUploadContext>({
  open: () => { },
  deleteFile: () => { },
  deleteAllFiles: () => { },
  isDragActive: false,
  files: [],
  renderContainerHeight: 70,
  images: {},
});

const FileUpload: React.FC<PropsWithChildren> = ({ children }) => {
  const [ files, setFiles ] = useState<ChatFile[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log(files, acceptedFiles);
    const newFiles: ChatFile[] = [];
    for (const file of acceptedFiles) {
      const chatFile: ChatFile = {
        file: file,
        image: '',
        status: 'uploading',
        progress: 0,
        abortController: new AbortController(),
      };
      if (file.type.startsWith('image/')) {
        chatFile.image = URL.createObjectURL(file);
      }
      newFiles.push(chatFile);
      files.push(chatFile);
    }

    setFiles([ ...files ]);

    for (const file of newFiles) {
      uploadFile(file);
    }

    //eslint-disable-next-line
  }, [ files ]);

  const uploadFile = async (file: ChatFile) => {
    try {

      let formData = new FormData();

      formData.append("file", file.file);

      const { client, headers } = getChatFilesApi();

      const result = await client.post("/files", formData, {
        headers,
        signal: file.abortController.signal,
        onUploadProgress: (event) => {
          const { loaded, total } = event;
          let progress = 0;
          if (total) {
            progress = Math.round((loaded * 100) / total);
          }
          const newFiles = [ ...files ];
          const index = newFiles.findIndex((f) => f.file === file.file);
          if (index === -1) {
            return;
          }
          newFiles[ index ].progress = progress;
          setFiles(newFiles);
        },
      });
      console.log('upload done...', result);

      const newFiles = [ ...files ];
      const index = newFiles.findIndex((f) => f.file === file.file);
      if (index === -1) {
        return;
      }
      newFiles[ index ].status = 'done';
      newFiles[ index ].ref = result.data.file.self;
      setFiles(newFiles);
    }
    catch (e) {
      console.log('upload error...', e);
      const newFiles = [ ...files ];
      const index = newFiles.findIndex((f) => f.file === file.file);
      if (index === -1) {
        return;
      }

      if ((e as any).message === 'canceled') {
        deleteFile(index);
      }
      else {
        newFiles[ index ].status = 'error';
        newFiles[ index ].error = (e as any).message;
        setFiles(newFiles);
      }
    }
  }

  // const uploadFile = useCallback(
  //   async (file: ChatFile) => {
  //     let formData = new FormData();

  //     formData.append("file", file.file);

  //     const result = await  uploadApi.post("/files", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //       signal: file.abortController.signal,
  //       onUploadProgress: (event) => {
  //         const { loaded, total } = event;
  //         let progress = 0;
  //         if (total) {
  //           progress = Math.round((loaded * 100) / total);
  //         }
  //         const newFiles = [ ...files ];
  //         const index = newFiles.findIndex((f) => f.file === file.file);
  //         newFiles[ index ].progress = progress;
  //         setFiles(newFiles);
  //       },
  //     });
  //     console.log('upload done...', result);
  //   }, [ files ]);

  const deleteFile = (index: number) => {
    const newFiles = [ ...files ];
    var removedFile = newFiles.splice(index, 1);
    if (Boolean(removedFile[ 0 ].image)) {
      URL.revokeObjectURL(removedFile[ 0 ].image);
    }
    removedFile[ 0 ].abortController.abort();
    setFiles(newFiles);
  }
  const deleteAllFiles = () => {
    for (const file of files) {
      // if (Boolean(file.image)) {
      //   URL.revokeObjectURL(file.image);
      // }
      file.abortController.abort();
    }
    setFiles([]);
  }

  const { getRootProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: true,
    noClick: true,
  });

  const context: IFileUploadContext = {
    open,
    deleteFile,
    deleteAllFiles,
    isDragActive,
    files,
    renderContainerHeight: 70,
    images: {},
  }

  return (
    <FileUploadContext.Provider value={context}>
      <Box
        width="100%"
        height="100%"
        {...getRootProps()}
      >
        {children}
        <Flex
          id="dropzone-overlay"
          backgroundColor="rgba(0,0,0, 0.7)"
          justify="center"
          align="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          pointerEvents="none"
          display={isDragActive ? 'flex' : 'none'}
        >
          <Text fontWeight={600} color="white">Drop files here to use in the conversation</Text>
        </Flex>
      </Box>
    </FileUploadContext.Provider>
  );
};


export const useFileUpload = () => {
  return React.useContext(FileUploadContext);
}

export default FileUpload;