import React from "react";
import AppPage from "../components/AppFrame/AppPage";
import AppPageHeader from "../components/AppFrame/AppPageHeader";
import { Text } from "@chakra-ui/react";



const HelpPage: React.FC = () => {
  return (
    <AppPage p={5} maxWidth={1000}>
      <AppPageHeader title="Help" />
      <Text>
        HelpPage
      </Text>
    </AppPage>
  );
};

export default HelpPage;