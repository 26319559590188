// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file user/preferences/v0/preferences.proto (package user.preferences.v0, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message user.preferences.v0.SetDarkModeRequest
 */
export class SetDarkModeRequest extends Message<SetDarkModeRequest> {
  /**
   * @generated from field: bool dark_mode = 1;
   */
  darkMode = false;

  constructor(data?: PartialMessage<SetDarkModeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "user.preferences.v0.SetDarkModeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dark_mode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetDarkModeRequest {
    return new SetDarkModeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetDarkModeRequest {
    return new SetDarkModeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetDarkModeRequest {
    return new SetDarkModeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetDarkModeRequest | PlainMessage<SetDarkModeRequest> | undefined, b: SetDarkModeRequest | PlainMessage<SetDarkModeRequest> | undefined): boolean {
    return proto3.util.equals(SetDarkModeRequest, a, b);
  }
}

/**
 * @generated from message user.preferences.v0.SetFavoriteRequest
 */
export class SetFavoriteRequest extends Message<SetFavoriteRequest> {
  /**
   * @generated from field: string assistant_id = 1;
   */
  assistantId = "";

  constructor(data?: PartialMessage<SetFavoriteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "user.preferences.v0.SetFavoriteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetFavoriteRequest {
    return new SetFavoriteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetFavoriteRequest {
    return new SetFavoriteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetFavoriteRequest {
    return new SetFavoriteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetFavoriteRequest | PlainMessage<SetFavoriteRequest> | undefined, b: SetFavoriteRequest | PlainMessage<SetFavoriteRequest> | undefined): boolean {
    return proto3.util.equals(SetFavoriteRequest, a, b);
  }
}

/**
 * @generated from message user.preferences.v0.RemoveFavoriteRequest
 */
export class RemoveFavoriteRequest extends Message<RemoveFavoriteRequest> {
  /**
   * @generated from field: string assistant_id = 1;
   */
  assistantId = "";

  constructor(data?: PartialMessage<RemoveFavoriteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "user.preferences.v0.RemoveFavoriteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveFavoriteRequest {
    return new RemoveFavoriteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveFavoriteRequest {
    return new RemoveFavoriteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveFavoriteRequest {
    return new RemoveFavoriteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveFavoriteRequest | PlainMessage<RemoveFavoriteRequest> | undefined, b: RemoveFavoriteRequest | PlainMessage<RemoveFavoriteRequest> | undefined): boolean {
    return proto3.util.equals(RemoveFavoriteRequest, a, b);
  }
}

/**
 * @generated from message user.preferences.v0.Preferences
 */
export class Preferences extends Message<Preferences> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: bool dark_mode = 2;
   */
  darkMode = false;

  /**
   * @generated from field: repeated string favorites = 3;
   */
  favorites: string[] = [];

  constructor(data?: PartialMessage<Preferences>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "user.preferences.v0.Preferences";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dark_mode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "favorites", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Preferences {
    return new Preferences().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Preferences {
    return new Preferences().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Preferences {
    return new Preferences().fromJsonString(jsonString, options);
  }

  static equals(a: Preferences | PlainMessage<Preferences> | undefined, b: Preferences | PlainMessage<Preferences> | undefined): boolean {
    return proto3.util.equals(Preferences, a, b);
  }
}

