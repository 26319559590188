import {
  Box,
  HStack,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  useColorModeValue
} from "@chakra-ui/react";
import { useState } from "react";
import { MdInfo } from "react-icons/md";

interface CustomSliderProps {
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  onChange: (value: number) => void;
  label: string;
  tooltip: string;
  disabled: boolean;
}
const CustomSlider: React.FC<CustomSliderProps> = ({
  min,
  max,
  step,
  defaultValue,
  onChange,
  label,
  tooltip,
  disabled
}) => {
  const [ value, setValue ] = useState(defaultValue);

  return (
    <HStack justify="space-between" width="100%">
      <HStack width="350px">
        <Text fontWeight="600">{label}</Text>
        <Tooltip label={tooltip}>
          <span style={{ marginTop: '5px' }}>
            <Icon as={MdInfo} color={useColorModeValue('gray.300', 'gray.600')} />
          </span>
        </Tooltip>
      </HStack>
      <Slider
        isDisabled={disabled}
        aria-label={label}
        defaultValue={defaultValue}
        min={min}
        max={max}
        step={step}
        onChange={(value) => {
          setValue(value);
        }}
        onChangeEnd={() => {
          onChange(value);
        }}
      >

        <Box position="absolute" left="0px" mt={2}>
          <Text fontSize="12px">
            {min}
          </Text>
        </Box>

        <Box position="absolute" right="0px" mt={2}>
          <Text fontSize="12px">
            {max}
          </Text>
        </Box>

        <SliderMark
          value={value > max ? max : value}
          textAlign='center'
          mt={-8}
          ml={-6}
          w={12}
          fontSize="sm"
        >
          {value}
        </SliderMark>

        <SliderTrack bgColor={useColorModeValue('gray.200', 'gray.700')}>
          <SliderFilledTrack bgColor={useColorModeValue('gray.200', 'gray.700')} />
        </SliderTrack>
        <SliderThumb boxSize={4} bgColor={useColorModeValue('gray.900', 'gray.300')} borderRadius={3} />
      </Slider>
    </HStack>
  );
}

export default CustomSlider;