import React, { useState } from "react";
import AppPage from "../components/AppFrame/AppPage";
import AppPageHeader from "../components/AppFrame/AppPageHeader";
import { IconButton } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAssistants } from "../state/assistants";
import AssistantsGrid from "../components/AssistantsGrid";



const AssistantsPage: React.FC = () => {
  const assistants = useAssistants();
  const navigate = useNavigate();

  const [ createPending, setCreateIsPending ] = useState(false);


  const handleCreateAssistant = async () => {
    setCreateIsPending(true);
    try {
      const assistantId = await assistants.createNewAssistant();
      navigate(`/assistants/${assistantId}`);
    } catch (e) {
      console.error(e);
    } finally {
      setCreateIsPending(false);
    }
  }

  return (
    <AppPage p={4} maxWidth={1200}>

      <AppPageHeader
        title="Assistants"
        actions={(
          <IconButton
            isLoading={createPending}
            onClick={handleCreateAssistant}
            aria-label="Create assistant" icon={<MdAdd />}
            colorScheme="primary"
            color="black"
          />
        )}
      />


      <AssistantsGrid
        onSelect={(assistantId) => {
          assistants.setSelectedAssistantId(assistantId);
          navigate(`/`, { replace: true });
        }}
        onEdit={(assistantId) => {
          navigate(`/assistants/${assistantId}`);
        }}
      />


    </AppPage>
  );
};

export default AssistantsPage;