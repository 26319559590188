import { Button, Flex, HStack, Icon, IconButton, Text, Tooltip, VStack, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Assistant } from "../gen-ts/ai/assistants/v0/assistant_pb";
import { MdStar, MdStarOutline, MdVerifiedUser } from "react-icons/md";
import { useUserState } from "../state/user";


interface Props {
  assistant: Assistant;
  onSelect: (assistantId: string) => void;
  onEdit?: (assistantId: string) => void;
  disableSelect?: boolean;
}

const AssistantCard: React.FC<Props> = ({ assistant, onSelect, onEdit, disableSelect }) => {
  const [ isVisible, setIsVisible ] = useState(false);
  const ref = useRef();
  const userState = useUserState();
  const bgColor = useColorModeValue("white", "gray.900");

  useEffect(() => {
    const observer = new IntersectionObserver(([ entry ]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.disconnect();
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    var refCopy = ref.current;

    return () => {
      if (refCopy) observer.unobserve(refCopy);
    };
  }, []);

  const isFavorited = userState.favoriteAssistants.includes(assistant.id);
  
  return (
    <VStack
      width={{
        base: "300px",
        md: "350px",
      }}
      height="280px"
      borderRadius={20}
      p={3}
      bgColor={bgColor}
      overflow="hidden"
      boxShadow="sm"
      ref={ref as any}
    >
      {
        isVisible ? (
          <>
            <Text fontWeight="bold" fontSize="17px">
              {assistant.displayName}
            </Text>

            <Text fontSize="15" textAlign="left">
              {assistant.description.length > 180 ? assistant.description.slice(0, 180) + '...' : assistant.description}
            </Text>
            <Flex grow={1} />
            <HStack justify="start" width="100%" align="center">
              {
                assistant.certified && (
                  <Tooltip label="Certified">
                    <span>
                      <Icon as={MdVerifiedUser} fontSize="20px" color="primary.600" mt="5px"/>
                    </span>
                  </Tooltip>
                )
              }
              <Tooltip label={isFavorited ? 'Remove from favorites' : 'Add to favorites'}>
                <IconButton
                  aria-label={isFavorited ? 'Remove from favorites' : 'Add to favorites'}
                  icon={isFavorited ? <MdStar /> : <MdStarOutline />}
                  variant="ghost"
                  size="lg"
                  fontSize="25px"
                  borderRadius="100px"
                  onClick={() => userState.toggleFavoriteAssistant(assistant.id)}
                />
              </Tooltip>
            </HStack>
            <HStack>
              {
                onEdit && (
                  <Button
                    onClick={() => {
                      onEdit(assistant.id);
                    }}
                  >
                    View
                  </Button>
                )
              }
              <Button
                isDisabled={disableSelect}
                onClick={() => {
                  onSelect(assistant.id);
                }}>Select</Button>
            </HStack>
          </>
        ) : <div />
      }
    </VStack>
  );
};

export default AssistantCard;