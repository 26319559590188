import { Flex, Icon, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import React from "react";
import { MdCloudUpload } from "react-icons/md";
import { useFileManager } from "./FileManagerProvider";


// interface Props {
//   isDragActive: boolean;
//   rootProps: any;
//   inputProps: any;
//   open: () => void;
// }

const EmptyDropZone: React.FC<{path: string}> = ({path}) => {
  const dragAreaBorderColor = useColorModeValue("gray.600", "gray.500");

  const fileManager = useFileManager();

 

  return (
    <VStack width="100%" opacity={
      fileManager.hasFilePermission ? 1 : 0.5
    }>
      <Flex
        data-path={path}
        data-dir="true"
        data-dropzone="true"
        direction="column"
        justify="center"
        align="center"
        borderWidth="2px"
        borderColor={fileManager.activeDropZone === path ? "primary.500" : dragAreaBorderColor}
        borderRadius={10}
        borderStyle="dashed"
        width="100%"
        height="120px"
        cursor={
          fileManager.hasFilePermission ? "pointer" : "not-allowed"
        }
        onClick={fileManager.openFilePicker}
        {...fileManager.getDropZoneProps()}
      >
        <Icon
          as={MdCloudUpload}
          fontSize="3xl"
          pointerEvents="none"
        />
        <Text fontWeight="600" pointerEvents="none">Upload files or folders</Text>

      </Flex>
      <Text textAlign="center" fontSize={15} mt={4}>
        Common file types for text, documents, spreadsheets, and code are supported. <br />
        Anything else will be treated as a plaintext file. Note: If you upload a duplicate file with the same file name, the latest one will overwrite the old one.
      </Text>
    </VStack>
  );
};

export default EmptyDropZone;