import { Box, Button, Flex, HStack, Spinner, useColorModeValue, VStack } from "@chakra-ui/react";
import React from "react";

import EmptyDropZone from "./EmptyDropZone";
import { useFileManager } from "./FileManagerProvider";
import CurrentFolder from "./CurrentFolder";
import RetrievalSettingsDialog from "./RetrievalSettingsDialog";
import BreadCrumbs from "./BreadCrumbs";
import { getRootFolder, ROOT_PATH, useAssistantFiles } from "../../../state/assistantFiles";
import { RetrievalSettings } from "../../../gen-ts/ai/assistants/v0/assistant_pb";




interface Props {
  assistantId: string;
  onRetrievalSettingsUpdate: (data: RetrievalSettings) => void;
}

const FileManager: React.FC<Props> = ({assistantId, onRetrievalSettingsUpdate}) => {
  const [ retreivalSettingsOpen, setRetrievalSettingsOpen ] = React.useState(false);

  const fileManager = useFileManager();
  const assistantFiles = useAssistantFiles();

  const filesLoading = assistantFiles.loading[fileManager.assistant.id];


  const bgColor = useColorModeValue("gray.50", "gray.700");
  const dividerColor = useColorModeValue("gray.200", "gray.700");

  if (fileManager.currentFolder === null) {
    return (
      <Box>
        Invalid path
      </Box>
    );
  }

  
  if (fileManager.currentFolder.children.length === 0 && !filesLoading && fileManager.currentPath === ROOT_PATH) { 
    return (
      <EmptyDropZone path={getRootFolder().path} />
    );
  }

  return (
    <>
      <VStack
        width="100%"
        align="start"
      >
        <HStack width="100%" overflow="scroll">
          <Button
            colorScheme={
              !false ? 'primary' : 'gray'
            }
            color="black"
            onClick={fileManager.openFilePicker}
            isDisabled={!fileManager.hasFilePermission}
          >
            {
              'Upload files'
              // isDragActive ? 'Drop the files' : 'Upload files'
            }
          </Button>
          <Box flexGrow={1} height="1px" bgColor={dividerColor} />
          <Button variant="outline" onClick={() => setRetrievalSettingsOpen(true)}>
            Retrieval Settings
          </Button>
        </HStack>
        
        <BreadCrumbs />

        <Box bgColor={bgColor} borderRadius="5" width="100%" overflow="hidden">
          {
            filesLoading ? (
              <Flex align="center" justify="center" width="100%" p={10}>
                <Spinner />
              </Flex>
            ) : (
              <CurrentFolder 
                items={fileManager.currentFolder.children} 
                currentPath={fileManager.currentPath}
                assistantId={assistantId}

              />
            )
          }
        </Box>
      </VStack>
      <RetrievalSettingsDialog
        isOpen={retreivalSettingsOpen}
        onClose={() => setRetrievalSettingsOpen(false)}
        onUpdate={(data) => {
          onRetrievalSettingsUpdate(data);
        }}
        retrievalSettings={fileManager.assistant.retrievalSettings!}
      />
    </>

  );
};

export default FileManager;