import { Box, HStack, IconButton, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { MdClose } from "react-icons/md";



interface Props {
  message: string;
  status: "success" | "error";
  isCLosable?: boolean;
  onClose?: () => void;
}

const ToastMessage: React.FC<Props> = ({ message, status, isCLosable, onClose }) => {
  const closeIconTextColor = useColorModeValue("gray.200", "gray.700");
  return (
    <HStack
      bgColor={useColorModeValue("gray.700", "gray.100")}
      color={useColorModeValue("white", "gray.800")}
      borderRadius={8}
      overflow="hidden"
      alignItems="stretch"
    >
      <Box
        width="7px"
        bgColor={status === "success" ? "green.500" : "red.500"}
      />
      <HStack width="100%">
        <Box flexGrow={1} py={2}>
          <Text>{message}</Text>
        </Box>
        {
          isCLosable && (
            <IconButton
              onClick={onClose}
              icon={<MdClose />}
              aria-label="Close"
              variant="ghost"
              size="sm"
              mr={1}
              bgColor="transparent"
              color={closeIconTextColor}
              _hover={{
                bgColor: "transparent",
              }}
              _active={{
                bgColor: "transparent",
              }}
            >
              Close
            </IconButton>
          )
        }
      </HStack>
    </HStack>
  );
};

export default ToastMessage;