import React from "react";
import { useParams } from "react-router-dom";
import ChatUI from "../components/ChatUI/ChatUI";


type ChatPageParams = {
  threadId: string;
}

const ChatPage: React.FC = () => {
  var { threadId } = useParams<ChatPageParams>();

  return (
    <ChatUI threadId={threadId} key={threadId} />
  );
};

export default ChatPage;