import React, { useEffect, useState } from "react";
import AppPage from "../../components/AppFrame/AppPage";
import AppPageHeader from "../../components/AppFrame/AppPageHeader";
import { StringParam, useQueryParam } from "use-query-params";
import { useChannelState } from "../../state/channel";
import { HStack, Stack, VStack, Text, Divider, Button, useColorModeValue } from "@chakra-ui/react";
import { useAssistants } from "../../state/assistants";
import { MdTune } from "react-icons/md";
import { Link } from "react-router-dom";
import CustomSwitch from "../../components/CustomSwitch";
import ChannelAliases from "./ChannelAliases";
import { useAssistantsDialog } from "../../components/AssistantsDialog";
import AppPageBottomBar from "../../components/AppFrame/AppPageBottomBar";
import { Alias } from "../../gen-ts/slack/slack_config_pb";



const ManageChannelPage: React.FC = () => {
  const [ token ] = useQueryParam('token', StringParam);
  const channelState = useChannelState();
  const assistants = useAssistants();
  const assistantDialog = useAssistantsDialog();

  const isLoading = channelState.isLoading[ token ?? '' ] ?? false;

  const originalChannel = channelState.channels[ token ?? '' ] ?? null;
  const [ channel, setChannel ] = useState(originalChannel);

  useEffect(() => {
    if (token) {
      channelState.loadChannelConfig(token);
    }
    // eslint-disable-next-line
  }, [ token ]);

  useEffect(() => {

    if (originalChannel) {
      setChannel(originalChannel.clone());
    }

    // eslint-disable-next-line

  }, [ originalChannel ]);



  const assistant = channel?.config?.assistantId ? assistants.assistants[ channel.config?.assistantId ] : null;
  const error = channelState.error[ token ?? '' ] ?? null;
  const bottombarHeight = 70;

  const onAddAlias = (name: string, alias: Alias) => {
    const update = channel.clone();
    update.config!.aliases[ name ] = alias;
    setChannel(update.clone());
  }

  const onDeleteAlias = (name: string) => {
    const update = channel.clone();
    delete update.config!.aliases[ name ];
    setChannel(update.clone());
  }

  const setRespondToAllConversations = (value: boolean) => {
    const update = channel.clone();
    update.config!.respondToAllConversations = value;
    setChannel(update.clone());
  }

  const setChannelAssistant = (assistantId: string) => {
    const update = channel.clone();
    update.config!.assistantId = assistantId;
    setChannel(update.clone());
  }

  const onSave = () => {
    channelState.saveChannelConfig(token ?? '', channel);
  }

  const onReset = () => {
    setChannel(originalChannel.clone());
  }




  const leftColumnWidth = {
    'base': '100%',
    'md': '350px',
  };

  const assistantBoxBgColor = useColorModeValue('gray.50', 'gray.700');

  //https://gpt.peak6labs.com/channel?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzbGFja2JvdCIsInN1YiI6ImNoYW5uZWwgY29uZmlndXJhdG9yIiwiZXhwIjoxNzI5ODk5NzMwLCJzbGFja2JvdF9pZCI6IkIwNjlKMTVGUEZUIiwiY2hhbm5lbF9pZCI6IkQwN0pTRkpERFBWIiwic2xhY2tib3RfbmFtZSI6InIyZzIiLCJjaGFubmVsX25hbWUiOiJcdTAwM2NEaXJlY3QgTWVzc2FnZVx1MDAzZSIsImRlZmF1bHRfYXNzaXN0YW50X2lkIjoiZGVmYXVsdCJ9.Eu_g3YBQIjhNC_GPZrgvtCRllmPwoh1HhtmOLuO9JrI

  const modified = !originalChannel?.equals(channel);

  return (
    <AppPage isLoading={isLoading} maxWidth={1200} p={4}>
      <AppPageHeader title="Manage channel configuration" />
      <AppPageBottomBar>
        <HStack
          width="100%"
          height={`${bottombarHeight}px`}
          bgColor={useColorModeValue('white', 'gray.700')}
          boxShadow="0 -4px 15px rgb(0, 0, 0, 0.2)"
          justify="space-between"
          px={5}
        >
          <Button
            variant="outline"
            isDisabled={!modified}
            onClick={onReset}
          >
            Clear to default
          </Button>
          <Button
            colorScheme="primary"
            color="black"
            isDisabled={!modified}
            onClick={onSave}
          >
            Save Changes
          </Button>
        </HStack>
      </AppPageBottomBar>
      {
        !isLoading && !error && token && (
          <VStack mt={9} pb={`${bottombarHeight}px`}>
            <Stack
              direction={{
                base: 'column',
                lg: 'row',
              }}
              align={{
                base: "start",
                lg: "center"
              }}
              width="100%"
            >
              <HStack width={leftColumnWidth}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_112_493)">
                    <path d="M0.600098 15.0079C0.738578 18.186 5.25226 18.1852 5.39026 15.0079V12.6127H2.99506C1.67698 12.6127 0.600098 13.6896 0.600098 15.0079Z" fill="#CE3056" />
                    <path d="M15.008 11.3873C16.3263 11.3873 17.4032 10.3104 17.4032 8.99211V2.99523C17.2654 -0.181412 12.7515 -0.183571 12.613 2.99523V8.99235C12.6128 10.3104 13.6897 11.3873 15.008 11.3873Z" fill="#5BB381" />
                    <path d="M8.99213 12.6127C7.67405 12.6127 6.59717 13.6896 6.59717 15.0079V21.0047C6.73493 24.1814 11.2488 24.1835 11.3873 21.0047V15.0076C11.3873 13.6896 10.3104 12.6127 8.99213 12.6127Z" fill="#CE3056" />
                    <path d="M23.4 8.99205C23.2615 5.81397 18.7479 5.81469 18.6099 8.99205V11.3873H21.0051C22.3231 11.3873 23.4 10.3104 23.4 8.99205Z" fill="#5BB381" />
                    <path d="M2.99535 11.3872H8.99247C12.1691 11.2494 12.1713 6.73553 8.99247 6.59705H2.99535C-0.181769 6.73505 -0.182969 11.2487 2.99535 11.3872Z" fill="#61C3EC" />
                    <path d="M15.008 18.6099H12.6128V21.0051C12.6128 22.3234 13.6897 23.4003 15.008 23.4003C18.1858 23.2615 18.1851 18.7479 15.008 18.6099Z" fill="#E4B34B" />
                    <path d="M21.0049 12.6127H15.008C11.8309 12.7507 11.8297 17.2644 15.008 17.4028H21.0052C24.1816 17.2651 24.1837 12.7514 21.0049 12.6127Z" fill="#E4B34B" />
                    <path d="M8.99218 0.599976C5.8141 0.738456 5.81482 5.25214 8.99218 5.39014H11.3874V2.99494C11.3874 1.67686 10.3105 0.599976 8.99218 0.599976Z" fill="#61C3EC" />
                  </g>
                  <defs>
                    <clipPath id="clip0_112_493">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text>Channel</Text>
              </HStack>
              <Text width="100%">{channel?.detail?.channelName}</Text>
            </Stack>

            <Divider my={5} />

            <Stack
              direction={{
                base: 'column',
                lg: 'row',
              }}
              align={{
                base: "start",
                lg: "start"
              }}
              width="100%"
            >
              <Text fontWeight="600" width={leftColumnWidth}>Assistant</Text>

              <VStack width="100%" align="start">
                <HStack width="100%" bgColor={assistantBoxBgColor} p={3} borderRadius={10}>
                  <VStack width="100%" align="start">
                    <Text fontWeight="600">{assistant?.displayName}</Text>
                    <Text fontSize="14px">{assistant?.description}</Text>
                  </VStack>
                  <Button
                    variant="outline"
                    as={Link}
                    to={`/assistants/${assistant?.id}`}
                  >
                    Edit
                  </Button>
                </HStack>
                <Button
                  leftIcon={<MdTune />}
                  variant="outline"
                  onClick={() => {
                    assistantDialog.open({
                      onSelect: (assistantId) => {
                        setChannelAssistant(assistantId);
                        assistantDialog.close();
                      }
                    });
                  }}
                >
                  Change assistant
                </Button>
              </VStack>
            </Stack>

            <Divider my={5} />

            <Stack
              direction={{
                base: 'column',
                lg: 'row',
              }}
              align={{
                base: "start",
                lg: "center"
              }}
              width="100%"
            >
              <Text fontWeight="600" width={leftColumnWidth}>Respond to every message</Text>

              <HStack width="100%">
                <CustomSwitch
                  items={[
                    { id: 'yes', name: 'Yes' },
                    { id: 'no', name: 'No' },
                  ]}
                  selected={channel?.config?.respondToAllConversations ? 'yes' : 'no'}
                  onSelect={(id) => {
                    setRespondToAllConversations(id === 'yes');
                  }}
                />
                <Text>
                  If enabled, GPT will respond to every message within a channel.
                </Text>
              </HStack>
            </Stack>

            <Divider my={5} />

            <ChannelAliases
              aliases={channel?.config?.aliases || {}}
              onAddAlias={onAddAlias}
              onDeleteAlias={onDeleteAlias}
            />

          </VStack>
        )
      }
      {
        !isLoading && error && (
          <Text>{error}</Text>
        )
      }
    </AppPage>
  );
};

export default ManageChannelPage;