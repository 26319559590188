import { create } from "zustand"
import { UserAccount } from "../types";
import { RemoveFavoriteRequest, SetFavoriteRequest } from "../gen-ts/user/preferences/v0/preferences_pb";
import { getUserPreferencesApiClient } from "../api";


interface UserState {
  user: UserAccount | null;
  authToken: string | null;
  setAuthData: (args: { user: UserAccount, authToken: string }) => void;
  favoriteAssistants: string[],
  toggleFavoriteAssistant: (assistantId: string) => void,
  loadPreferences: () => void,
  getDomain: () => string,
}

export const useUserState = create<UserState>((set, get) => ({
  user: null,
  authToken: null,
  favoriteAssistants: [],
  setAuthData: (args) => {
    set({
      user: args.user,
      authToken: args.authToken
    });
  },
  toggleFavoriteAssistant: (assistantId) => {
    const favorited = get().favoriteAssistants.includes(assistantId);

    const { client, headers } = getUserPreferencesApiClient();

    if (favorited) {
      set({
        favoriteAssistants: get().favoriteAssistants.filter((id) => id !== assistantId),
      });

      const removeRequest = new RemoveFavoriteRequest({
        assistantId,
      });

      client.removeFavorite(removeRequest, { headers });
    }
    else {
      set({
        favoriteAssistants: [ ...get().favoriteAssistants, assistantId ],
      });

      const addRequest = new SetFavoriteRequest({
        assistantId,
      });

      client.setFavorite(addRequest, { headers });
    }
  },
  loadPreferences: async () => {
    const { client, headers } = getUserPreferencesApiClient();

    const resp = await client.get({}, { headers });

    set({
      favoriteAssistants: resp.favorites,
    });
  },
  getDomain: () => {
    const user = get().user;
    if (!user) {
      return '';
    }

    return user.email.split('@')[1].toLowerCase();
  },
}));

