import { Config } from "./types";

export const config: Config = {
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  backendUrl: process.env.REACT_APP_BACKEND_URL as string,
  grpcBackendUrl: process.env.REACT_APP_GRPC_BACKEND_URL as string,
  blacklist: [
    "domain:1ststreetagency.com",
    "domain:fballianceinsurance.com",
    "domain:peak6insurtech.com",
    "domain:southernoakinsfl.com",
    "domain:spinnakerinsco.com",
    "domain:teamfocusins.com",
    "domain:uw-shoreoneinsurance.com",
    "domain:weinsuregroup.com",
  ],
  whitelist: [
    "domain:afs.io",
    "domain:apexclearing.com",
    "domain:apexfintechsolutions.com",
    "domain:evilgeniuses.gg",
    "domain:peak6.com",
    "domain:zogo.com",
    "domain:zogofinance.com",
    "user:andre.vazquez@weinsuregroup.com",
    "user:andy.quach@peak6insurtech.com",
    "user:andy.quach@teamfocusins.com",
    "user:danielle.davis@weinsuregroup.com",
    "user:jamie.wormington@weinsuregroup.com",
    "user:jonathan.marsh@teamfocusins.com",
    "user:juan.puigdoller@teamfocusins.com",
    "user:mark.swofford@peak6insurtech.com",
    "user:mark.swofford@teamfocusins.com",
    "user:sam.rea@peak6insurtech.com",
    "user:sam.rea@teamfocusins.com",
    "user:sarah.mcshane@teamfocusins.com",
    "user:sean.harvey@teamfocusins.com",
    "user:yuying.chenwynn@teamfocusins.com"
  ],
  adminEmails: [ "csacks@peak6.com", "cschroeder@peak6.com", "aghazaryan@peak6.com" ],
  developerEmails: [
    "afernandes@peak6.com",
    "dbudworth@peak6.com",
    "mmann@peak6.com",
    "ychenwynn@peak6.com",
    "aghazaryan@peak6.com",
  ],
  tenants: {
    peak6: {
      domains: [ "peak6.com" ],
      logo: "/logos/peak6.png",
    },
    peak6insurtech: {
      domains: [
        "1ststreetagency.com",
        "fballianceinsurance.com",
        "peak6insurtech.com",
        "southernoakinsfl.com",
        "spinnakerinsco.com",
        "teamfocusins.com",
        "uw-shoreoneinsurance.com",
        "weinsuregroup.com",
      ],
      logo: "/logos/insurtech.png",
    },
    apexfintechsolutions: {
      domains: [ "apexfintechsolutions.com", "apexclearing.com", "afs.io" ],
      logo: "/logos/apex.png",
    },
    zogo: {
      domains: [ "zogo.com", "zogofinance.com" ],
      logo: "/logos/zogo.png",
    },
    evilGeniuses: {
      domains: [ "evilgeniuses.gg" ],
      logo: "/logos/eg.png",
    },
  },
}

export const MODEL_ENGINES_ICONS: { [ id: string ]: string } = {
  'ANTHROPIC': '/model-logos/anthropic.svg',
  'GEMINI': '/model-logos/gemini.svg',
  'OPENAI': '/model-logos/openai.svg',
  'PERPLEXITY': '/model-logos/perplexity.svg',
}

export const DEFAULT_ASSISTANT_ID = "default";

export enum ASSISTANT_PEPRMISSIONS {
  assistantsUpdate = "assistants.edit",
  filesUpload = "assistants.upload",
}