import React from "react";


const LoginPage: React.FC = () => {
  return (
    <div>
      Login Page
    </div>
  );
};

export default LoginPage;