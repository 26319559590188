import { Portal } from "@chakra-ui/react";
import React, { PropsWithChildren, useContext} from "react";
import { AppFrameContext } from "./AppFrame";




const AppPageBottomBar: React.FC<PropsWithChildren> = ({ children }) => {
  const context = useContext(AppFrameContext);

  return (
    <Portal containerRef={context.bottomBarRef}>
      {children}
    </Portal>
  );
};

export default AppPageBottomBar;