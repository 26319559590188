import { create } from "zustand"
import { getSlackApiClient } from "../api";
import { GetConfigRequest, GetConfigResponse, SetConfigRequest } from "../gen-ts/slack/slack_config_pb";
import { useAssistants } from "./assistants";


interface ChannelState {
  isLoading: {
    [ token: string ]: boolean;
  },
  channels: {
    [ token: string ]: GetConfigResponse;
  },
  error: {
    [ token: string ]: string;
  },
  loadChannelConfig: (token: string) => void,
  saveChannelConfig: (token: string, channel: GetConfigResponse) => void,
}


export const useChannelState = create<ChannelState>((set, get) => ({
  isLoading: {},
  channels: {},
  error: {},
  loadChannelConfig: async (token) => {
    if (get().isLoading[ token ] || get().channels[ token ]) {
      return;
    }

    set({
      isLoading: {
        ...get().isLoading,
        [ token ]: true,
      }
    });

    const { client, headers } = getSlackApiClient();

    try {
      const request = new GetConfigRequest({ token });
      const response = await client.getConfig(request, { headers });
      useAssistants.getState().loadById({
        assistantId: response.config?.assistantId!,
      });
      set({
        isLoading: {
          ...get().isLoading,
          [ token ]: false,
        },
        channels: {
          ...get().channels,
          [ token ]: response,
        },
      });
    }
    catch (e) {
      console.error(e);
      set({
        isLoading: {
          ...get().isLoading,
          [ token ]: false,
        },
        error: {
          ...get().error,
          [ token ]: (e as any).message,
        }
      })
    }
  },
  saveChannelConfig: async (token, channel) => {
    try {
      const { client, headers } = getSlackApiClient();

      channel.config!.lastUpdate = undefined;


      const setConfigRequest = new SetConfigRequest({
        config: channel.config!,
        token,
      });

      client.setConfig(setConfigRequest, { headers });
      set({
        channels: {
          ...get().channels,
          [ token ]: channel,
        },
      });
    }
    catch (e) {
      console.error(e);
    }
  },
}));

