import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import React from "react";


interface Item {
  id: string;
  name: string;
}

interface Props {
  items: Item[];
  selected: string;
  onSelect: (id: string) => void;
  isDisabled?: boolean;
}

const CustomSwitch: React.FC<Props> = ({ items, selected, onSelect, isDisabled }) => {
  const selectedColor = useColorModeValue('gray.900', 'gray.200');

  const selectedTextColor = useColorModeValue('white', 'black');
  const unselectedTextColor = useColorModeValue('black', 'white');

  const hanldeClick = (id: string) => {
    if (!isDisabled) {
      onSelect(id);
    }
  }


  return (
    <HStack
      borderWidth="1px"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      p={1}
      borderRadius={8}
      opacity={isDisabled ? 0.5 : 1}
    >
      {
        items.map((item) => {
          return (
            <Box
              as="button"
              key={item.id}
              px={2}
              py={1}
              borderRadius={8}
              bgColor={
                selected === item.id ? selectedColor : 'transparent'
              }
              color={
                selected === item.id ? selectedTextColor : unselectedTextColor
              }
              onClick={() => hanldeClick(item.id)}
              fontWeight="600"
              
              transition="all 0.2s"
            >
              {item.name}
            </Box>
          );
        })
      }
    </HStack>
  );
};

export default CustomSwitch;