// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/assistants/v0/tools.proto (package ai.assistants.v0, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * A tool/function definition for a model to use.
 *
 * @generated from message ai.assistants.v0.Tool
 */
export class Tool extends Message<Tool> {
  /**
   * Name of the function
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Human-readable description of the function
   *
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * Parameters for the function
   *
   * @generated from field: repeated ai.assistants.v0.Tool.Parameter parameters = 3;
   */
  parameters: Tool_Parameter[] = [];

  constructor(data?: PartialMessage<Tool>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Tool";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parameters", kind: "message", T: Tool_Parameter, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tool {
    return new Tool().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tool {
    return new Tool().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tool {
    return new Tool().fromJsonString(jsonString, options);
  }

  static equals(a: Tool | PlainMessage<Tool> | undefined, b: Tool | PlainMessage<Tool> | undefined): boolean {
    return proto3.util.equals(Tool, a, b);
  }
}

/**
 * A parameter definition for a tool
 *
 * @generated from message ai.assistants.v0.Tool.Parameter
 */
export class Tool_Parameter extends Message<Tool_Parameter> {
  /**
   * Name of the parameter
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Human-readable description of the parameter
   *
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * Type of the parameter
   * TODO: What about complex types?
   *
   * @generated from field: ai.assistants.v0.Tool.Parameter.Type type = 3;
   */
  type = Tool_Parameter_Type.TYPE_UNSPECIFIED;

  /**
   * Possible values for the parameter when the type is ENUM
   *
   * @generated from field: repeated string values = 4;
   */
  values: string[] = [];

  /**
   * Whether the parameter is required
   *
   * @generated from field: bool required = 5;
   */
  required = false;

  constructor(data?: PartialMessage<Tool_Parameter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Tool.Parameter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(Tool_Parameter_Type) },
    { no: 4, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tool_Parameter {
    return new Tool_Parameter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tool_Parameter {
    return new Tool_Parameter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tool_Parameter {
    return new Tool_Parameter().fromJsonString(jsonString, options);
  }

  static equals(a: Tool_Parameter | PlainMessage<Tool_Parameter> | undefined, b: Tool_Parameter | PlainMessage<Tool_Parameter> | undefined): boolean {
    return proto3.util.equals(Tool_Parameter, a, b);
  }
}

/**
 * @generated from enum ai.assistants.v0.Tool.Parameter.Type
 */
export enum Tool_Parameter_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: STRING = 1;
   */
  STRING = 1,

  /**
   * @generated from enum value: NUMBER = 2;
   */
  NUMBER = 2,

  /**
   * @generated from enum value: BOOLEAN = 3;
   */
  BOOLEAN = 3,

  /**
   * @generated from enum value: ENUM = 4;
   */
  ENUM = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Tool_Parameter_Type)
proto3.util.setEnumType(Tool_Parameter_Type, "ai.assistants.v0.Tool.Parameter.Type", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "STRING" },
  { no: 2, name: "NUMBER" },
  { no: 3, name: "BOOLEAN" },
  { no: 4, name: "ENUM" },
]);

/**
 * A request by the Assistant Run for the user to invoke a tool and provide a response
 *
 * @generated from message ai.assistants.v0.ToolCall
 */
export class ToolCall extends Message<ToolCall> {
  /**
   * Unique id of the tool call
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Name of the tool being called
   *
   * @generated from field: string tool_name = 2;
   */
  toolName = "";

  /**
   * Arguments to the tool
   *
   * @generated from field: string arguments = 3;
   */
  arguments = "";

  /**
   * The state of the tool call
   *
   * @generated from field: ai.assistants.v0.ToolCall.State state = 4;
   */
  state = ToolCall_State.STATE_UNSPECIFIED;

  /**
   * Indicates that the tool call failed
   *
   * @generated from field: bool error = 5;
   */
  error = false;

  /**
   * The response value to the tool call
   * This is only set when the user has responded to the tool call
   *
   * @generated from field: string response = 6;
   */
  response = "";

  constructor(data?: PartialMessage<ToolCall>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ToolCall";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tool_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "arguments", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "enum", T: proto3.getEnumType(ToolCall_State) },
    { no: 5, name: "error", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ToolCall {
    return new ToolCall().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ToolCall {
    return new ToolCall().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ToolCall {
    return new ToolCall().fromJsonString(jsonString, options);
  }

  static equals(a: ToolCall | PlainMessage<ToolCall> | undefined, b: ToolCall | PlainMessage<ToolCall> | undefined): boolean {
    return proto3.util.equals(ToolCall, a, b);
  }
}

/**
 * The state of a ToolCall
 *
 * @generated from enum ai.assistants.v0.ToolCall.State
 */
export enum ToolCall_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: RESPONSE_REQUIRED = 1;
   */
  RESPONSE_REQUIRED = 1,

  /**
   * @generated from enum value: COMPLETED = 2;
   */
  COMPLETED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ToolCall_State)
proto3.util.setEnumType(ToolCall_State, "ai.assistants.v0.ToolCall.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "RESPONSE_REQUIRED" },
  { no: 2, name: "COMPLETED" },
]);

