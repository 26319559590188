import {config} from "../config";
import { useUserState } from "../state/user";

export const useTenantInfo = () => {
  const { user } = useUserState();
  if (user) {
    user.email = user.email.toLowerCase()
  }

  const getEmailDomain = (email: string) => {
    return email?.split("@")[1] || "";
  };

  const userDomain = getEmailDomain(user?.email || "");

  const tenantKey = Object.keys(config.tenants).find((key) =>
    config.tenants[key].domains.includes(userDomain)
  );

  const tenantInfo = tenantKey ? config.tenants[tenantKey] : null;

  const isDeveloper = () => {
    return config.developerEmails.includes(user?.email || "");
  };

  const isAdmin = () => {
    return config.adminEmails.includes(user?.email || "");
  };

  return { user, tenantInfo, isDeveloper, isAdmin };
};
