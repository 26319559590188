// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/engine.proto (package ai, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// buf:lint:ignore PACKAGE_VERSION_SUFFIX

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Struct } from "@bufbuild/protobuf";
import { Message as Message$1 } from "./message_pb";

/**
 * A request to a streaming chat call
 *
 * @generated from message ai.ChatEngineRequest
 */
export class ChatEngineRequest extends Message<ChatEngineRequest> {
  /**
   * The model to use.
   * Values depend on chat engine implementation/
   *
   * @generated from field: string model = 1;
   */
  model = "";

  /**
   * The system prompt to send to the model.
   *
   * @generated from field: string system_prompt = 2;
   */
  systemPrompt = "";

  /**
   * The messages to send to the model.
   *
   * @generated from field: repeated ai.Message messages = 3;
   */
  messages: Message$1[] = [];

  /**
   * Tools definitions (function calls) to provide to the model.
   *
   * @generated from field: repeated ai.Tool tools = 4;
   */
  tools: Tool[] = [];

  /**
   * Maximum number of tokens to return
   * Some models may return fewer tokens, this value is an absolute maximum to the number of tokens returned.
   * If not provided, the model will use its default value.
   *
   * @generated from field: optional uint32 max_tokens = 7;
   */
  maxTokens?: number;

  /**
   * Sampling temperature to use, which impacts the randomness of the response.
   * Higher values result in more random responses while lower values result in more deterministic responses.
   *
   * @generated from field: optional float temperature = 8;
   */
  temperature?: number;

  /**
   * OPTIONAL. JSON schema for the response.
   * Not all models support this feature. This field will be will be ignored if not supported.
   *
   * @generated from field: ai.JsonSchemaResponseFormat response_json_schema = 9;
   */
  responseJsonSchema?: JsonSchemaResponseFormat;

  /**
   * Additional parameters passed to the engine implementation.
   *
   * @generated from oneof ai.ChatEngineRequest.params
   */
  params: {
    /**
     * @generated from field: ai.OpenAIParams openai_params = 5;
     */
    value: OpenAIParams;
    case: "openaiParams";
  } | {
    /**
     * @generated from field: ai.AnthropicParams anthropic_params = 6;
     */
    value: AnthropicParams;
    case: "anthropicParams";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ChatEngineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ChatEngineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "system_prompt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "messages", kind: "message", T: Message$1, repeated: true },
    { no: 4, name: "tools", kind: "message", T: Tool, repeated: true },
    { no: 7, name: "max_tokens", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
    { no: 8, name: "temperature", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 9, name: "response_json_schema", kind: "message", T: JsonSchemaResponseFormat },
    { no: 5, name: "openai_params", kind: "message", T: OpenAIParams, oneof: "params" },
    { no: 6, name: "anthropic_params", kind: "message", T: AnthropicParams, oneof: "params" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChatEngineRequest {
    return new ChatEngineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChatEngineRequest {
    return new ChatEngineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChatEngineRequest {
    return new ChatEngineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChatEngineRequest | PlainMessage<ChatEngineRequest> | undefined, b: ChatEngineRequest | PlainMessage<ChatEngineRequest> | undefined): boolean {
    return proto3.util.equals(ChatEngineRequest, a, b);
  }
}

/**
 * A response to a streaming chat call
 *
 * @generated from message ai.ChatEngineResponse
 */
export class ChatEngineResponse extends Message<ChatEngineResponse> {
  /**
   * The response message from the chat engine.
   *
   * @generated from field: ai.Message response = 1;
   */
  response?: Message$1;

  constructor(data?: PartialMessage<ChatEngineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ChatEngineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response", kind: "message", T: Message$1 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChatEngineResponse {
    return new ChatEngineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChatEngineResponse {
    return new ChatEngineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChatEngineResponse {
    return new ChatEngineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChatEngineResponse | PlainMessage<ChatEngineResponse> | undefined, b: ChatEngineResponse | PlainMessage<ChatEngineResponse> | undefined): boolean {
    return proto3.util.equals(ChatEngineResponse, a, b);
  }
}

/**
 * A schema specification for a JSON response.
 *
 * @generated from message ai.JsonSchemaResponseFormat
 */
export class JsonSchemaResponseFormat extends Message<JsonSchemaResponseFormat> {
  /**
   * REQUIRED. The JSON schema to use for the response.
   *
   * @generated from field: string schema = 2;
   */
  schema = "";

  /**
   * OPTIONAL. The description of the schema.
   * This may be used by the model to determine how to respond in the specified format.
   *
   * @generated from field: string description = 1;
   */
  description = "";

  constructor(data?: PartialMessage<JsonSchemaResponseFormat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.JsonSchemaResponseFormat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "schema", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JsonSchemaResponseFormat {
    return new JsonSchemaResponseFormat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JsonSchemaResponseFormat {
    return new JsonSchemaResponseFormat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JsonSchemaResponseFormat {
    return new JsonSchemaResponseFormat().fromJsonString(jsonString, options);
  }

  static equals(a: JsonSchemaResponseFormat | PlainMessage<JsonSchemaResponseFormat> | undefined, b: JsonSchemaResponseFormat | PlainMessage<JsonSchemaResponseFormat> | undefined): boolean {
    return proto3.util.equals(JsonSchemaResponseFormat, a, b);
  }
}

/**
 * A tool/function for a model to use.
 *
 * @generated from message ai.Tool
 */
export class Tool extends Message<Tool> {
  /**
   * Name of the function
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Plaintext description of the function
   *
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * The parameters accepted by the function, described using a JSONSchema object
   * TODO: Should we normalize this to make it easier?
   *
   * @generated from field: google.protobuf.Struct parameters = 3;
   */
  parameters?: Struct;

  constructor(data?: PartialMessage<Tool>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Tool";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parameters", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tool {
    return new Tool().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tool {
    return new Tool().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tool {
    return new Tool().fromJsonString(jsonString, options);
  }

  static equals(a: Tool | PlainMessage<Tool> | undefined, b: Tool | PlainMessage<Tool> | undefined): boolean {
    return proto3.util.equals(Tool, a, b);
  }
}

/**
 * Parameters for OpenAI API
 *
 * @generated from message ai.OpenAIParams
 */
export class OpenAIParams extends Message<OpenAIParams> {
  /**
   * Format of the response
   *
   * @generated from field: ai.OpenAIParams.ResponseFormat response_format = 4;
   */
  responseFormat = OpenAIParams_ResponseFormat.TEXT;

  constructor(data?: PartialMessage<OpenAIParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.OpenAIParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "response_format", kind: "enum", T: proto3.getEnumType(OpenAIParams_ResponseFormat) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OpenAIParams {
    return new OpenAIParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OpenAIParams {
    return new OpenAIParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OpenAIParams {
    return new OpenAIParams().fromJsonString(jsonString, options);
  }

  static equals(a: OpenAIParams | PlainMessage<OpenAIParams> | undefined, b: OpenAIParams | PlainMessage<OpenAIParams> | undefined): boolean {
    return proto3.util.equals(OpenAIParams, a, b);
  }
}

/**
 * The format of the response to return
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.OpenAIParams.ResponseFormat
 */
export enum OpenAIParams_ResponseFormat {
  /**
   * @generated from enum value: TEXT = 0;
   */
  TEXT = 0,

  /**
   * @generated from enum value: JSON = 1;
   */
  JSON = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(OpenAIParams_ResponseFormat)
proto3.util.setEnumType(OpenAIParams_ResponseFormat, "ai.OpenAIParams.ResponseFormat", [
  { no: 0, name: "TEXT" },
  { no: 1, name: "JSON" },
]);

/**
 * Parameters for Anthropic API
 *
 * @generated from message ai.AnthropicParams
 */
export class AnthropicParams extends Message<AnthropicParams> {
  constructor(data?: PartialMessage<AnthropicParams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.AnthropicParams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnthropicParams {
    return new AnthropicParams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnthropicParams {
    return new AnthropicParams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnthropicParams {
    return new AnthropicParams().fromJsonString(jsonString, options);
  }

  static equals(a: AnthropicParams | PlainMessage<AnthropicParams> | undefined, b: AnthropicParams | PlainMessage<AnthropicParams> | undefined): boolean {
    return proto3.util.equals(AnthropicParams, a, b);
  }
}

/**
 * A model available to the chat engine.
 *
 * @generated from message ai.Model
 */
export class Model extends Message<Model> {
  /**
   * The machine readable name of the model
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * The human readable name of the model
   *
   * @generated from field: string display_name = 4;
   */
  displayName = "";

  /**
   * Description of the model
   *
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * The underlying model this model is an alias for
   * This will only be set for models that are aliases for other models.
   *
   * @generated from field: string alias_for = 6;
   */
  aliasFor = "";

  /**
   * The parameters accepted by the model
   *
   * @generated from field: map<string, ai.Model.Parameter> parameters = 2;
   */
  parameters: { [key: string]: Model_Parameter } = {};

  /**
   * Whether the models supports accepting images as input
   * Deprecated: Use `supported_input_formats` instead.
   *
   * @generated from field: bool supports_image_input = 3 [deprecated = true];
   * @deprecated
   */
  supportsImageInput = false;

  /**
   * The set of input formats supported by the model
   *
   * @generated from field: repeated ai.Model.InputFormats supported_input_formats = 7;
   */
  supportedInputFormats: Model_InputFormats[] = [];

  /**
   * Tags that indicate features or attributes of a model
   *
   * @generated from field: repeated ai.Model.Tag tags = 8;
   */
  tags: Model_Tag[] = [];

  /**
   * Indicates that the model is recommended for use by the Applied AI team.
   *
   * @generated from field: bool recommended = 9;
   */
  recommended = false;

  /**
   * Indicates that the model is deprecated and should not be used.
   *
   * @generated from field: ai.Model.LaunchStage launch_stage = 10;
   */
  launchStage = Model_LaunchStage.GA;

  /**
   * The suggested replacement model to use if this model's launch stage is `DEPRECATED`.
   *
   * @generated from field: string replacement = 11;
   */
  replacement = "";

  constructor(data?: PartialMessage<Model>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Model";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "alias_for", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parameters", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Model_Parameter} },
    { no: 3, name: "supports_image_input", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "supported_input_formats", kind: "enum", T: proto3.getEnumType(Model_InputFormats), repeated: true },
    { no: 8, name: "tags", kind: "enum", T: proto3.getEnumType(Model_Tag), repeated: true },
    { no: 9, name: "recommended", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "launch_stage", kind: "enum", T: proto3.getEnumType(Model_LaunchStage) },
    { no: 11, name: "replacement", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Model {
    return new Model().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Model {
    return new Model().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Model {
    return new Model().fromJsonString(jsonString, options);
  }

  static equals(a: Model | PlainMessage<Model> | undefined, b: Model | PlainMessage<Model> | undefined): boolean {
    return proto3.util.equals(Model, a, b);
  }
}

/**
 * Launch stage of the model
 * buf:lint:ignore ENUM_VALUE_PREFIX
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 *
 * @generated from enum ai.Model.LaunchStage
 */
export enum Model_LaunchStage {
  /**
   * @generated from enum value: GA = 0;
   */
  GA = 0,

  /**
   * @generated from enum value: BETA = 1;
   */
  BETA = 1,

  /**
   * @generated from enum value: DEPRECATED = 2;
   */
  DEPRECATED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Model_LaunchStage)
proto3.util.setEnumType(Model_LaunchStage, "ai.Model.LaunchStage", [
  { no: 0, name: "GA" },
  { no: 1, name: "BETA" },
  { no: 2, name: "DEPRECATED" },
]);

/**
 * Input formats supported by the model
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.Model.InputFormats
 */
export enum Model_InputFormats {
  /**
   * @generated from enum value: INPUT_FORMAT_UNSPECIFIED = 0;
   */
  INPUT_FORMAT_UNSPECIFIED = 0,

  /**
   * The model accepts text input
   *
   * @generated from enum value: TEXT = 1;
   */
  TEXT = 1,

  /**
   * The model accepts image input
   *
   * @generated from enum value: IMAGE = 2;
   */
  IMAGE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Model_InputFormats)
proto3.util.setEnumType(Model_InputFormats, "ai.Model.InputFormats", [
  { no: 0, name: "INPUT_FORMAT_UNSPECIFIED" },
  { no: 1, name: "TEXT" },
  { no: 2, name: "IMAGE" },
]);

/**
 * Tags that indicate features or attributes of a model
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.Model.Tag
 */
export enum Model_Tag {
  /**
   * @generated from enum value: TAG_UNSPECIFIED = 0;
   */
  TAG_UNSPECIFIED = 0,

  /**
   * @generated from enum value: CREATIVE_WRITING = 1;
   */
  CREATIVE_WRITING = 1,

  /**
   * @generated from enum value: CODING = 2;
   */
  CODING = 2,

  /**
   * @generated from enum value: FAST = 3;
   */
  FAST = 3,

  /**
   * @generated from enum value: ONLINE_ACCESS = 4;
   */
  ONLINE_ACCESS = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Model_Tag)
proto3.util.setEnumType(Model_Tag, "ai.Model.Tag", [
  { no: 0, name: "TAG_UNSPECIFIED" },
  { no: 1, name: "CREATIVE_WRITING" },
  { no: 2, name: "CODING" },
  { no: 3, name: "FAST" },
  { no: 4, name: "ONLINE_ACCESS" },
]);

/**
 * A parameter that the model accepts.
 *
 * @generated from message ai.Model.Parameter
 */
export class Model_Parameter extends Message<Model_Parameter> {
  /**
   * The name of the parameter
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * The default value of the parameter
   *
   * @generated from field: ai.Model.Parameter.Value default_value = 2;
   */
  defaultValue?: Model_Parameter_Value;

  /**
   * The minimum value of the parameter
   *
   * @generated from field: ai.Model.Parameter.Value min_value = 3;
   */
  minValue?: Model_Parameter_Value;

  /**
   * The maximum value of the parameter
   *
   * @generated from field: ai.Model.Parameter.Value max_value = 4;
   */
  maxValue?: Model_Parameter_Value;

  constructor(data?: PartialMessage<Model_Parameter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Model.Parameter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "default_value", kind: "message", T: Model_Parameter_Value },
    { no: 3, name: "min_value", kind: "message", T: Model_Parameter_Value },
    { no: 4, name: "max_value", kind: "message", T: Model_Parameter_Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Model_Parameter {
    return new Model_Parameter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Model_Parameter {
    return new Model_Parameter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Model_Parameter {
    return new Model_Parameter().fromJsonString(jsonString, options);
  }

  static equals(a: Model_Parameter | PlainMessage<Model_Parameter> | undefined, b: Model_Parameter | PlainMessage<Model_Parameter> | undefined): boolean {
    return proto3.util.equals(Model_Parameter, a, b);
  }
}

/**
 * @generated from message ai.Model.Parameter.Value
 */
export class Model_Parameter_Value extends Message<Model_Parameter_Value> {
  /**
   * @generated from oneof ai.Model.Parameter.Value.value
   */
  value: {
    /**
     * @generated from field: string string_value = 1;
     */
    value: string;
    case: "stringValue";
  } | {
    /**
     * @generated from field: int32 int_value = 2;
     */
    value: number;
    case: "intValue";
  } | {
    /**
     * @generated from field: double double_value = 3;
     */
    value: number;
    case: "doubleValue";
  } | {
    /**
     * @generated from field: bool bool_value = 4;
     */
    value: boolean;
    case: "boolValue";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Model_Parameter_Value>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Model.Parameter.Value";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "string_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 2, name: "int_value", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "value" },
    { no: 3, name: "double_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "value" },
    { no: 4, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Model_Parameter_Value {
    return new Model_Parameter_Value().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Model_Parameter_Value {
    return new Model_Parameter_Value().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Model_Parameter_Value {
    return new Model_Parameter_Value().fromJsonString(jsonString, options);
  }

  static equals(a: Model_Parameter_Value | PlainMessage<Model_Parameter_Value> | undefined, b: Model_Parameter_Value | PlainMessage<Model_Parameter_Value> | undefined): boolean {
    return proto3.util.equals(Model_Parameter_Value, a, b);
  }
}

/**
 * A request to list available models in the chat engine.
 *
 * @generated from message ai.ListModelsRequest
 */
export class ListModelsRequest extends Message<ListModelsRequest> {
  constructor(data?: PartialMessage<ListModelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ListModelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelsRequest {
    return new ListModelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelsRequest | PlainMessage<ListModelsRequest> | undefined, b: ListModelsRequest | PlainMessage<ListModelsRequest> | undefined): boolean {
    return proto3.util.equals(ListModelsRequest, a, b);
  }
}

/**
 * A response to a list models request.
 *
 * @generated from message ai.ListModelsResponse
 */
export class ListModelsResponse extends Message<ListModelsResponse> {
  /**
   * The models available to the chat engine.
   *
   * @generated from field: repeated ai.Model models = 1;
   */
  models: Model[] = [];

  constructor(data?: PartialMessage<ListModelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.ListModelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "models", kind: "message", T: Model, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListModelsResponse {
    return new ListModelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListModelsResponse | PlainMessage<ListModelsResponse> | undefined, b: ListModelsResponse | PlainMessage<ListModelsResponse> | undefined): boolean {
    return proto3.util.equals(ListModelsResponse, a, b);
  }
}

