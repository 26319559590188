// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=ts,import_extension=none"
// @generated from file slack/slack_config.proto (package slack, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetConfigRequest, GetConfigResponse, SetConfigRequest } from "./slack_config_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service slack.SlackConfig
 */
export const SlackConfig = {
  typeName: "slack.SlackConfig",
  methods: {
    /**
     * caller of this api uses a JWT that contains the request details
     *
     * @generated from rpc slack.SlackConfig.GetConfig
     */
    getConfig: {
      name: "GetConfig",
      I: GetConfigRequest,
      O: GetConfigResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc slack.SlackConfig.SetConfig
     */
    setConfig: {
      name: "SetConfig",
      I: SetConfigRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

