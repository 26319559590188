import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  HStack,
  Heading,
  Input,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  VStack
} from "@chakra-ui/react";
import CustomSwitch from "../../components/CustomSwitch";
import { Assistant } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import { MdStorage } from "react-icons/md";
import Expandable from "../Expandable";
import ModelSettingsBox from "./ModelSettings";
// import AssistantFunctions from "./Functions";
import TabAssistantFiles from "./TabAssistantFiles";
import TabAssistantAdvanced from "./TabAssistantAdvanced";
import TabAssistantUsers from "./TabAssistantUsers";
import { useUserState } from "../../state/user";
import { useModels } from "../../state/models";
import ChangeModelDialog from "./ChangeModelDialog";
// import TabAssistantChannels from "./TabAssistantChannels";
import { NumberParam, useQueryParam } from "use-query-params";



interface Props {
  data: Assistant;
  onUpdate: (assistant: Assistant) => void;
  disabled?: boolean;
  isUploader: boolean;
  isOwner: boolean;
}

const ManageAssistantForm: React.FC<Props> = ({ data, disabled, onUpdate, isOwner, isUploader }) => {

  const [ assistant, setAssistant ] = useState<Assistant>(data.clone());
  const assistantRef = useRef<Assistant>(data.clone());

  const models = useModels();
  const user = useUserState();

  const [ currTab, setCurrTab ] = useQueryParam('tab', NumberParam);

  const [ changeModelOpen, setChangeModelOpen ] = useState(false);

  const leftColumnWidth = {
    'base': '100%',
    'md': '250px',
  };

  useEffect(() => {
    models.load();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!data.equals(assistantRef.current)) {
      setAssistant(data.clone());
      assistantRef.current = data.clone();
    }
  }, [ data ])

  const toggleVisibility = (id: string) => {
    if (id === 'private') {
      assistant.viewers = [];
    }
    else {
      const domain = user.user!.email.split('@')[ 1 ].toLowerCase();
      if (!assistant.viewers) {
        assistant.viewers = [];
      }
      assistant.viewers.push(`domain:${domain}`);
    }

    setAssistant(assistant.clone());
    onUpdate(assistant.clone());
  }

  const isPublic = assistant.viewers?.length > 0;

  const model = models.models[ assistant.model ];
  const modelEngine = models.modelEngines[ assistant.model ];

  return (
    <VStack mt={5} width="100%" align="start">
      <Stack
        direction={{
          base: "column",
          lg: "row"
        }}
        align={{
          base: "start",
          lg: "center"
        }}
        width="100%"
      >
        <Heading size="md" width={leftColumnWidth}>Assistant Name</Heading>
        <Input
          isDisabled={disabled}
          isReadOnly={!isOwner}
          flexGrow={1}
          width={{
            base: "100%",
            lg: "auto"
          }}
          value={assistant.displayName ?? ''}
          onChange={(e) => {
            assistant.displayName = e.target.value;
            setAssistant(assistant.clone());
            onUpdate(assistant.clone());
          }}
        />
        <CustomSwitch
          items={[
            { id: 'private', name: 'Private' },
            { id: 'public', name: 'Public' },
          ]}
          selected={isPublic ? 'public' : 'private'}
          onSelect={toggleVisibility}
          isDisabled={disabled || !isOwner}
        />
      </Stack>

      <Divider my={5} />

      <Stack
        direction="row"
        align={{
          base: "start",
          lg: "center"
        }}
        justify="space-between"
        width="100%"
      >
        <Heading size="md" width={leftColumnWidth}>Certified</Heading>
        <Switch
          size="lg"
          colorScheme="primary"
          isDisabled
          isChecked={assistant.certified}

        />
      </Stack>

      <Divider my={5} />

      <Stack
        direction={{
          base: "column",
          lg: "row"
        }}
        align="start"
        width="100%"
      >
        <Heading size="md" width={leftColumnWidth}>Assistant Instructions</Heading>
        <Textarea
          value={assistant.instructions ?? ''}
          onChange={(e) => {
            assistant.instructions = e.target.value;
            setAssistant(assistant.clone());
            onUpdate(assistant.clone());
          }}
          flexGrow={1}
          width={{
            base: "100%",
            lg: "auto"
          }}
          rows={10}
          isDisabled={disabled}
          isReadOnly={!isOwner}
        />
      </Stack>

      <Divider my={5} />

      <Stack
        direction={{
          base: "column",
          lg: "row"
        }}
        align="start"
        width="100%"
      >
        <Heading size="md" width={leftColumnWidth}>Bot Description</Heading>
        <Textarea
          value={assistant.description ?? ''}
          onChange={(e) => {
            assistant.description = e.target.value;
            setAssistant(assistant.clone());
            onUpdate(assistant.clone());
          }}
          placeholder="Add your own or we’ll generate one for you"
          width={{
            base: "100%",
            lg: "auto"
          }}
          flexGrow={1}
          rows={2}
          isDisabled={disabled}
          isReadOnly={!isOwner}
        />
      </Stack>

      <Divider my={5} />

      <Stack
        direction={{
          base: "column",
          lg: "row"
        }}
        align={{
          base: "initial",
          lg: "start"
        }}
        width="100%"
      >
        <Heading size="md" width={leftColumnWidth}>Model</Heading>
        <VStack flexGrow={1}>
          <HStack justify="space-between" width="100%" align="start" mb={4}>
            <Text>
              {modelEngine} - {model?.name}
            </Text>
            <Button
              leftIcon={<MdStorage />}
              onClick={() => setChangeModelOpen(true)}
              isDisabled={disabled || !isOwner}
            >
              Change Model
            </Button>
          </HStack>
          <ChangeModelDialog
            isOpen={changeModelOpen}
            onClose={() => setChangeModelOpen(false)}
            selectedModel={assistant.model}
            onSelect={(selectedModel, selectedEngine) => {
              assistant.model = selectedModel;
              assistant.engine = selectedEngine;
              
              setAssistant(assistant.clone());
              onUpdate(assistant.clone());
              setChangeModelOpen(false);
            }}
          />
          <Expandable title="Model Details">
            <ModelSettingsBox
              disabled={disabled || !isOwner}
              modelSettings={assistant.modelSettings!}
              params={model?.parameters}
              onUpdate={(modelSettings) => {
                assistant.modelSettings = modelSettings;
                setAssistant(assistant.clone());
                onUpdate(assistant.clone());
              }}
            />
          </Expandable>
        </VStack>
      </Stack>

      <Divider my={5} />

      {/* <Stack
        direction={{
          base: "column",
          lg: "row"
        }}
        align={{
          base: "initial",
          lg: "start"
        }}
        width="100%"
      >
        <Heading size="md" width={leftColumnWidth}>Functions</Heading>
        <VStack flexGrow={1}>
          <HStack justify="space-between" width="100%" align="start" mb={4}>
            <Text>
              All
            </Text>
            <Switch size="lg" colorScheme="primary" isDisabled={disabled} />
          </HStack>
          <Expandable title="Toggle functions by group">
            <AssistantFunctions />
          </Expandable>
        </VStack>
      </Stack>

      <Divider my={5} /> */}

      <Tabs
        width="100%"
        colorScheme="gray"
        index={currTab ?? 0}
        onChange={(index) => setCurrTab(index, 'replaceIn')}
      >
        <TabList>
          <Tab>File Training</Tab>
          <Tab>Advanced</Tab>
          <Tab>Users</Tab>
          {/* <Tab>Channels</Tab> */}
        </TabList>

        <TabPanels>
          <TabPanel px={0}>
            <TabAssistantFiles
              assistant={assistant}
              onUpdate={(assistant) => {
                setAssistant(assistant.clone());
                onUpdate(assistant.clone());
              }}
            />
          </TabPanel>
          <TabPanel px={0}>
            <TabAssistantAdvanced
              assistant={assistant}
              disabled={disabled || !isOwner}
              onUpdate={(assistant) => {
                setAssistant(assistant.clone());
                onUpdate(assistant.clone());
              }}
            />
          </TabPanel>
          <TabPanel px={0}>
            <TabAssistantUsers
              assistant={assistant}
              onUpdate={(assistant) => {
                setAssistant(assistant.clone());
                onUpdate(assistant.clone());
              }}
            />
          </TabPanel>
          {/* <TabPanel px={0}>
            <TabAssistantChannels />
          </TabPanel> */}
        </TabPanels>
      </Tabs>

    </VStack>
  );
};

export default ManageAssistantForm;