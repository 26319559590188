import { extendTheme } from "@chakra-ui/react";



const theme = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: false,
  colors: {
    primary: {
      50: '#dcfffa',
      100: '#b0fff1',
      200: '#81fde7',
      300: '#53fcde',
      400: '#2ffad4',
      500: '#20e1bb',
      600: '#11b091',
      700: '#027e68',
      800: '#004b3e',
      900: '#001b15',
    },
    lightBgColor: 
    {
      50: '#eef7f4',
      100: '#d2e4de',
      200: '#b3d3c7',
      300: '#93c2b2',
      400: '#74b19b',
      500: '#5b9781',
      600: '#477565',
      700: '#345348',
      800: '#1f322b',
      900: '#08110e',
    },
    darkBgColor: {
      50: '#e7f9f5',
      100: '#c9e8e2',
      200: '#a9d8cf',
      300: '#87c9bc',
      400: '#66baa9',
      500: '#4ea18f',
      600: '#3c7d70',
      700: '#2a5950',
      800: '#173630',
      900: '#001310',
    },
  }

});


export default theme;

