// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/assistants/v0/assistant.proto (package ai.assistants.v0, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// buf:lint:ignore PACKAGE_VERSION_SUFFIX

import { Assistant, CreateAssistantRequest, CreateMessageRequest, CreateRunFeedbackRequest, CreateRunRequest, CreateThreadAndRunRequest, CreateThreadRequest, DeleteAssistantRequest, DeleteRunRequest, DeleteThreadRequest, GetAssistantRequest, GetMessageRequest, GetRunRequest, GetThreadRequest, ListAssistantsRequest, ListAssistantsResponse, ListEnginesRequest, ListEnginesResponse, ListMessagesRequest, ListMessagesResponse, ListRunsRequest, ListRunsResponse, ListThreadsRequest, ListThreadsResponse, Message, Run, RunFeedback, ShareThreadRequest, StreamRunResultsRequest, StreamRunResultsResponse, SubmitToolResponsesRequest, TestAssistantPermissionsRequest, TestAssistantPermissionsResponse, Thread, UpdateAssistantRequest, UpdateThreadRequest } from "./assistant_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * buf:lint:ignore SERVICE_SUFFIX
 * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
 * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
 *
 * @generated from service ai.assistants.v0.Assistants
 */
export const Assistants = {
  typeName: "ai.assistants.v0.Assistants",
  methods: {
    /**
     * Retrieves a list of chat engines and their available models.
     *
     * @generated from rpc ai.assistants.v0.Assistants.ListEngines
     */
    listEngines: {
      name: "ListEngines",
      I: ListEnginesRequest,
      O: ListEnginesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Creates an Assistant.
     *
     * @generated from rpc ai.assistants.v0.Assistants.CreateAssistant
     */
    createAssistant: {
      name: "CreateAssistant",
      I: CreateAssistantRequest,
      O: Assistant,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieves an Assistant by ID.
     *
     * @generated from rpc ai.assistants.v0.Assistants.GetAssistant
     */
    getAssistant: {
      name: "GetAssistant",
      I: GetAssistantRequest,
      O: Assistant,
      kind: MethodKind.Unary,
    },
    /**
     * Lists Assistants.
     *
     * @generated from rpc ai.assistants.v0.Assistants.ListAssistants
     */
    listAssistants: {
      name: "ListAssistants",
      I: ListAssistantsRequest,
      O: ListAssistantsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Updates an Assistant.
     *
     * @generated from rpc ai.assistants.v0.Assistants.UpdateAssistant
     */
    updateAssistant: {
      name: "UpdateAssistant",
      I: UpdateAssistantRequest,
      O: Assistant,
      kind: MethodKind.Unary,
    },
    /**
     * Deletes an Assistant.
     *
     * @generated from rpc ai.assistants.v0.Assistants.DeleteAssistant
     */
    deleteAssistant: {
      name: "DeleteAssistant",
      I: DeleteAssistantRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Returns permissions that a caller has on the specified Assistant.
     * If the resource does not exist, this will return an empty set of
     * permissions, not a `NOT_FOUND` error.
     *
     * @generated from rpc ai.assistants.v0.Assistants.TestAssistantPermissions
     */
    testAssistantPermissions: {
      name: "TestAssistantPermissions",
      I: TestAssistantPermissionsRequest,
      O: TestAssistantPermissionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a Thread.
     *
     * @generated from rpc ai.assistants.v0.Assistants.CreateThread
     */
    createThread: {
      name: "CreateThread",
      I: CreateThreadRequest,
      O: Thread,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieves a Thread By ID.
     *
     * @generated from rpc ai.assistants.v0.Assistants.GetThread
     */
    getThread: {
      name: "GetThread",
      I: GetThreadRequest,
      O: Thread,
      kind: MethodKind.Unary,
    },
    /**
     * Lists Threads.
     *
     * @generated from rpc ai.assistants.v0.Assistants.ListThreads
     */
    listThreads: {
      name: "ListThreads",
      I: ListThreadsRequest,
      O: ListThreadsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Update a Thread.
     *
     * @generated from rpc ai.assistants.v0.Assistants.UpdateThread
     */
    updateThread: {
      name: "UpdateThread",
      I: UpdateThreadRequest,
      O: Thread,
      kind: MethodKind.Unary,
    },
    /**
     * Deletes a Thread.
     *
     * @generated from rpc ai.assistants.v0.Assistants.DeleteThread
     */
    deleteThread: {
      name: "DeleteThread",
      I: DeleteThreadRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Snapshots a thread and marks it as shared.
     * Shared threads are immutable and can be viewed by all users that have been provided the ID.
     *
     * @generated from rpc ai.assistants.v0.Assistants.ShareThread
     */
    shareThread: {
      name: "ShareThread",
      I: ShareThreadRequest,
      O: Thread,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a Message in a Thread.
     *
     * @generated from rpc ai.assistants.v0.Assistants.CreateMessage
     */
    createMessage: {
      name: "CreateMessage",
      I: CreateMessageRequest,
      O: Message,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieves a Message by ID.
     *
     * @generated from rpc ai.assistants.v0.Assistants.GetMessage
     */
    getMessage: {
      name: "GetMessage",
      I: GetMessageRequest,
      O: Message,
      kind: MethodKind.Unary,
    },
    /**
     * Lists Messages in a Thread.
     *
     * @generated from rpc ai.assistants.v0.Assistants.ListMessages
     */
    listMessages: {
      name: "ListMessages",
      I: ListMessagesRequest,
      O: ListMessagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a Thread and then creates a Run on that Thread.
     *
     * @generated from rpc ai.assistants.v0.Assistants.CreateThreadAndRun
     */
    createThreadAndRun: {
      name: "CreateThreadAndRun",
      I: CreateThreadAndRunRequest,
      O: Run,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a Run on a Thread.
     *
     * @generated from rpc ai.assistants.v0.Assistants.CreateRun
     */
    createRun: {
      name: "CreateRun",
      I: CreateRunRequest,
      O: Run,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieves a Run by ID.
     *
     * @generated from rpc ai.assistants.v0.Assistants.GetRun
     */
    getRun: {
      name: "GetRun",
      I: GetRunRequest,
      O: Run,
      kind: MethodKind.Unary,
    },
    /**
     * Lists Runs.
     *
     * @generated from rpc ai.assistants.v0.Assistants.ListRuns
     */
    listRuns: {
      name: "ListRuns",
      I: ListRunsRequest,
      O: ListRunsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Deletes a Run.
     *
     * @generated from rpc ai.assistants.v0.Assistants.DeleteRun
     */
    deleteRun: {
      name: "DeleteRun",
      I: DeleteRunRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Streams the results generated by a Run.
     *
     * @generated from rpc ai.assistants.v0.Assistants.StreamRunResults
     */
    streamRunResults: {
      name: "StreamRunResults",
      I: StreamRunResultsRequest,
      O: StreamRunResultsResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Submits on or more responses to ToolCalls required by a Run.
     * buf:lint:ignore RPC_REQUEST_STANDARD_NAME
     *
     * @generated from rpc ai.assistants.v0.Assistants.SubmitToolResponse
     */
    submitToolResponse: {
      name: "SubmitToolResponse",
      I: SubmitToolResponsesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a feedback entry for a Run.
     *
     * @generated from rpc ai.assistants.v0.Assistants.CreateRunFeedback
     */
    createRunFeedback: {
      name: "CreateRunFeedback",
      I: CreateRunFeedbackRequest,
      O: RunFeedback,
      kind: MethodKind.Unary,
    },
  }
} as const;

