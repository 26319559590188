import React, { useEffect, useMemo } from "react";
import { useAssistants } from "../state/assistants";
import { HStack, Icon, Input, InputGroup, InputLeftElement, Skeleton, VStack, Wrap, WrapItem, useColorModeValue } from "@chakra-ui/react";
import { MdAccountCircle, MdSearch, MdStar, MdVerifiedUser } from "react-icons/md";
import AssistantFilterButton from "../components/FilterButton";
import { AssistantFilter } from "../types";
import AssistantCard from "../components/AssistantCard";
import { Assistant } from "../gen-ts/ai/assistants/v0/assistant_pb";
import { StringParam, useQueryParam } from "use-query-params";
import { useUserState } from "../state/user";

interface Props {
  onSelect: (assistantId: string) => void;
  onEdit?: (assistantId: string) => void;
  unselectableIds?: string[];
}

const AssistantsGrid: React.FC<Props> = ({ onSelect, onEdit, unselectableIds }) => {
  const [ searchQuery, setSearchQuery ] = useQueryParam("query", StringParam);
  const assistantsState = useAssistants();
  const user = useUserState();

  useEffect(() => {
    assistantsState.loadList();
    //eslint-disable-next-line
  }, []);

  const assistantById = Boolean(searchQuery) && assistantsState.assistants[ searchQuery! ] ? assistantsState.assistants[ searchQuery! ] : null;

  const assistants = useMemo(() => {
    return Object.values(assistantsState.assistants).sort((a, b) => {
      return a.displayName > b.displayName ? 1 : -1;
    });
  }, [ assistantsState.assistants ]);

  let searchedAssistants = useMemo(() => {
    if (!searchQuery) {
      return assistants;
    }


    const result = assistantsState.searchIndex.search(searchQuery);
    const list: Assistant[] = [];
    for (const id of result) {
      if (assistantsState.assistants[ id ]) {
        list.push(assistantsState.assistants[ id ]);
      }
    }

    return list;
    //eslint-disable-next-line
  }, [ assistants, searchQuery, assistantsState.newAssistants.length ]);

  let filteredAssistants = useMemo(() => {
    if (assistantsState.filters.length === 0) {
      return searchedAssistants;
    }

    return searchedAssistants.filter((assistant) => {
      if (assistantsState.filters.includes(AssistantFilter.MY_ASSISTANTS) && !assistant.owners.includes(`user:${user.user!.email}`)) {
        return false;
      }

      if (assistantsState.filters.includes(AssistantFilter.FAVORITES) && !user.favoriteAssistants.includes(assistant.id)) {
        return false;
      }

      if (assistantsState.filters.includes(AssistantFilter.CERTIFIED) && !assistant.certified) {
        return false;
      }

      return true;
    });

    //eslint-disable-next-line
  }, [ searchedAssistants, assistantsState.filters ]);


  if (assistantById) {
    filteredAssistants = [ assistantById, ...filteredAssistants ];
  }


  const gridBgColor = useColorModeValue("gray.100", "gray.800");
  const searchBorderColor = useColorModeValue("gray.300", "gray.600");


  return (
    <VStack align="start" mt={4}>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <Icon as={MdSearch} size="lg" />
        </InputLeftElement>
        <Input
          placeholder="Search"
          borderRadius={20}
          borderColor={searchBorderColor}
          borderWidth={2}
          value={searchQuery || ''}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>

      <HStack spacing={2} width="100%" overflow="scroll">
        <AssistantFilterButton
          icon={MdVerifiedUser}
          title="Certified"
          isSelected={assistantsState.filters.includes(AssistantFilter.CERTIFIED)}
          onClick={() => assistantsState.toggleFilter(AssistantFilter.CERTIFIED)}
        />
        <AssistantFilterButton
          icon={MdStar}
          title="Favorites"
          isSelected={assistantsState.filters.includes(AssistantFilter.FAVORITES)}
          onClick={() => assistantsState.toggleFilter(AssistantFilter.FAVORITES)}
        />
        <AssistantFilterButton
          icon={MdAccountCircle}
          title="My Assistants"
          isSelected={assistantsState.filters.includes(AssistantFilter.MY_ASSISTANTS)}
          onClick={() => assistantsState.toggleFilter(AssistantFilter.MY_ASSISTANTS)}
        />
      </HStack>

      <Wrap bgColor={gridBgColor} borderRadius={30} p={6} spacing={4} width="100%" mt={5} justify="space-evenly">
        {
          !assistantsState.loadingList && filteredAssistants.map((assistant, index) => {
            return (
              <WrapItem key={index}>
                <AssistantCard
                  assistant={assistant}
                  onSelect={onSelect}
                  onEdit={onEdit}
                  disableSelect={unselectableIds?.includes(assistant.id)}
                />
              </WrapItem>
            );
          })
        }
        {
          assistantsState.loadingList && (
            <>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
              <WrapItem>
                <Skeleton width="350px" height="300px" borderRadius={20} />
              </WrapItem>
            </>
          )
        }

      </Wrap>
    </VStack>
  );
};

export default AssistantsGrid;