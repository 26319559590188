import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import React from "react";
import { useFileManager } from "./FileManagerProvider";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { getRootFolder } from "../../../state/assistantFiles";



const BreadCrumbs: React.FC = () => {
  const fileManager = useFileManager();

  const parts = fileManager.currentPath === getRootFolder().path ? [ '' ] : fileManager.currentPath.split('/');
  // const onDropBgColor = useColorModeValue("primary.500", "gray.700");

  // console.log(fileManager.activeDropZone, fileManager.activeDragItem);

  return (
    <Breadcrumb fontWeight="600" fontSize="2xl" separator={<ChevronRightIcon pointerEvents="none" />} spacing={0}>
      {
        parts.map((part, index) => {
          const path = parts.slice(0, index + 1).join('/') || '';
          return (
            <BreadcrumbItem
              key={index}
              px={0}
            >
              <BreadcrumbLink
                data-path={path}
                data-dir="true"
                display="block"
                m={0}
                borderRadius={5}
                px={2}
                onClick={() => { fileManager.setCurrentPath(path); }}
              >
                {part || getRootFolder().name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })
      }

    </Breadcrumb>
  );
};

export default BreadCrumbs;