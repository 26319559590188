import { Box, HStack, Heading, Portal } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { AppFrameContext } from "./AppFrame";



interface AppPageHeaderProps {
  title?: string;
  actions?: React.ReactNode;
  onlyMobile?: boolean;
  updateTitle?: boolean;
}

const AppPageHeader: React.FC<AppPageHeaderProps> = ({ title, actions, onlyMobile, updateTitle = true }) => {
  const context = useContext(AppFrameContext);

  useEffect(() => {
    if (updateTitle && title) {
      document.title = title;
    }
    else {
      document.title = 'R2G2 App';
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Portal containerRef={context.titleRef}>
        <HStack spacing={0} width="calc(100vw - 106px)">
        {
          title && <Heading flexGrow={1} size="sm">{title}</Heading>
        }
        {actions}
        </HStack>
      </Portal>

      {
        !onlyMobile && (
          <HStack
           display={{
            base: 'none',
            lg: 'flex'
          }}
          width='100%'
          >
            {
              title && <Box flexGrow={1}><Heading flexGrow={1} size="lg">{title}</Heading></Box>
            }
            <HStack spacing={1}>
              {actions}
            </HStack>
          </HStack>
        )
      }

    </>
  );
};

export default AppPageHeader;