import React from "react";
import ChatUI from "../components/ChatUI/ChatUI";




const HomePage: React.FC = () => {
  return (
    <ChatUI />
  );
};

export default HomePage;