import { Box, CircularProgress, HStack, Icon, IconButton, Image, Text, Tooltip, VStack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useFileUpload } from "./FileUpload";
import { MdAttachFile, MdClose, MdError } from "react-icons/md";
import { ChatFile } from "../../types";





const InputFiles: React.FC = () => {
  const fileUpload = useFileUpload();

  if (fileUpload.files.length === 0) {
    return null;
  }

  return (
    <HStack
      height={`${fileUpload.renderContainerHeight}px`}
      // bgColor="red"
      width="100%"
      overflowX="scroll"
      px={4}
      flexShrink={0}
      pb={2}
      pt="13px"
      align="start"
      role="group"
      spacing={4}
    >
      {
        fileUpload.files.map((file, index) => {
          var props = {
            file: file,
            height: fileUpload.renderContainerHeight,
            onDelete: () => {
              fileUpload.deleteFile(index);
            }
          }
          return file.file.type.startsWith('image/') ?
            <ImageFile
              key={`${file.file.name}-${index}`}
              {...props}
            /> :
            <OtherFile
              key={`${file.file.name}-${index}`}
              {...props}
            />;
        })
      }
    </HStack>
  );
};

export default InputFiles;



interface Props {
  file: ChatFile;
  height: number;
  onDelete: () => void;
}


const FileDeleteButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {

  return (
    <IconButton
      onClick={onClick}
      icon={<MdClose />}
      aria-label="delete file"
      size="xs"
      position="absolute"
      top="-10px"
      borderRadius={50}
      right="-10px"
      opacity="0"
      transition="opacity 0.2s"
      boxShadow="md"
      borderWidth="1px"
      bgColor={useColorModeValue('gray.200', 'gray.800')}
      borderColor={useColorModeValue('gray.300', 'gray.600')}
      _groupHover={{
        opacity: 1,
      }}
    />
  );
}
// const ProgressBackdrop: React.FC<> = () => {
// }

const ImageFile: React.FC<Props> = ({ file, height, onDelete }) => {

  return (
    <Box position="relative" flexShrink={0}>
      <Box overflow="hidden" borderRadius={5}>
        <Image src={file.image} height={`${height - 15}px`} />
      </Box>
      {
        (file.status === 'uploading' || file.status === 'error') && (
          <Box
            borderRadius={5}
            bgColor="rgba(0, 0, 0, 0.5)"
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            bottom="0px"
          />
        )
      }
      <FileDeleteButton onClick={onDelete} />

      {
        file.status === 'uploading' && (
          <CircularProgress
            p={0}
            size="30px"
            color="primary.500"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            value={file.progress}
          />
        )
      }

      {
        file.status === 'error' && (
          <Tooltip label={file.error}>
            <span style={{ marginTop: '2px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Icon
                p={0}
                fontSize="30px"
                color="red.200"
                as={MdError}
              />
            </span>
          </Tooltip>
        )
      }

    </Box>
  )
}

const OtherFile: React.FC<Props> = ({ file, height, onDelete }) => {
  const [type1, type2] = file.file.type.split('/');
  const type = (type2 || type1).toUpperCase();
  return (
    <HStack
      bgColor={useColorModeValue('gray.50', 'gray.700')}
      p={1}
      pr={2}
      borderRadius={10}
      position="relative"
      flexShrink={0}
    >
      <Box p={2} borderRadius={6} bgColor={useColorModeValue('gray.300', 'gray.800')}>
        <Icon as={MdAttachFile} fontSize="22px"></Icon>
      </Box>
      <VStack align="start" spacing={0} maxWidth="200px">
        <Text whiteSpace="nowrap" textOverflow="ellipsis" maxWidth="200px" isTruncated>{file.file.name}</Text>
        <Text fontSize={12}>{type}</Text>
      </VStack>

      {
        (file.status === 'uploading' || file.status === 'error') && (
          <Box
            borderRadius={10}
            bgColor="rgba(0, 0, 0, 0.5)"
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            bottom="0px"
          />
        )
      }

      <FileDeleteButton onClick={onDelete} />
      {
        file.status === 'uploading' && (
          <CircularProgress
            p={0}
            size="30px"
            color="primary.500"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            value={file.progress}
          />
        )
      }

      {
        file.status === 'error' && (
          <Tooltip label={file.error}>
            <span style={{ marginTop: '2px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Icon
                p={0}
                fontSize="30px"
                color="red.200"
                as={MdError}
              />
            </span>
          </Tooltip>
        )
      }
    </HStack>
  )
}