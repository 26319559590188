import { Box, Progress } from "@chakra-ui/react";
import React, { useEffect, PropsWithChildren, useRef } from "react";
import { MobileAppBarHeight } from "./AppBar";
import { useLocation } from "react-router-dom";


interface AppPageProps {
  p?: number;
  px?: number;
  py?: number;
  maxWidth?: number;
  isLoading?: boolean;
  restoreScroll?: boolean;
}

const AppPage: React.FC<PropsWithChildren<AppPageProps>> = ({ children, p, px, py, maxWidth, isLoading, restoreScroll }) => {

  const location = useLocation();
  const scrollCongtainerRef = React.useRef<HTMLDivElement>(null);
  const scrollTop = useRef<number>(0);

  useEffect(() => {
    if (restoreScroll) {
      const scroll = parseInt(window.sessionStorage.getItem(`scroll-${location.pathname}`) || '0');
      console.log('restoring scroll', scroll);
      if (!isNaN(scroll) && scrollCongtainerRef.current) {
        scrollCongtainerRef.current.scrollTop = scroll;
      }
    }

    return () => {
      if (restoreScroll) {
        window.sessionStorage.setItem(`scroll-${location.pathname}`, scrollTop.current.toString());
      }      
    }

    //eslint-disable-next-line
  }, [scrollCongtainerRef.current]);

  return (
    <>
      {
        isLoading && (
          <Box
            position="absolute"
            top="0px"
            left="0px"
            width="100%"
            pointerEvents="none"
          >
            <Progress size='sm' isIndeterminate colorScheme="primary" />
          </Box>
        )
      }
      <Box
        id="app-page"
        width="100%"
        display="flex"
        justifyContent="center"
        overflowY="scroll"
        ref={scrollCongtainerRef}
        onScroll={(e) => {
          scrollTop.current = scrollCongtainerRef.current?.scrollTop || 0;
        }}
        height={{
          base: `calc(100vh - ${MobileAppBarHeight}px)`,
          lg: '100%',
        }}
        position="relative"
      >

        <Box
        
          p={p}
          px={px}
          py={py}
          height="100%"
          width="100%"
          maxWidth={maxWidth}
          // bgColor="blue"
          id="app-page-vstack"
          // align="start"
          position="relative"
        >
          {children}
          <Box bgColor="transparent" width="100px" height={p}></Box>
        </Box>
      </Box>
    </>
  );
};

export default AppPage;