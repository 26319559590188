import { IconButton, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { MdArrowDownward } from "react-icons/md";
import { useFileUpload } from "./FileUpload";
import { useInputHeight } from "./ChatInput";


interface Props {
  scrollDownRef: React.MutableRefObject<HTMLDivElement | null>,
  visible: boolean
}

const ScrollDownButton: React.FC<Props> = ({ scrollDownRef, visible }) => {
  const fileUpload = useFileUpload();
  const inputHeight = useInputHeight();
  const scrollToBottomBorderColor = useColorModeValue('gray.300', 'gray.600');

  let bottom = 80 + inputHeight.height;
  if (fileUpload.files.length > 0) {
    bottom += fileUpload.renderContainerHeight;
  }



  return (
    <IconButton
      icon={<MdArrowDownward />}
      aria-label="scroll to bottom"
      position="absolute"
      bottom={`${bottom}px`}
      left="50%"
      borderRadius={50}
      boxShadow="md"
      borderWidth="1px"
      bgColor={useColorModeValue('gray.200', 'gray.800')}
      borderColor={scrollToBottomBorderColor}
      transition="opacity 0.2s, transform 0.2s"
      opacity={visible ? 1 : 0}
      transform={visible ? 'translateX(-50%) scale(1)' : 'translateX(-50%) scale(0)'}
      pointerEvents={visible ? 'auto' : 'none'}
      onClick={() => {
        scrollDownRef.current?.scrollIntoView({ behavior: 'smooth' });
      }}
    />
  );
};

export default ScrollDownButton;