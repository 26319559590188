import { Flex, IconButton, Image, VStack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { MdMenu } from "react-icons/md";

export const MobileAppBarHeight = 56;

interface AppBarProps {
  children?: React.ReactNode;
  onMenuOpen: () => void;
}

const AppBar: React.FC<AppBarProps> = ({ children, onMenuOpen }) => {
  var bgColor = useColorModeValue('lightBgColor.50', 'darkBgColor.900');
  return (
    <VStack spacing={0} boxShadow="md" height={`${MobileAppBarHeight}px`}>
      <Flex
        direction="row"
        align="center"
        justify="start"
        p={2}
        width="100%"
        bgColor={bgColor}
      >
        <Image src="/logo512.png" alt="Logo" width="40px" />
        <IconButton
          aria-label='Open Menu'
          icon={<MdMenu />}
          // colorScheme="primary"
          // color="black"
          onClick={onMenuOpen}
          variant="ghost"
          mx={1}
          borderRadius={99}
        />
        {children}
      </Flex>
    </VStack>
  );
};

export default AppBar;