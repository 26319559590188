import { Box, Collapse, HStack, IconButton, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useToggle } from "react-use";


interface Props {
  title: string;
}


const Expandable: React.FC<PropsWithChildren<Props>> = ({ title, children,  }) => {
  const [ isOpen, onToggle ] = useToggle(false);
  const bgColor = useColorModeValue('gray.100', 'gray.700');

  return (

    <VStack
      width="100%"
      bgColor={bgColor}
      borderRadius={10}
      py={3}
      px={4}
      transition="all 0.2s"
      spacing={0}
    >
      <HStack justify="space-between" width="100%" mb={0}>
        <Text fontWeight={600}>{title}</Text>
        <IconButton
          variant="ghost"
          icon={
            <MdKeyboardArrowDown
              fontSize="25px"
              style={{
                transform: `rotate(${isOpen ? '180' : '0'}deg)`,
                transition: 'all 0.2s',
              }}
            />
          }
          aria-label="Expand"
          onClick={onToggle}
        />
      </HStack>
      <Collapse in={isOpen} startingHeight="0px" style={{ width: '100%' }} animateOpacity>
        <Box width="100%" mt={1}>
          {children}
        </Box>
      </Collapse>
    </VStack>

  );
};

export default Expandable;