import React from "react";
import FileManagerProvider from "./FileManager/FileManagerProvider";
import FileManager from "./FileManager/FileManager";
import { Assistant } from "../../gen-ts/ai/assistants/v0/assistant_pb";


interface Props {
  assistant: Assistant;
  onUpdate: (assistant: Assistant) => void;
}

const TabAssistantFiles: React.FC<Props> = ({ assistant, onUpdate }) => {
  return (
    <FileManagerProvider assistant={assistant} key={assistant.id}>
      <FileManager
        assistantId={assistant.id}
        onRetrievalSettingsUpdate={(data) => {
          const update = assistant.clone();
          update.retrievalSettings = data;
          onUpdate(update);
        }}

      />
    </FileManagerProvider>
  );
};

export default TabAssistantFiles;