// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/assistants/v0/assistant.proto (package ai.assistants.v0, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// buf:lint:ignore PACKAGE_VERSION_SUFFIX

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message as Message$1, proto3, Timestamp } from "@bufbuild/protobuf";
import { Model, Model_InputFormats, Model_Tag } from "../../engine_pb";
import { Tool, ToolCall } from "./tools_pb";
import { StringList } from "../../type/list_pb";

/**
 * The direction that records should be sorted.
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.assistants.v0.SortOrder
 */
export enum SortOrder {
  /**
   * @generated from enum value: SORT_ORDER_UNSPECIFIED = 0;
   */
  SORT_ORDER_UNSPECIFIED = 0,

  /**
   * @generated from enum value: ASCENDING = 1;
   */
  ASCENDING = 1,

  /**
   * @generated from enum value: DESCENDING = 2;
   */
  DESCENDING = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SortOrder)
proto3.util.setEnumType(SortOrder, "ai.assistants.v0.SortOrder", [
  { no: 0, name: "SORT_ORDER_UNSPECIFIED" },
  { no: 1, name: "ASCENDING" },
  { no: 2, name: "DESCENDING" },
]);

/**
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.assistants.v0.Role
 */
export enum Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  ROLE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: USER = 1;
   */
  USER = 1,

  /**
   * @generated from enum value: ASSISTANT = 2;
   */
  ASSISTANT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Role)
proto3.util.setEnumType(Role, "ai.assistants.v0.Role", [
  { no: 0, name: "ROLE_UNSPECIFIED" },
  { no: 1, name: "USER" },
  { no: 2, name: "ASSISTANT" },
]);

/**
 * Request for the ListEngines method.
 *
 * @generated from message ai.assistants.v0.ListEnginesRequest
 */
export class ListEnginesRequest extends Message$1<ListEnginesRequest> {
  /**
   * OPTIONAL. The engine to filter for.
   *
   * @generated from field: string engine = 1;
   */
  engine = "";

  /**
   * OPTIONAL. Filter for only recommended models.
   *
   * @generated from field: bool recommended = 2;
   */
  recommended = false;

  /**
   * OPTIONAL. The input formats to filter for.
   *
   * @generated from field: repeated ai.Model.InputFormats input_formats = 3;
   */
  inputFormats: Model_InputFormats[] = [];

  /**
   * OPTIONAL. The tags to filter for.
   *
   * @generated from field: repeated ai.Model.Tag tags = 4;
   */
  tags: Model_Tag[] = [];

  constructor(data?: PartialMessage<ListEnginesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListEnginesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "engine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recommended", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "input_formats", kind: "enum", T: proto3.getEnumType(Model_InputFormats), repeated: true },
    { no: 4, name: "tags", kind: "enum", T: proto3.getEnumType(Model_Tag), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnginesRequest {
    return new ListEnginesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnginesRequest {
    return new ListEnginesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnginesRequest {
    return new ListEnginesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnginesRequest | PlainMessage<ListEnginesRequest> | undefined, b: ListEnginesRequest | PlainMessage<ListEnginesRequest> | undefined): boolean {
    return proto3.util.equals(ListEnginesRequest, a, b);
  }
}

/**
 * Response for the ListEngines method.
 *
 * @generated from message ai.assistants.v0.ListEnginesResponse
 */
export class ListEnginesResponse extends Message$1<ListEnginesResponse> {
  /**
   * The list of engines and their available models
   *
   * @generated from field: repeated ai.assistants.v0.ListEnginesResponse.EngineDescriptor engines = 1;
   */
  engines: ListEnginesResponse_EngineDescriptor[] = [];

  constructor(data?: PartialMessage<ListEnginesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListEnginesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "engines", kind: "message", T: ListEnginesResponse_EngineDescriptor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnginesResponse {
    return new ListEnginesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnginesResponse {
    return new ListEnginesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnginesResponse {
    return new ListEnginesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnginesResponse | PlainMessage<ListEnginesResponse> | undefined, b: ListEnginesResponse | PlainMessage<ListEnginesResponse> | undefined): boolean {
    return proto3.util.equals(ListEnginesResponse, a, b);
  }
}

/**
 * Describes an engine and its available models
 *
 * @generated from message ai.assistants.v0.ListEnginesResponse.EngineDescriptor
 */
export class ListEnginesResponse_EngineDescriptor extends Message$1<ListEnginesResponse_EngineDescriptor> {
  /**
   * The engine backend
   *
   * @generated from field: string engine = 1;
   */
  engine = "";

  /**
   * The list of models available to the engine
   *
   * @generated from field: repeated ai.Model models = 2;
   */
  models: Model[] = [];

  constructor(data?: PartialMessage<ListEnginesResponse_EngineDescriptor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListEnginesResponse.EngineDescriptor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "engine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "models", kind: "message", T: Model, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnginesResponse_EngineDescriptor {
    return new ListEnginesResponse_EngineDescriptor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnginesResponse_EngineDescriptor {
    return new ListEnginesResponse_EngineDescriptor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnginesResponse_EngineDescriptor {
    return new ListEnginesResponse_EngineDescriptor().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnginesResponse_EngineDescriptor | PlainMessage<ListEnginesResponse_EngineDescriptor> | undefined, b: ListEnginesResponse_EngineDescriptor | PlainMessage<ListEnginesResponse_EngineDescriptor> | undefined): boolean {
    return proto3.util.equals(ListEnginesResponse_EngineDescriptor, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.Assistant
 */
export class Assistant extends Message$1<Assistant> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string store_id = 4;
   */
  storeId = "";

  /**
   * @generated from field: string engine = 5;
   */
  engine = "";

  /**
   * @generated from field: string model = 6;
   */
  model = "";

  /**
   * @generated from field: string instructions = 7;
   */
  instructions = "";

  /**
   * @generated from field: ai.assistants.v0.ModelSettings model_settings = 8;
   */
  modelSettings?: ModelSettings;

  /**
   * @generated from field: ai.assistants.v0.RetrievalSettings retrieval_settings = 9;
   */
  retrievalSettings?: RetrievalSettings;

  /**
   * A list of sub-assistants that this assistant will call.
   * Responses from sub-assistants will be combined and used as input to the main assistant.
   *
   * @generated from field: repeated string sub_assistants = 15;
   */
  subAssistants: string[] = [];

  /**
   * Indicates that sub-assistants should be treated as tools rather than than fan-out.
   *
   * @generated from field: bool sub_assistants_as_tools = 16;
   */
  subAssistantsAsTools = false;

  /**
   * A list of tools that the assistant can call
   *
   * @generated from field: repeated ai.assistants.v0.Tool tools = 13;
   */
  tools: Tool[] = [];

  /**
   * JSON schema for the response.
   * Not all models support this feature. This field will be will be ignored if not supported.
   *
   * @generated from field: ai.assistants.v0.JsonSchemaResponseFormat response_json_schema = 19;
   */
  responseJsonSchema?: JsonSchemaResponseFormat;

  /**
   * Indicates that the Assistant is certified by the Applied AI team.
   *
   * @generated from field: bool certified = 20;
   */
  certified = false;

  /**
   * Owner of the assistant
   * Deprecated: See `owners` field.
   *
   * @generated from field: string owner = 11 [deprecated = true];
   * @deprecated
   */
  owner = "";

  /**
   * Owners of the Assistant
   * Owners are granted the ability to edit, delete, and upload to an Assistant.
   * The user that created the Assistant is set as the initial owner if owners are not set at create time.
   *
   * @generated from field: repeated string owners = 17;
   */
  owners: string[] = [];

  /**
   * Users that are allowed to upload to the Assistant's store.
   *
   * @generated from field: repeated string uploaders = 18;
   */
  uploaders: string[] = [];

  /**
   * List of viewers of the assistant
   * Values may be either:
   * - `domain:example.com` to allow all users in the domain to view the store
   * - `user:user@example.com` to allow a specific user to view the store
   *
   * @generated from field: repeated string viewers = 12;
   */
  viewers: string[] = [];

  /**
   * Metadata associated with the assistant
   * This can be used to store additional information about the assistant.
   * Can be set on created but not updated.
   *
   * @generated from field: map<string, string> metadata = 14;
   */
  metadata: { [key: string]: string } = {};

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 10;
   */
  createTime?: Timestamp;

  constructor(data?: PartialMessage<Assistant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Assistant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "engine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "model_settings", kind: "message", T: ModelSettings },
    { no: 9, name: "retrieval_settings", kind: "message", T: RetrievalSettings },
    { no: 15, name: "sub_assistants", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "sub_assistants_as_tools", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "tools", kind: "message", T: Tool, repeated: true },
    { no: 19, name: "response_json_schema", kind: "message", T: JsonSchemaResponseFormat },
    { no: 20, name: "certified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "owners", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "uploaders", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "viewers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 10, name: "create_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Assistant {
    return new Assistant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Assistant {
    return new Assistant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Assistant {
    return new Assistant().fromJsonString(jsonString, options);
  }

  static equals(a: Assistant | PlainMessage<Assistant> | undefined, b: Assistant | PlainMessage<Assistant> | undefined): boolean {
    return proto3.util.equals(Assistant, a, b);
  }
}

/**
 * A schema specification for a JSON response.
 *
 * @generated from message ai.assistants.v0.JsonSchemaResponseFormat
 */
export class JsonSchemaResponseFormat extends Message$1<JsonSchemaResponseFormat> {
  /**
   * REQUIRED. The JSON schema to use for the response.
   *
   * @generated from field: string schema = 2;
   */
  schema = "";

  /**
   * OPTIONAL. The description of the schema.
   * This may be used by the model to determine how to respond in the specified format.
   *
   * @generated from field: string description = 1;
   */
  description = "";

  constructor(data?: PartialMessage<JsonSchemaResponseFormat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.JsonSchemaResponseFormat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "schema", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JsonSchemaResponseFormat {
    return new JsonSchemaResponseFormat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JsonSchemaResponseFormat {
    return new JsonSchemaResponseFormat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JsonSchemaResponseFormat {
    return new JsonSchemaResponseFormat().fromJsonString(jsonString, options);
  }

  static equals(a: JsonSchemaResponseFormat | PlainMessage<JsonSchemaResponseFormat> | undefined, b: JsonSchemaResponseFormat | PlainMessage<JsonSchemaResponseFormat> | undefined): boolean {
    return proto3.util.equals(JsonSchemaResponseFormat, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.CreateAssistantRequest
 */
export class CreateAssistantRequest extends Message$1<CreateAssistantRequest> {
  /**
   * @generated from field: string display_name = 1;
   */
  displayName = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string store_id = 3;
   */
  storeId = "";

  /**
   * @generated from field: string engine = 4;
   */
  engine = "";

  /**
   * @generated from field: string model = 5;
   */
  model = "";

  /**
   * @generated from field: string instructions = 6;
   */
  instructions = "";

  /**
   * @generated from field: ai.assistants.v0.ModelSettings model_settings = 7;
   */
  modelSettings?: ModelSettings;

  /**
   * @generated from field: ai.assistants.v0.RetrievalSettings retrieval_settings = 8;
   */
  retrievalSettings?: RetrievalSettings;

  /**
   * A list of sub-assistants that this assistant may call.
   *
   * @generated from field: repeated string sub_assistants = 12;
   */
  subAssistants: string[] = [];

  /**
   * Indicates that sub-assistants should be treated as tools rather than than fan-out.
   *
   * @generated from field: bool sub_assistants_as_tools = 13;
   */
  subAssistantsAsTools = false;

  /**
   * A list of tools that the assistant can call
   *
   * @generated from field: repeated ai.assistants.v0.Tool tools = 10;
   */
  tools: Tool[] = [];

  /**
   * OPTIONAL. JSON schema for the response.
   * Not all models support this feature. This field will be will be ignored if not supported.
   *
   * @generated from field: ai.assistants.v0.JsonSchemaResponseFormat response_json_schema = 16;
   */
  responseJsonSchema?: JsonSchemaResponseFormat;

  /**
   * Owners of the Assistant
   * Owners are granted the ability to edit, delete, and upload to an Assistant.
   * The user that created the Assistant is set as the initial owner if owners are not set at create time.
   *
   * @generated from field: repeated string owners = 14;
   */
  owners: string[] = [];

  /**
   * Users that are allowed to upload to the Assistant's store.
   *
   * @generated from field: repeated string uploaders = 15;
   */
  uploaders: string[] = [];

  /**
   * List of viewers of the assistant
   * Values may be either:
   * - `domain:example.com` to allow all users in the domain to view the store
   * - `user:user@example.com` to allow a specific user to view the store
   *
   * @generated from field: repeated string viewers = 9;
   */
  viewers: string[] = [];

  /**
   * Metadata associated with the assistant
   * This can be used to store additional information about the assistant.
   * Can be set on created but not updated.
   *
   * @generated from field: map<string, string> metadata = 11;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CreateAssistantRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.CreateAssistantRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "engine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "model_settings", kind: "message", T: ModelSettings },
    { no: 8, name: "retrieval_settings", kind: "message", T: RetrievalSettings },
    { no: 12, name: "sub_assistants", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "sub_assistants_as_tools", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "tools", kind: "message", T: Tool, repeated: true },
    { no: 16, name: "response_json_schema", kind: "message", T: JsonSchemaResponseFormat },
    { no: 14, name: "owners", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "uploaders", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "viewers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAssistantRequest {
    return new CreateAssistantRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAssistantRequest {
    return new CreateAssistantRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAssistantRequest {
    return new CreateAssistantRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAssistantRequest | PlainMessage<CreateAssistantRequest> | undefined, b: CreateAssistantRequest | PlainMessage<CreateAssistantRequest> | undefined): boolean {
    return proto3.util.equals(CreateAssistantRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.GetAssistantRequest
 */
export class GetAssistantRequest extends Message$1<GetAssistantRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetAssistantRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.GetAssistantRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssistantRequest {
    return new GetAssistantRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssistantRequest {
    return new GetAssistantRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssistantRequest {
    return new GetAssistantRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssistantRequest | PlainMessage<GetAssistantRequest> | undefined, b: GetAssistantRequest | PlainMessage<GetAssistantRequest> | undefined): boolean {
    return proto3.util.equals(GetAssistantRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.ListAssistantsRequest
 */
export class ListAssistantsRequest extends Message$1<ListAssistantsRequest> {
  /**
   * OPTIONAL. Maximum number of assistants to return in a single page.
   * If unspecified, at most 50 will be returned. The maximum value is 100; values over
   * 100 will be coerced to 100.
   *
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * OPTIONAL. Token of the previous call to ListAssistants used to retrieve the next page.
   * All other fields must match the previous call.
   *
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  /**
   * OPTIONAL. Store ID to filter for.
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * OPTIONAL. Metadata to filter for. Metadata keys and values are case-sensitive.
   * Metadata values are AND-ed together, so only records that have all the specified metadata will be returned.
   *
   * @generated from field: map<string, string> metadata = 4;
   */
  metadata: { [key: string]: string } = {};

  /**
   * OPTIONAL. Filter for only certified Assistants.
   * Leaving this value empty or setting it to false will return all Assistants.
   *
   * @generated from field: bool certified = 5;
   */
  certified = false;

  constructor(data?: PartialMessage<ListAssistantsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListAssistantsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 5, name: "certified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssistantsRequest {
    return new ListAssistantsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssistantsRequest {
    return new ListAssistantsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssistantsRequest {
    return new ListAssistantsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssistantsRequest | PlainMessage<ListAssistantsRequest> | undefined, b: ListAssistantsRequest | PlainMessage<ListAssistantsRequest> | undefined): boolean {
    return proto3.util.equals(ListAssistantsRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.ListAssistantsResponse
 */
export class ListAssistantsResponse extends Message$1<ListAssistantsResponse> {
  /**
   * @generated from field: repeated ai.assistants.v0.Assistant assistants = 1;
   */
  assistants: Assistant[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListAssistantsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListAssistantsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assistants", kind: "message", T: Assistant, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssistantsResponse {
    return new ListAssistantsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssistantsResponse {
    return new ListAssistantsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssistantsResponse {
    return new ListAssistantsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssistantsResponse | PlainMessage<ListAssistantsResponse> | undefined, b: ListAssistantsResponse | PlainMessage<ListAssistantsResponse> | undefined): boolean {
    return proto3.util.equals(ListAssistantsResponse, a, b);
  }
}

/**
 * A request to update an assistant.
 * Only fields that are set to non-zero values, or non-null in the case of `optional` fields, will be updated.
 *
 * @generated from message ai.assistants.v0.UpdateAssistantRequest
 */
export class UpdateAssistantRequest extends Message$1<UpdateAssistantRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The display name of the assistant
   *
   * @generated from field: string display_name = 10;
   */
  displayName = "";

  /**
   * @generated from field: string description = 8;
   */
  description = "";

  /**
   * @generated from field: string store_id = 2;
   */
  storeId = "";

  /**
   * @generated from field: string engine = 3;
   */
  engine = "";

  /**
   * @generated from field: string model = 4;
   */
  model = "";

  /**
   * @generated from field: string instructions = 5;
   */
  instructions = "";

  /**
   * @generated from field: ai.assistants.v0.ModelSettings model_settings = 6;
   */
  modelSettings?: ModelSettings;

  /**
   * @generated from field: ai.assistants.v0.RetrievalSettings retrieval_settings = 7;
   */
  retrievalSettings?: RetrievalSettings;

  /**
   * OPTIONAL. A list of sub-assistants that this assistant may call.
   *
   * @generated from field: ai.type.StringList sub_assistants = 14;
   */
  subAssistants?: StringList;

  /**
   * OPTIONAL. Indicates that sub-assistants should be treated as tools rather than than fan-out.
   *
   * @generated from field: optional bool sub_assistants_as_tools = 15;
   */
  subAssistantsAsTools?: boolean;

  /**
   * Metadata associated with the Assistant.
   *
   * @generated from field: ai.assistants.v0.UpdateAssistantRequest.Metadata metadata = 13;
   */
  metadata?: UpdateAssistantRequest_Metadata;

  /**
   * List of Owners to update on the Assistant
   *
   * @generated from field: ai.assistants.v0.UpdateAssistantRequest.OwnerList owners = 11;
   */
  owners?: UpdateAssistantRequest_OwnerList;

  /**
   * List of uploaders to update on the Assistant.
   *
   * @generated from field: ai.assistants.v0.UpdateAssistantRequest.UploaderList uploaders = 12;
   */
  uploaders?: UpdateAssistantRequest_UploaderList;

  /**
   * List of viewers of the assistant
   *
   * @generated from field: ai.assistants.v0.UpdateAssistantRequest.ViewerList viewers = 9;
   */
  viewers?: UpdateAssistantRequest_ViewerList;

  constructor(data?: PartialMessage<UpdateAssistantRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.UpdateAssistantRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "engine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "model_settings", kind: "message", T: ModelSettings },
    { no: 7, name: "retrieval_settings", kind: "message", T: RetrievalSettings },
    { no: 14, name: "sub_assistants", kind: "message", T: StringList },
    { no: 15, name: "sub_assistants_as_tools", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 13, name: "metadata", kind: "message", T: UpdateAssistantRequest_Metadata },
    { no: 11, name: "owners", kind: "message", T: UpdateAssistantRequest_OwnerList },
    { no: 12, name: "uploaders", kind: "message", T: UpdateAssistantRequest_UploaderList },
    { no: 9, name: "viewers", kind: "message", T: UpdateAssistantRequest_ViewerList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssistantRequest {
    return new UpdateAssistantRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssistantRequest {
    return new UpdateAssistantRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssistantRequest {
    return new UpdateAssistantRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssistantRequest | PlainMessage<UpdateAssistantRequest> | undefined, b: UpdateAssistantRequest | PlainMessage<UpdateAssistantRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAssistantRequest, a, b);
  }
}

/**
 * A list of owners. This is a wrapper type to allow for partial updates.
 * Values must be a list of users in the form `user:user@example.com`.
 *
 * @generated from message ai.assistants.v0.UpdateAssistantRequest.OwnerList
 */
export class UpdateAssistantRequest_OwnerList extends Message$1<UpdateAssistantRequest_OwnerList> {
  /**
   * Values of the list
   *
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<UpdateAssistantRequest_OwnerList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.UpdateAssistantRequest.OwnerList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssistantRequest_OwnerList {
    return new UpdateAssistantRequest_OwnerList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssistantRequest_OwnerList {
    return new UpdateAssistantRequest_OwnerList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssistantRequest_OwnerList {
    return new UpdateAssistantRequest_OwnerList().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssistantRequest_OwnerList | PlainMessage<UpdateAssistantRequest_OwnerList> | undefined, b: UpdateAssistantRequest_OwnerList | PlainMessage<UpdateAssistantRequest_OwnerList> | undefined): boolean {
    return proto3.util.equals(UpdateAssistantRequest_OwnerList, a, b);
  }
}

/**
 * A list of Uploaders. This is a wrapper type to allow for partial updates.
 * Values may be either:
 * - `domain:example.com` to allow all users in the domain to upload to the store
 * - `user:user@example.com` to allow a specific user to upload to the store
 *
 * @generated from message ai.assistants.v0.UpdateAssistantRequest.UploaderList
 */
export class UpdateAssistantRequest_UploaderList extends Message$1<UpdateAssistantRequest_UploaderList> {
  /**
   * Values of the list
   *
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<UpdateAssistantRequest_UploaderList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.UpdateAssistantRequest.UploaderList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssistantRequest_UploaderList {
    return new UpdateAssistantRequest_UploaderList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssistantRequest_UploaderList {
    return new UpdateAssistantRequest_UploaderList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssistantRequest_UploaderList {
    return new UpdateAssistantRequest_UploaderList().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssistantRequest_UploaderList | PlainMessage<UpdateAssistantRequest_UploaderList> | undefined, b: UpdateAssistantRequest_UploaderList | PlainMessage<UpdateAssistantRequest_UploaderList> | undefined): boolean {
    return proto3.util.equals(UpdateAssistantRequest_UploaderList, a, b);
  }
}

/**
 * A list of Viewers. This is a wrapper type to allow for partial updates.
 * Values may be either:
 * - `domain:example.com` to allow all users in the domain to view the store
 * - `user:user@example.com` to allow a specific user to view the store
 *
 * @generated from message ai.assistants.v0.UpdateAssistantRequest.ViewerList
 */
export class UpdateAssistantRequest_ViewerList extends Message$1<UpdateAssistantRequest_ViewerList> {
  /**
   * Values of the list
   *
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<UpdateAssistantRequest_ViewerList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.UpdateAssistantRequest.ViewerList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssistantRequest_ViewerList {
    return new UpdateAssistantRequest_ViewerList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssistantRequest_ViewerList {
    return new UpdateAssistantRequest_ViewerList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssistantRequest_ViewerList {
    return new UpdateAssistantRequest_ViewerList().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssistantRequest_ViewerList | PlainMessage<UpdateAssistantRequest_ViewerList> | undefined, b: UpdateAssistantRequest_ViewerList | PlainMessage<UpdateAssistantRequest_ViewerList> | undefined): boolean {
    return proto3.util.equals(UpdateAssistantRequest_ViewerList, a, b);
  }
}

/**
 * A set of metadata. This is a wrapper type to allow for partial updates.
 *
 * @generated from message ai.assistants.v0.UpdateAssistantRequest.Metadata
 */
export class UpdateAssistantRequest_Metadata extends Message$1<UpdateAssistantRequest_Metadata> {
  /**
   * Metadata values
   *
   * @generated from field: map<string, string> values = 1;
   */
  values: { [key: string]: string } = {};

  constructor(data?: PartialMessage<UpdateAssistantRequest_Metadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.UpdateAssistantRequest.Metadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssistantRequest_Metadata {
    return new UpdateAssistantRequest_Metadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssistantRequest_Metadata {
    return new UpdateAssistantRequest_Metadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssistantRequest_Metadata {
    return new UpdateAssistantRequest_Metadata().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssistantRequest_Metadata | PlainMessage<UpdateAssistantRequest_Metadata> | undefined, b: UpdateAssistantRequest_Metadata | PlainMessage<UpdateAssistantRequest_Metadata> | undefined): boolean {
    return proto3.util.equals(UpdateAssistantRequest_Metadata, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.DeleteAssistantRequest
 */
export class DeleteAssistantRequest extends Message$1<DeleteAssistantRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteAssistantRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.DeleteAssistantRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAssistantRequest {
    return new DeleteAssistantRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAssistantRequest {
    return new DeleteAssistantRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAssistantRequest {
    return new DeleteAssistantRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAssistantRequest | PlainMessage<DeleteAssistantRequest> | undefined, b: DeleteAssistantRequest | PlainMessage<DeleteAssistantRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAssistantRequest, a, b);
  }
}

/**
 * Request message for `TestAssistantPermissions` method.
 *
 * @generated from message ai.assistants.v0.TestAssistantPermissionsRequest
 */
export class TestAssistantPermissionsRequest extends Message$1<TestAssistantPermissionsRequest> {
  /**
   * REQUIRED: The ID of the Assistant to test permissions for.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * REQUIRED: The set of permissions to test.
   * Currently supported permissions are:
   * - `assistant.edit`: Allows the user to edit the properties of the Assistant.
   * - `assistant.delete`: Allows the user to delete the Assistant.
   * - `assistant.upload`: Allows the user to upload files to the Assistant.
   * - `assistant.view`: Allows the user to view the Assistant.
   *
   * @generated from field: repeated string permissions = 2;
   */
  permissions: string[] = [];

  constructor(data?: PartialMessage<TestAssistantPermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.TestAssistantPermissionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "permissions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestAssistantPermissionsRequest {
    return new TestAssistantPermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestAssistantPermissionsRequest {
    return new TestAssistantPermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestAssistantPermissionsRequest {
    return new TestAssistantPermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TestAssistantPermissionsRequest | PlainMessage<TestAssistantPermissionsRequest> | undefined, b: TestAssistantPermissionsRequest | PlainMessage<TestAssistantPermissionsRequest> | undefined): boolean {
    return proto3.util.equals(TestAssistantPermissionsRequest, a, b);
  }
}

/**
 * Response message for `TestAssistantPermissions` method.
 *
 * @generated from message ai.assistants.v0.TestAssistantPermissionsResponse
 */
export class TestAssistantPermissionsResponse extends Message$1<TestAssistantPermissionsResponse> {
  /**
   * The set of permissions that the user has on the Assistant.
   *
   * @generated from field: repeated string permissions = 1;
   */
  permissions: string[] = [];

  constructor(data?: PartialMessage<TestAssistantPermissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.TestAssistantPermissionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "permissions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestAssistantPermissionsResponse {
    return new TestAssistantPermissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestAssistantPermissionsResponse {
    return new TestAssistantPermissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestAssistantPermissionsResponse {
    return new TestAssistantPermissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TestAssistantPermissionsResponse | PlainMessage<TestAssistantPermissionsResponse> | undefined, b: TestAssistantPermissionsResponse | PlainMessage<TestAssistantPermissionsResponse> | undefined): boolean {
    return proto3.util.equals(TestAssistantPermissionsResponse, a, b);
  }
}

/**
 * A thread of messages
 *
 * @generated from message ai.assistants.v0.Thread
 */
export class Thread extends Message$1<Thread> {
  /**
   * Unique identifier for the Thread.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * A description of the Thread.
   *
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * Metadata associated with the Thread.
   *
   * @generated from field: map<string, string> metadata = 2;
   */
  metadata: { [key: string]: string } = {};

  /**
   * Time the thread was created.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 3;
   */
  createTime?: Timestamp;

  constructor(data?: PartialMessage<Thread>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Thread";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Thread {
    return new Thread().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Thread {
    return new Thread().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Thread {
    return new Thread().fromJsonString(jsonString, options);
  }

  static equals(a: Thread | PlainMessage<Thread> | undefined, b: Thread | PlainMessage<Thread> | undefined): boolean {
    return proto3.util.equals(Thread, a, b);
  }
}

/**
 * Request message for creating a Thread.
 *
 * @generated from message ai.assistants.v0.CreateThreadRequest
 */
export class CreateThreadRequest extends Message$1<CreateThreadRequest> {
  /**
   * Optional. A description of the Thread.
   * If no description is provided, the first 60 characters of the first message will be used.
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * Optional. Messages to add to the Thread on creation.
   *
   * @generated from field: repeated ai.assistants.v0.CreateMessageRequest messages = 1;
   */
  messages: CreateMessageRequest[] = [];

  /**
   * Optional. Metadata associated with the Thread. This can be used to store additional
   * information about the Thread. Can be set on create but not updated.
   *
   * @generated from field: map<string, string> metadata = 2;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CreateThreadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.CreateThreadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "messages", kind: "message", T: CreateMessageRequest, repeated: true },
    { no: 2, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateThreadRequest {
    return new CreateThreadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateThreadRequest {
    return new CreateThreadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateThreadRequest {
    return new CreateThreadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateThreadRequest | PlainMessage<CreateThreadRequest> | undefined, b: CreateThreadRequest | PlainMessage<CreateThreadRequest> | undefined): boolean {
    return proto3.util.equals(CreateThreadRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.GetThreadRequest
 */
export class GetThreadRequest extends Message$1<GetThreadRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetThreadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.GetThreadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetThreadRequest {
    return new GetThreadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetThreadRequest {
    return new GetThreadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetThreadRequest {
    return new GetThreadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetThreadRequest | PlainMessage<GetThreadRequest> | undefined, b: GetThreadRequest | PlainMessage<GetThreadRequest> | undefined): boolean {
    return proto3.util.equals(GetThreadRequest, a, b);
  }
}

/**
 * Request message for the `ListThreads` method.
 *
 * @generated from message ai.assistants.v0.ListThreadsRequest
 */
export class ListThreadsRequest extends Message$1<ListThreadsRequest> {
  /**
   * OPTIONAL. Maximum number of assistants to return in a single page.
   * If unspecified, at most 50 will be returned. The maximum value is 100; values over
   * 100 will be coerced to 100.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize = 0;

  /**
   * OPTIONAL. Token of the previous call to ListThreads used to retrieve the next page.
   * All other fields must match the previous call.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  /**
   * OPTIONAL. Metadata to filter for. Metadata keys and values are case-sensitive.
   * Metadata values are AND-ed together, so only records that have all the specified metadata will be returned.
   *
   * @generated from field: map<string, string> metadata = 3;
   */
  metadata: { [key: string]: string } = {};

  /**
   * OPTIONAL. The direction to sort the returned records.
   * If unspecified, records will be returned in ascending order.
   * Records are sorted by the `create_time` field.
   *
   * @generated from field: ai.assistants.v0.SortOrder order = 4;
   */
  order = SortOrder.SORT_ORDER_UNSPECIFIED;

  constructor(data?: PartialMessage<ListThreadsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListThreadsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 4, name: "order", kind: "enum", T: proto3.getEnumType(SortOrder) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListThreadsRequest {
    return new ListThreadsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListThreadsRequest {
    return new ListThreadsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListThreadsRequest {
    return new ListThreadsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListThreadsRequest | PlainMessage<ListThreadsRequest> | undefined, b: ListThreadsRequest | PlainMessage<ListThreadsRequest> | undefined): boolean {
    return proto3.util.equals(ListThreadsRequest, a, b);
  }
}

/**
 * Response message for the `ListThreads` method.
 *
 * @generated from message ai.assistants.v0.ListThreadsResponse
 */
export class ListThreadsResponse extends Message$1<ListThreadsResponse> {
  /**
   * @generated from field: repeated ai.assistants.v0.Thread threads = 1;
   */
  threads: Thread[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListThreadsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListThreadsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "threads", kind: "message", T: Thread, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListThreadsResponse {
    return new ListThreadsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListThreadsResponse {
    return new ListThreadsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListThreadsResponse {
    return new ListThreadsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListThreadsResponse | PlainMessage<ListThreadsResponse> | undefined, b: ListThreadsResponse | PlainMessage<ListThreadsResponse> | undefined): boolean {
    return proto3.util.equals(ListThreadsResponse, a, b);
  }
}

/**
 * Request for the `UpdateThread` method.
 *
 * @generated from message ai.assistants.v0.UpdateThreadRequest
 */
export class UpdateThreadRequest extends Message$1<UpdateThreadRequest> {
  /**
   * REQUIRED. The ID of the Thread to update.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * OPTIONAL. A description of the Thread.
   *
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * OPTIONAL. Metadata associated with the Thread.
   *
   * @generated from field: ai.assistants.v0.UpdateThreadRequest.Metadata metadata = 3;
   */
  metadata?: UpdateThreadRequest_Metadata;

  constructor(data?: PartialMessage<UpdateThreadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.UpdateThreadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metadata", kind: "message", T: UpdateThreadRequest_Metadata },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateThreadRequest {
    return new UpdateThreadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateThreadRequest {
    return new UpdateThreadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateThreadRequest {
    return new UpdateThreadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateThreadRequest | PlainMessage<UpdateThreadRequest> | undefined, b: UpdateThreadRequest | PlainMessage<UpdateThreadRequest> | undefined): boolean {
    return proto3.util.equals(UpdateThreadRequest, a, b);
  }
}

/**
 * A set of metadata. This is a wrapper type to allow for partial updates.
 *
 * @generated from message ai.assistants.v0.UpdateThreadRequest.Metadata
 */
export class UpdateThreadRequest_Metadata extends Message$1<UpdateThreadRequest_Metadata> {
  /**
   * Metadata values
   *
   * @generated from field: map<string, string> values = 1;
   */
  values: { [key: string]: string } = {};

  constructor(data?: PartialMessage<UpdateThreadRequest_Metadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.UpdateThreadRequest.Metadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateThreadRequest_Metadata {
    return new UpdateThreadRequest_Metadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateThreadRequest_Metadata {
    return new UpdateThreadRequest_Metadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateThreadRequest_Metadata {
    return new UpdateThreadRequest_Metadata().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateThreadRequest_Metadata | PlainMessage<UpdateThreadRequest_Metadata> | undefined, b: UpdateThreadRequest_Metadata | PlainMessage<UpdateThreadRequest_Metadata> | undefined): boolean {
    return proto3.util.equals(UpdateThreadRequest_Metadata, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.DeleteThreadRequest
 */
export class DeleteThreadRequest extends Message$1<DeleteThreadRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteThreadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.DeleteThreadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteThreadRequest {
    return new DeleteThreadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteThreadRequest {
    return new DeleteThreadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteThreadRequest {
    return new DeleteThreadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteThreadRequest | PlainMessage<DeleteThreadRequest> | undefined, b: DeleteThreadRequest | PlainMessage<DeleteThreadRequest> | undefined): boolean {
    return proto3.util.equals(DeleteThreadRequest, a, b);
  }
}

/**
 * Request message for the `ShareThread` method.
 *
 * @generated from message ai.assistants.v0.ShareThreadRequest
 */
export class ShareThreadRequest extends Message$1<ShareThreadRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<ShareThreadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ShareThreadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShareThreadRequest {
    return new ShareThreadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShareThreadRequest {
    return new ShareThreadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShareThreadRequest {
    return new ShareThreadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ShareThreadRequest | PlainMessage<ShareThreadRequest> | undefined, b: ShareThreadRequest | PlainMessage<ShareThreadRequest> | undefined): boolean {
    return proto3.util.equals(ShareThreadRequest, a, b);
  }
}

/**
 * A file attachment
 *
 * @generated from message ai.assistants.v0.File
 */
export class File extends Message$1<File> {
  /**
   * The source of the file
   *
   * @generated from oneof ai.assistants.v0.File.source
   */
  source: {
    /**
     * Self ref of a file uploaded to the file-server
     *
     * @generated from field: string ref = 1;
     */
    value: string;
    case: "ref";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<File>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.File";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "source" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): File {
    return new File().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): File {
    return new File().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): File {
    return new File().fromJsonString(jsonString, options);
  }

  static equals(a: File | PlainMessage<File> | undefined, b: File | PlainMessage<File> | undefined): boolean {
    return proto3.util.equals(File, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.Image
 */
export class Image extends Message$1<Image> {
  /**
   * @generated from oneof ai.assistants.v0.Image.source
   */
  source: {
    /**
     * @generated from field: string url = 1;
     */
    value: string;
    case: "url";
  } | {
    /**
     * @generated from field: ai.assistants.v0.Image.Data data = 2;
     */
    value: Image_Data;
    case: "data";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Image>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Image";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "source" },
    { no: 2, name: "data", kind: "message", T: Image_Data, oneof: "source" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Image {
    return new Image().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Image {
    return new Image().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Image {
    return new Image().fromJsonString(jsonString, options);
  }

  static equals(a: Image | PlainMessage<Image> | undefined, b: Image | PlainMessage<Image> | undefined): boolean {
    return proto3.util.equals(Image, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.Image.Data
 */
export class Image_Data extends Message$1<Image_Data> {
  /**
   * @generated from field: string media_type = 1;
   */
  mediaType = "";

  /**
   * @generated from field: bytes data = 2;
   */
  data = new Uint8Array(0);

  constructor(data?: PartialMessage<Image_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Image.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Image_Data {
    return new Image_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Image_Data {
    return new Image_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Image_Data {
    return new Image_Data().fromJsonString(jsonString, options);
  }

  static equals(a: Image_Data | PlainMessage<Image_Data> | undefined, b: Image_Data | PlainMessage<Image_Data> | undefined): boolean {
    return proto3.util.equals(Image_Data, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.MessageContent
 */
export class MessageContent extends Message$1<MessageContent> {
  /**
   * @generated from oneof ai.assistants.v0.MessageContent.content
   */
  content: {
    /**
     * @generated from field: string text = 1;
     */
    value: string;
    case: "text";
  } | {
    /**
     * @generated from field: ai.assistants.v0.Image image = 2;
     */
    value: Image;
    case: "image";
  } | {
    /**
     * @generated from field: ai.assistants.v0.File file = 3;
     */
    value: File;
    case: "file";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<MessageContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.MessageContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "content" },
    { no: 2, name: "image", kind: "message", T: Image, oneof: "content" },
    { no: 3, name: "file", kind: "message", T: File, oneof: "content" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MessageContent {
    return new MessageContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MessageContent {
    return new MessageContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MessageContent {
    return new MessageContent().fromJsonString(jsonString, options);
  }

  static equals(a: MessageContent | PlainMessage<MessageContent> | undefined, b: MessageContent | PlainMessage<MessageContent> | undefined): boolean {
    return proto3.util.equals(MessageContent, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.Message
 */
export class Message extends Message$1<Message> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: ai.assistants.v0.Role role = 2;
   */
  role = Role.ROLE_UNSPECIFIED;

  /**
   * @generated from field: repeated ai.assistants.v0.MessageContent content = 3;
   */
  content: MessageContent[] = [];

  /**
   * @generated from field: map<string, string> metadata = 4;
   */
  metadata: { [key: string]: string } = {};

  /**
   * State of the message.
   * Deprecated: Will always be set as COMPLETED.
   *
   * @generated from field: ai.assistants.v0.Message.State state = 5 [deprecated = true];
   * @deprecated
   */
  state = Message_State.STATE_UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 6;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp modify_time = 7;
   */
  modifyTime?: Timestamp;

  /**
   * @generated from field: string run_id = 8;
   */
  runId = "";

  constructor(data?: PartialMessage<Message>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Message";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(Role) },
    { no: 3, name: "content", kind: "message", T: MessageContent, repeated: true },
    { no: 4, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(Message_State) },
    { no: 6, name: "create_time", kind: "message", T: Timestamp },
    { no: 7, name: "modify_time", kind: "message", T: Timestamp },
    { no: 8, name: "run_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Message {
    return new Message().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Message {
    return new Message().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Message {
    return new Message().fromJsonString(jsonString, options);
  }

  static equals(a: Message | PlainMessage<Message> | undefined, b: Message | PlainMessage<Message> | undefined): boolean {
    return proto3.util.equals(Message, a, b);
  }
}

/**
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.assistants.v0.Message.State
 */
export enum Message_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: GENERATING = 2;
   */
  GENERATING = 2,

  /**
   * @generated from enum value: COMPLETED = 3;
   */
  COMPLETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Message_State)
proto3.util.setEnumType(Message_State, "ai.assistants.v0.Message.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "PENDING" },
  { no: 2, name: "GENERATING" },
  { no: 3, name: "COMPLETED" },
]);

/**
 * @generated from message ai.assistants.v0.ModelSettings
 */
export class ModelSettings extends Message$1<ModelSettings> {
  /**
   * @generated from field: optional uint32 max_tokens = 1;
   */
  maxTokens?: number;

  /**
   * @generated from field: optional float temperature = 2;
   */
  temperature?: number;

  constructor(data?: PartialMessage<ModelSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ModelSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "max_tokens", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
    { no: 2, name: "temperature", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModelSettings {
    return new ModelSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModelSettings {
    return new ModelSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModelSettings {
    return new ModelSettings().fromJsonString(jsonString, options);
  }

  static equals(a: ModelSettings | PlainMessage<ModelSettings> | undefined, b: ModelSettings | PlainMessage<ModelSettings> | undefined): boolean {
    return proto3.util.equals(ModelSettings, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.RetrievalSettings
 */
export class RetrievalSettings extends Message$1<RetrievalSettings> {
  /**
   * @generated from field: optional int32 top_k = 1;
   */
  topK?: number;

  /**
   * @generated from field: optional float min_relevance_score = 4;
   */
  minRelevanceScore?: number;

  constructor(data?: PartialMessage<RetrievalSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.RetrievalSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "top_k", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "min_relevance_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrievalSettings {
    return new RetrievalSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrievalSettings {
    return new RetrievalSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrievalSettings {
    return new RetrievalSettings().fromJsonString(jsonString, options);
  }

  static equals(a: RetrievalSettings | PlainMessage<RetrievalSettings> | undefined, b: RetrievalSettings | PlainMessage<RetrievalSettings> | undefined): boolean {
    return proto3.util.equals(RetrievalSettings, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.CreateMessageRequest
 */
export class CreateMessageRequest extends Message$1<CreateMessageRequest> {
  /**
   * @generated from field: string thread_id = 1;
   */
  threadId = "";

  /**
   * @generated from field: ai.assistants.v0.Role role = 2;
   */
  role = Role.ROLE_UNSPECIFIED;

  /**
   * @generated from field: string content = 3;
   */
  content = "";

  /**
   * @generated from field: repeated ai.assistants.v0.CreateMessageRequest.Attachment attachments = 4;
   */
  attachments: CreateMessageRequest_Attachment[] = [];

  /**
   * Optional. Metadata associated with the Message. This can be used to store additional
   * information about the Message. Can be set on create but not updated.
   *
   * @generated from field: map<string, string> metadata = 5;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CreateMessageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.CreateMessageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(Role) },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "attachments", kind: "message", T: CreateMessageRequest_Attachment, repeated: true },
    { no: 5, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMessageRequest {
    return new CreateMessageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMessageRequest {
    return new CreateMessageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMessageRequest {
    return new CreateMessageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMessageRequest | PlainMessage<CreateMessageRequest> | undefined, b: CreateMessageRequest | PlainMessage<CreateMessageRequest> | undefined): boolean {
    return proto3.util.equals(CreateMessageRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.CreateMessageRequest.Attachment
 */
export class CreateMessageRequest_Attachment extends Message$1<CreateMessageRequest_Attachment> {
  /**
   * @generated from oneof ai.assistants.v0.CreateMessageRequest.Attachment.content
   */
  content: {
    /**
     * @generated from field: ai.assistants.v0.Image image = 1;
     */
    value: Image;
    case: "image";
  } | {
    /**
     * @generated from field: ai.assistants.v0.File file = 2;
     */
    value: File;
    case: "file";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateMessageRequest_Attachment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.CreateMessageRequest.Attachment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image", kind: "message", T: Image, oneof: "content" },
    { no: 2, name: "file", kind: "message", T: File, oneof: "content" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMessageRequest_Attachment {
    return new CreateMessageRequest_Attachment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMessageRequest_Attachment {
    return new CreateMessageRequest_Attachment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMessageRequest_Attachment {
    return new CreateMessageRequest_Attachment().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMessageRequest_Attachment | PlainMessage<CreateMessageRequest_Attachment> | undefined, b: CreateMessageRequest_Attachment | PlainMessage<CreateMessageRequest_Attachment> | undefined): boolean {
    return proto3.util.equals(CreateMessageRequest_Attachment, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.GetMessageRequest
 */
export class GetMessageRequest extends Message$1<GetMessageRequest> {
  /**
   * @generated from field: string thread_id = 1;
   */
  threadId = "";

  /**
   * @generated from field: string message_id = 2;
   */
  messageId = "";

  constructor(data?: PartialMessage<GetMessageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.GetMessageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "message_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMessageRequest {
    return new GetMessageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMessageRequest {
    return new GetMessageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMessageRequest {
    return new GetMessageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMessageRequest | PlainMessage<GetMessageRequest> | undefined, b: GetMessageRequest | PlainMessage<GetMessageRequest> | undefined): boolean {
    return proto3.util.equals(GetMessageRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.ListMessagesRequest
 */
export class ListMessagesRequest extends Message$1<ListMessagesRequest> {
  /**
   * REQUIRED. The thread to list messages for.
   *
   * @generated from field: string thread_id = 1;
   */
  threadId = "";

  /**
   * OPTIONAL. Maximum number of assistants to return in a single page.
   * If unspecified, at most 50 will be returned. The maximum value is 100; values over
   * 1000 will be coerced to 1000.
   *
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * OPTIONAL. Token of the previous call to ListMessages used to retrieve the next page.
   * All other fields must match the previous call.
   *
   * @generated from field: string page_token = 3;
   */
  pageToken = "";

  /**
   * OPTIONAL. The direction to sort the returned records.
   * If unspecified, records will be returned in ascending order.
   * Records are sorted by the `create_time` field.
   *
   * @generated from field: ai.assistants.v0.SortOrder order = 6;
   */
  order = SortOrder.SORT_ORDER_UNSPECIFIED;

  /**
   * OPTIONAL. The ID of the Run to filter for.
   *
   * @generated from field: string run_id = 5;
   */
  runId = "";

  /**
   * OPTIONAL. Metadata to filter for. Metadata keys and values are case-sensitive.
   * Metadata values are AND-ed together, so only records that have all the specified metadata will be returned.
   *
   * @generated from field: map<string, string> metadata = 4;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ListMessagesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListMessagesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "order", kind: "enum", T: proto3.getEnumType(SortOrder) },
    { no: 5, name: "run_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMessagesRequest {
    return new ListMessagesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMessagesRequest {
    return new ListMessagesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMessagesRequest {
    return new ListMessagesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListMessagesRequest | PlainMessage<ListMessagesRequest> | undefined, b: ListMessagesRequest | PlainMessage<ListMessagesRequest> | undefined): boolean {
    return proto3.util.equals(ListMessagesRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.ListMessagesResponse
 */
export class ListMessagesResponse extends Message$1<ListMessagesResponse> {
  /**
   * @generated from field: repeated ai.assistants.v0.Message messages = 1;
   */
  messages: Message[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListMessagesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListMessagesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "messages", kind: "message", T: Message, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMessagesResponse {
    return new ListMessagesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMessagesResponse {
    return new ListMessagesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMessagesResponse {
    return new ListMessagesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListMessagesResponse | PlainMessage<ListMessagesResponse> | undefined, b: ListMessagesResponse | PlainMessage<ListMessagesResponse> | undefined): boolean {
    return proto3.util.equals(ListMessagesResponse, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.Run
 */
export class Run extends Message$1<Run> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string thread_id = 2;
   */
  threadId = "";

  /**
   * @generated from field: string assistant_id = 3;
   */
  assistantId = "";

  /**
   * @generated from field: string store_id = 4;
   */
  storeId = "";

  /**
   * @generated from field: string engine = 5;
   */
  engine = "";

  /**
   * @generated from field: string model = 6;
   */
  model = "";

  /**
   * @generated from field: string instructions = 7;
   */
  instructions = "";

  /**
   * @generated from field: ai.assistants.v0.ModelSettings model_settings = 8;
   */
  modelSettings?: ModelSettings;

  /**
   * @generated from field: ai.assistants.v0.RetrievalSettings retrieval_settings = 9;
   */
  retrievalSettings?: RetrievalSettings;

  /**
   * A list of sub-assistants that will be invoked by this Run.
   * Responses from sub-assistants will be combined and used as input to the primary assistant specified on this Run.
   *
   * @generated from field: repeated string sub_assistants = 19;
   */
  subAssistants: string[] = [];

  /**
   * Indicates that sub-assistants should be treated as tools rather than than fan-out
   *
   * @generated from field: bool sub_assistants_as_tools = 20;
   */
  subAssistantsAsTools = false;

  /**
   * Metadata associated with the run
   *
   * @generated from field: map<string, string> metadata = 15;
   */
  metadata: { [key: string]: string } = {};

  /**
   * The set of tools that the model can call
   *
   * @generated from field: repeated ai.assistants.v0.Tool tools = 17;
   */
  tools: Tool[] = [];

  /**
   * The set of tool calls that have been made during the run
   *
   * @generated from field: repeated ai.assistants.v0.ToolCall tool_calls = 18;
   */
  toolCalls: ToolCall[] = [];

  /**
   * JSON schema for the response.
   * Not all models support this feature. This field will be will be ignored if not supported.
   *
   * @generated from field: ai.assistants.v0.JsonSchemaResponseFormat response_json_schema = 21;
   */
  responseJsonSchema?: JsonSchemaResponseFormat;

  /**
   * The current state of the Run.
   *
   * @generated from field: ai.assistants.v0.Run.State state = 10;
   */
  state = Run_State.STATE_UNSPECIFIED;

  /**
   * The reason the Run failed. Only set when the state is `FAILED`.
   *
   * @generated from field: string failure_reason = 11;
   */
  failureReason = "";

  /**
   * Documents provided to the LLM run as context
   *
   * @generated from field: repeated ai.assistants.v0.Run.ContextDocument context_documents = 16;
   */
  contextDocuments: Run_ContextDocument[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 12;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 13;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 14;
   */
  endTime?: Timestamp;

  constructor(data?: PartialMessage<Run>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Run";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "engine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "model_settings", kind: "message", T: ModelSettings },
    { no: 9, name: "retrieval_settings", kind: "message", T: RetrievalSettings },
    { no: 19, name: "sub_assistants", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "sub_assistants_as_tools", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 17, name: "tools", kind: "message", T: Tool, repeated: true },
    { no: 18, name: "tool_calls", kind: "message", T: ToolCall, repeated: true },
    { no: 21, name: "response_json_schema", kind: "message", T: JsonSchemaResponseFormat },
    { no: 10, name: "state", kind: "enum", T: proto3.getEnumType(Run_State) },
    { no: 11, name: "failure_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "context_documents", kind: "message", T: Run_ContextDocument, repeated: true },
    { no: 12, name: "create_time", kind: "message", T: Timestamp },
    { no: 13, name: "start_time", kind: "message", T: Timestamp },
    { no: 14, name: "end_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Run {
    return new Run().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Run {
    return new Run().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Run {
    return new Run().fromJsonString(jsonString, options);
  }

  static equals(a: Run | PlainMessage<Run> | undefined, b: Run | PlainMessage<Run> | undefined): boolean {
    return proto3.util.equals(Run, a, b);
  }
}

/**
 * The enumeration of states that a Run may be in.
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.assistants.v0.Run.State
 */
export enum Run_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,

  /**
   * The Run is pending execution.
   *
   * @generated from enum value: PENDING = 1;
   */
  PENDING = 1,

  /**
   * The Run is currently executing.
   *
   * @generated from enum value: RUNNING = 2;
   */
  RUNNING = 2,

  /**
   * The Run has completed successfully.
   *
   * @generated from enum value: SUCCEEDED = 3;
   */
  SUCCEEDED = 3,

  /**
   * The Run has failed with an error. See the `failure_reason` field for details.
   *
   * @generated from enum value: FAILED = 4;
   */
  FAILED = 4,

  /**
   * The Run has yielded and requires a tool response as input from the user to proceed.
   *
   * @generated from enum value: TOOL_RESPONSE_REQUIRED = 5;
   */
  TOOL_RESPONSE_REQUIRED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Run_State)
proto3.util.setEnumType(Run_State, "ai.assistants.v0.Run.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "PENDING" },
  { no: 2, name: "RUNNING" },
  { no: 3, name: "SUCCEEDED" },
  { no: 4, name: "FAILED" },
  { no: 5, name: "TOOL_RESPONSE_REQUIRED" },
]);

/**
 * A document that was provided as context to the LLM run via RAG retrieval
 *
 * @generated from message ai.assistants.v0.Run.ContextDocument
 */
export class Run_ContextDocument extends Message$1<Run_ContextDocument> {
  /**
   * The full name of the file
   *
   * @generated from field: string filename = 1;
   */
  filename = "";

  /**
   * The url to download/view the file
   *
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * A trailer containing additional metadata about the document, such as pages
   *
   * @generated from field: string trailer = 3;
   */
  trailer = "";

  constructor(data?: PartialMessage<Run_ContextDocument>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.Run.ContextDocument";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "trailer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Run_ContextDocument {
    return new Run_ContextDocument().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Run_ContextDocument {
    return new Run_ContextDocument().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Run_ContextDocument {
    return new Run_ContextDocument().fromJsonString(jsonString, options);
  }

  static equals(a: Run_ContextDocument | PlainMessage<Run_ContextDocument> | undefined, b: Run_ContextDocument | PlainMessage<Run_ContextDocument> | undefined): boolean {
    return proto3.util.equals(Run_ContextDocument, a, b);
  }
}

/**
 * Request message for the `CreateThreadAndRun` method.
 *
 * @generated from message ai.assistants.v0.CreateThreadAndRunRequest
 */
export class CreateThreadAndRunRequest extends Message$1<CreateThreadAndRunRequest> {
  /**
   * REQUIRED. Configuration for the Thread to create.
   *
   * @generated from field: ai.assistants.v0.CreateThreadRequest thread = 1;
   */
  thread?: CreateThreadRequest;

  /**
   * REQUIRED. ID of the Assistant to execute.
   *
   * @generated from field: string assistant_id = 2;
   */
  assistantId = "";

  /**
   * OPTIONAL. The chat engine to use for this Run.
   *
   * @generated from field: string engine = 3;
   */
  engine = "";

  /**
   * OPTIONAL. The model to use for this Run.
   *
   * @generated from field: string model = 4;
   */
  model = "";

  /**
   * OPTIONAL. The system instructions to use for this Run. This completely overrides the instructions provided by the
   * Assistant.
   *
   * @generated from field: string instructions = 5;
   */
  instructions = "";

  /**
   * OPTIONAL. Additional system instructions to use for this Run. These will be appended to the instructions provided
   * by the Assistant or in the `instructions` field on this Run.
   *
   * @generated from field: string additional_instructions = 6;
   */
  additionalInstructions = "";

  /**
   * OPTIONAL. Settings provided to the model for this Run.
   *
   * @generated from field: ai.assistants.v0.ModelSettings model_settings = 7;
   */
  modelSettings?: ModelSettings;

  /**
   * OPTIONAL. Settings used to control RAG behavior for this Run.
   *
   * @generated from field: ai.assistants.v0.RetrievalSettings retrieval_settings = 8;
   */
  retrievalSettings?: RetrievalSettings;

  /**
   * OPTIONAL. The set of tools that should be used for this run
   * This will override the assistant's default tools
   *
   * @generated from field: repeated ai.assistants.v0.Tool tools = 10;
   */
  tools: Tool[] = [];

  /**
   * OPTIONAL. JSON schema for the response.
   * Not all models support this feature. This field will be will be ignored if not supported.
   *
   * @generated from field: ai.assistants.v0.JsonSchemaResponseFormat response_json_schema = 11;
   */
  responseJsonSchema?: JsonSchemaResponseFormat;

  /**
   * OPTIONAL. Metadata associated with the Run. This can be used to store additional
   * information about the Run. Can be set on create but not updated.
   *
   * @generated from field: map<string, string> metadata = 9;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CreateThreadAndRunRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.CreateThreadAndRunRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "thread", kind: "message", T: CreateThreadRequest },
    { no: 2, name: "assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "engine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "additional_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "model_settings", kind: "message", T: ModelSettings },
    { no: 8, name: "retrieval_settings", kind: "message", T: RetrievalSettings },
    { no: 10, name: "tools", kind: "message", T: Tool, repeated: true },
    { no: 11, name: "response_json_schema", kind: "message", T: JsonSchemaResponseFormat },
    { no: 9, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateThreadAndRunRequest {
    return new CreateThreadAndRunRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateThreadAndRunRequest {
    return new CreateThreadAndRunRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateThreadAndRunRequest {
    return new CreateThreadAndRunRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateThreadAndRunRequest | PlainMessage<CreateThreadAndRunRequest> | undefined, b: CreateThreadAndRunRequest | PlainMessage<CreateThreadAndRunRequest> | undefined): boolean {
    return proto3.util.equals(CreateThreadAndRunRequest, a, b);
  }
}

/**
 * Request message for the `CreateRun` method.
 *
 * @generated from message ai.assistants.v0.CreateRunRequest
 */
export class CreateRunRequest extends Message$1<CreateRunRequest> {
  /**
   * REQUIRED. The ID of the Thread to create the Run on.
   *
   * @generated from field: string thread_id = 1;
   */
  threadId = "";

  /**
   * REQUIRED. The ID of the Assistant to execute.
   *
   * @generated from field: string assistant_id = 2;
   */
  assistantId = "";

  /**
   * OPTIONAL. The chat engine to use for this Run.
   *
   * @generated from field: string engine = 3;
   */
  engine = "";

  /**
   * OPTIONAL. The model to use for this Run.
   *
   * @generated from field: string model = 4;
   */
  model = "";

  /**
   * OPTIONAL. The system instructions to use for this Run. This completely overrides the instructions provided by the
   * Assistant.
   *
   * @generated from field: string instructions = 5;
   */
  instructions = "";

  /**
   * OPTIONAL. Additional system instructions to use for this Run. These will be appended to the instructions provided
   * by the Assistant or in the `instructions` field on this Run.
   *
   * @generated from field: string additional_instructions = 6;
   */
  additionalInstructions = "";

  /**
   * OPTIONAL. Settings provided to the model for this Run.
   *
   * @generated from field: ai.assistants.v0.ModelSettings model_settings = 8;
   */
  modelSettings?: ModelSettings;

  /**
   * OPTIONAL. Settings used to control RAG behavior for this Run.
   *
   * @generated from field: ai.assistants.v0.RetrievalSettings retrieval_settings = 9;
   */
  retrievalSettings?: RetrievalSettings;

  /**
   * OPTIONAL. The set of tools that should be used for this run
   * This will override the assistant's default tools
   *
   * @generated from field: repeated ai.assistants.v0.Tool tools = 11;
   */
  tools: Tool[] = [];

  /**
   * OPTIONAL. JSON schema for the response.
   * Not all models support this feature. This field will be will be ignored if not supported.
   *
   * @generated from field: ai.assistants.v0.JsonSchemaResponseFormat response_json_schema = 12;
   */
  responseJsonSchema?: JsonSchemaResponseFormat;

  /**
   * OPTIONAL. Additional Messages to append to the Thread prior to executing the Run.
   *
   * @generated from field: repeated ai.assistants.v0.CreateMessageRequest additional_messages = 7;
   */
  additionalMessages: CreateMessageRequest[] = [];

  /**
   * OPTIONAL. Metadata associated with the Run. This can be used to store additional
   * information about the Run. Can be set on create but not updated.
   *
   * @generated from field: map<string, string> metadata = 10;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CreateRunRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.CreateRunRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "engine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "additional_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "model_settings", kind: "message", T: ModelSettings },
    { no: 9, name: "retrieval_settings", kind: "message", T: RetrievalSettings },
    { no: 11, name: "tools", kind: "message", T: Tool, repeated: true },
    { no: 12, name: "response_json_schema", kind: "message", T: JsonSchemaResponseFormat },
    { no: 7, name: "additional_messages", kind: "message", T: CreateMessageRequest, repeated: true },
    { no: 10, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRunRequest {
    return new CreateRunRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRunRequest {
    return new CreateRunRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRunRequest {
    return new CreateRunRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRunRequest | PlainMessage<CreateRunRequest> | undefined, b: CreateRunRequest | PlainMessage<CreateRunRequest> | undefined): boolean {
    return proto3.util.equals(CreateRunRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.GetRunRequest
 */
export class GetRunRequest extends Message$1<GetRunRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetRunRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.GetRunRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRunRequest {
    return new GetRunRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRunRequest {
    return new GetRunRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRunRequest {
    return new GetRunRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRunRequest | PlainMessage<GetRunRequest> | undefined, b: GetRunRequest | PlainMessage<GetRunRequest> | undefined): boolean {
    return proto3.util.equals(GetRunRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.ListRunsRequest
 */
export class ListRunsRequest extends Message$1<ListRunsRequest> {
  /**
   * Optional. Maximum number of assistants to return in a single page.
   * If unspecified, at most 50 will be returned. The maximum value is 100; values over
   * 100 will be coerced to 100.
   *
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  /**
   * Optional. Token of the previous call to ListAssistants used to retrieve the next page.
   * All other fields must match the previous call.
   *
   * @generated from field: string page_token = 4;
   */
  pageToken = "";

  /**
   * Optional. The assistant ID to filter for.
   *
   * @generated from field: string assistant_id = 1;
   */
  assistantId = "";

  /**
   * Optional. The thread ID to filter for.
   *
   * @generated from field: string thread_id = 2;
   */
  threadId = "";

  /**
   * Optional. Metadata to filter for. Metadata keys and values are case-sensitive.
   * Metadata values are AND-ed together, so only records that have all the specified metadata will be returned.
   *
   * @generated from field: map<string, string> metadata = 5;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ListRunsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListRunsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunsRequest {
    return new ListRunsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunsRequest {
    return new ListRunsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunsRequest {
    return new ListRunsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunsRequest | PlainMessage<ListRunsRequest> | undefined, b: ListRunsRequest | PlainMessage<ListRunsRequest> | undefined): boolean {
    return proto3.util.equals(ListRunsRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.ListRunsResponse
 */
export class ListRunsResponse extends Message$1<ListRunsResponse> {
  /**
   * @generated from field: repeated ai.assistants.v0.Run runs = 1;
   */
  runs: Run[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListRunsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.ListRunsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runs", kind: "message", T: Run, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunsResponse {
    return new ListRunsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunsResponse {
    return new ListRunsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunsResponse {
    return new ListRunsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunsResponse | PlainMessage<ListRunsResponse> | undefined, b: ListRunsResponse | PlainMessage<ListRunsResponse> | undefined): boolean {
    return proto3.util.equals(ListRunsResponse, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.DeleteRunRequest
 */
export class DeleteRunRequest extends Message$1<DeleteRunRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteRunRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.DeleteRunRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRunRequest {
    return new DeleteRunRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRunRequest {
    return new DeleteRunRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRunRequest {
    return new DeleteRunRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRunRequest | PlainMessage<DeleteRunRequest> | undefined, b: DeleteRunRequest | PlainMessage<DeleteRunRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRunRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.StreamRunResultsRequest
 */
export class StreamRunResultsRequest extends Message$1<StreamRunResultsRequest> {
  /**
   * @generated from field: string run_id = 1;
   */
  runId = "";

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 2;
   */
  startTime?: Timestamp;

  constructor(data?: PartialMessage<StreamRunResultsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.StreamRunResultsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "run_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamRunResultsRequest {
    return new StreamRunResultsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamRunResultsRequest {
    return new StreamRunResultsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamRunResultsRequest {
    return new StreamRunResultsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StreamRunResultsRequest | PlainMessage<StreamRunResultsRequest> | undefined, b: StreamRunResultsRequest | PlainMessage<StreamRunResultsRequest> | undefined): boolean {
    return proto3.util.equals(StreamRunResultsRequest, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.StreamRunResultsResponse
 */
export class StreamRunResultsResponse extends Message$1<StreamRunResultsResponse> {
  /**
   * @generated from field: ai.assistants.v0.Message message = 1;
   */
  message?: Message;

  /**
   * @generated from field: google.protobuf.Timestamp event_time = 2;
   */
  eventTime?: Timestamp;

  constructor(data?: PartialMessage<StreamRunResultsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.StreamRunResultsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "message", kind: "message", T: Message },
    { no: 2, name: "event_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamRunResultsResponse {
    return new StreamRunResultsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamRunResultsResponse {
    return new StreamRunResultsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamRunResultsResponse {
    return new StreamRunResultsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StreamRunResultsResponse | PlainMessage<StreamRunResultsResponse> | undefined, b: StreamRunResultsResponse | PlainMessage<StreamRunResultsResponse> | undefined): boolean {
    return proto3.util.equals(StreamRunResultsResponse, a, b);
  }
}

/**
 * @generated from message ai.assistants.v0.SubmitToolResponsesRequest
 */
export class SubmitToolResponsesRequest extends Message$1<SubmitToolResponsesRequest> {
  /**
   * The ID of the Run containing the ToolCalls to respond to
   *
   * @generated from field: string run_id = 1;
   */
  runId = "";

  /**
   * The responses to the ToolCalls
   *
   * @generated from field: repeated ai.assistants.v0.SubmitToolResponsesRequest.ToolResponse responses = 2;
   */
  responses: SubmitToolResponsesRequest_ToolResponse[] = [];

  constructor(data?: PartialMessage<SubmitToolResponsesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.SubmitToolResponsesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "run_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "responses", kind: "message", T: SubmitToolResponsesRequest_ToolResponse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitToolResponsesRequest {
    return new SubmitToolResponsesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitToolResponsesRequest {
    return new SubmitToolResponsesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitToolResponsesRequest {
    return new SubmitToolResponsesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitToolResponsesRequest | PlainMessage<SubmitToolResponsesRequest> | undefined, b: SubmitToolResponsesRequest | PlainMessage<SubmitToolResponsesRequest> | undefined): boolean {
    return proto3.util.equals(SubmitToolResponsesRequest, a, b);
  }
}

/**
 * A response from the user to a ToolCall returned by a Run
 *
 * @generated from message ai.assistants.v0.SubmitToolResponsesRequest.ToolResponse
 */
export class SubmitToolResponsesRequest_ToolResponse extends Message$1<SubmitToolResponsesRequest_ToolResponse> {
  /**
   * The ID of the ToolCall being responded to
   *
   * @generated from field: string tool_call_id = 1;
   */
  toolCallId = "";

  /**
   * Indicates that the tool call failed
   *
   * @generated from field: bool error = 2;
   */
  error = false;

  /**
   * The content of the tool response
   *
   * @generated from field: string text = 3;
   */
  text = "";

  constructor(data?: PartialMessage<SubmitToolResponsesRequest_ToolResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.SubmitToolResponsesRequest.ToolResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tool_call_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitToolResponsesRequest_ToolResponse {
    return new SubmitToolResponsesRequest_ToolResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitToolResponsesRequest_ToolResponse {
    return new SubmitToolResponsesRequest_ToolResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitToolResponsesRequest_ToolResponse {
    return new SubmitToolResponsesRequest_ToolResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitToolResponsesRequest_ToolResponse | PlainMessage<SubmitToolResponsesRequest_ToolResponse> | undefined, b: SubmitToolResponsesRequest_ToolResponse | PlainMessage<SubmitToolResponsesRequest_ToolResponse> | undefined): boolean {
    return proto3.util.equals(SubmitToolResponsesRequest_ToolResponse, a, b);
  }
}

/**
 * Request to provide feedback on a Run
 *
 * @generated from message ai.assistants.v0.CreateRunFeedbackRequest
 */
export class CreateRunFeedbackRequest extends Message$1<CreateRunFeedbackRequest> {
  /**
   * REQUIRED: The ID of the Run that feedback is being provided for.
   *
   * @generated from field: string run_id = 1;
   */
  runId = "";

  /**
   * REQUIRED: The rating of the Run.
   *
   * @generated from field: ai.assistants.v0.CreateRunFeedbackRequest.Rating rating = 2;
   */
  rating = CreateRunFeedbackRequest_Rating.RATING_UNSPECIFIED;

  /**
   * OPTIONAL: The reason for the rating.
   *
   * @generated from field: string reason = 3;
   */
  reason = "";

  /**
   * OPTIONAL: Additional feedback comment.
   *
   * @generated from field: string comment = 4;
   */
  comment = "";

  constructor(data?: PartialMessage<CreateRunFeedbackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.CreateRunFeedbackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "run_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rating", kind: "enum", T: proto3.getEnumType(CreateRunFeedbackRequest_Rating) },
    { no: 3, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRunFeedbackRequest {
    return new CreateRunFeedbackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRunFeedbackRequest {
    return new CreateRunFeedbackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRunFeedbackRequest {
    return new CreateRunFeedbackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRunFeedbackRequest | PlainMessage<CreateRunFeedbackRequest> | undefined, b: CreateRunFeedbackRequest | PlainMessage<CreateRunFeedbackRequest> | undefined): boolean {
    return proto3.util.equals(CreateRunFeedbackRequest, a, b);
  }
}

/**
 * Allowed rating values.
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.assistants.v0.CreateRunFeedbackRequest.Rating
 */
export enum CreateRunFeedbackRequest_Rating {
  /**
   * @generated from enum value: RATING_UNSPECIFIED = 0;
   */
  RATING_UNSPECIFIED = 0,

  /**
   * @generated from enum value: POSITIVE = 1;
   */
  POSITIVE = 1,

  /**
   * @generated from enum value: NEGATIVE = 2;
   */
  NEGATIVE = 2,

  /**
   * @generated from enum value: NEUTRAL = 3;
   */
  NEUTRAL = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(CreateRunFeedbackRequest_Rating)
proto3.util.setEnumType(CreateRunFeedbackRequest_Rating, "ai.assistants.v0.CreateRunFeedbackRequest.Rating", [
  { no: 0, name: "RATING_UNSPECIFIED" },
  { no: 1, name: "POSITIVE" },
  { no: 2, name: "NEGATIVE" },
  { no: 3, name: "NEUTRAL" },
]);

/**
 * A feedback entry for a Run
 *
 * @generated from message ai.assistants.v0.RunFeedback
 */
export class RunFeedback extends Message$1<RunFeedback> {
  /**
   * The ID of the Run Feedback entry
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<RunFeedback>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.assistants.v0.RunFeedback";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunFeedback {
    return new RunFeedback().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunFeedback {
    return new RunFeedback().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunFeedback {
    return new RunFeedback().fromJsonString(jsonString, options);
  }

  static equals(a: RunFeedback | PlainMessage<RunFeedback> | undefined, b: RunFeedback | PlainMessage<RunFeedback> | undefined): boolean {
    return proto3.util.equals(RunFeedback, a, b);
  }
}

