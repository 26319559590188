class LocalStorage {
  
  public static defaultAssistantId = 'default';

  public static getSelectedAssistantId(): string {
    return window.localStorage.getItem('selectedAssistantId') || LocalStorage.defaultAssistantId;
  }

  public static setSelectedAssistantId(id: string): void {
    return window.localStorage.setItem('selectedAssistantId', id);
  }
  
  public static getLeftPanelWidth(): string {
    return window.localStorage.getItem('leftPanelWidth') || '250';
  }

  public static setLeftPanelWidth(width: string): void {
    return window.localStorage.setItem('leftPanelWidth', width);
  }
}

export default LocalStorage;