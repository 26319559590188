// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/rag/v0/stores.proto (package ai.rag.v0, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// buf:lint:ignore PACKAGE_VERSION_SUFFIX

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * A file in an index store.
 *
 * @generated from message ai.rag.v0.IndexFile
 */
export class IndexFile extends Message<IndexFile> {
  /**
   * The ID of the store the file belongs to.
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * The name of the file.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * The time the file was last uploaded.
   *
   * @generated from field: google.protobuf.Timestamp upload_time = 3;
   */
  uploadTime?: Timestamp;

  /**
   * Information about the file.
   *
   * @generated from oneof ai.rag.v0.IndexFile.info
   */
  info: {
    /**
     * Information about a directory.
     * Directory information is only populated when the `recursive` flag on the request is set to `false`.
     *
     * @generated from field: ai.rag.v0.IndexFile.DirectoryInfo directory_info = 4;
     */
    value: IndexFile_DirectoryInfo;
    case: "directoryInfo";
  } | {
    /**
     * Information about a file.
     *
     * @generated from field: ai.rag.v0.IndexFile.FileInfo file_info = 5;
     */
    value: IndexFile_FileInfo;
    case: "fileInfo";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<IndexFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.rag.v0.IndexFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "upload_time", kind: "message", T: Timestamp },
    { no: 4, name: "directory_info", kind: "message", T: IndexFile_DirectoryInfo, oneof: "info" },
    { no: 5, name: "file_info", kind: "message", T: IndexFile_FileInfo, oneof: "info" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexFile {
    return new IndexFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexFile {
    return new IndexFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexFile {
    return new IndexFile().fromJsonString(jsonString, options);
  }

  static equals(a: IndexFile | PlainMessage<IndexFile> | undefined, b: IndexFile | PlainMessage<IndexFile> | undefined): boolean {
    return proto3.util.equals(IndexFile, a, b);
  }
}

/**
 * The indexing state of a IndexFile.
 * buf:lint:ignore ENUM_VALUE_PREFIX
 *
 * @generated from enum ai.rag.v0.IndexFile.State
 */
export enum IndexFile_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: INDEXING = 1;
   */
  INDEXING = 1,

  /**
   * @generated from enum value: INDEXED = 2;
   */
  INDEXED = 2,

  /**
   * @generated from enum value: FAILED = 3;
   */
  FAILED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(IndexFile_State)
proto3.util.setEnumType(IndexFile_State, "ai.rag.v0.IndexFile.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "INDEXING" },
  { no: 2, name: "INDEXED" },
  { no: 3, name: "FAILED" },
]);

/**
 * Information about a directory.
 *
 * @generated from message ai.rag.v0.IndexFile.DirectoryInfo
 */
export class IndexFile_DirectoryInfo extends Message<IndexFile_DirectoryInfo> {
  /**
   * The count of states of all files within the directory and its subdirectories.
   *
   * @generated from field: map<string, uint32> states = 1;
   */
  states: { [key: string]: number } = {};

  constructor(data?: PartialMessage<IndexFile_DirectoryInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.rag.v0.IndexFile.DirectoryInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "states", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 13 /* ScalarType.UINT32 */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexFile_DirectoryInfo {
    return new IndexFile_DirectoryInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexFile_DirectoryInfo {
    return new IndexFile_DirectoryInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexFile_DirectoryInfo {
    return new IndexFile_DirectoryInfo().fromJsonString(jsonString, options);
  }

  static equals(a: IndexFile_DirectoryInfo | PlainMessage<IndexFile_DirectoryInfo> | undefined, b: IndexFile_DirectoryInfo | PlainMessage<IndexFile_DirectoryInfo> | undefined): boolean {
    return proto3.util.equals(IndexFile_DirectoryInfo, a, b);
  }
}

/**
 * Information about a file.
 *
 * @generated from message ai.rag.v0.IndexFile.FileInfo
 */
export class IndexFile_FileInfo extends Message<IndexFile_FileInfo> {
  /**
   * The MD5 hash of the file.
   *
   * @generated from field: string md5 = 1;
   */
  md5 = "";

  /**
   * The indexing state of the file.
   *
   * @generated from field: ai.rag.v0.IndexFile.State state = 2;
   */
  state = IndexFile_State.STATE_UNSPECIFIED;

  /**
   * The reason for the state. This field is only populated when the state is FAILED.
   *
   * @generated from field: string state_reason = 3;
   */
  stateReason = "";

  constructor(data?: PartialMessage<IndexFile_FileInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.rag.v0.IndexFile.FileInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "md5", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(IndexFile_State) },
    { no: 3, name: "state_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndexFile_FileInfo {
    return new IndexFile_FileInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndexFile_FileInfo {
    return new IndexFile_FileInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndexFile_FileInfo {
    return new IndexFile_FileInfo().fromJsonString(jsonString, options);
  }

  static equals(a: IndexFile_FileInfo | PlainMessage<IndexFile_FileInfo> | undefined, b: IndexFile_FileInfo | PlainMessage<IndexFile_FileInfo> | undefined): boolean {
    return proto3.util.equals(IndexFile_FileInfo, a, b);
  }
}

/**
 * Request to the `ListIndexFiles` method.
 *
 * @generated from message ai.rag.v0.ListIndexFilesRequest
 */
export class ListIndexFilesRequest extends Message<ListIndexFilesRequest> {
  /**
   * REQUIRED. The ID of the store to list files from.
   *
   * @generated from field: string store_id = 1;
   */
  storeId = "";

  /**
   * OPTIONAL. List files in the specified directory prefix. When not specified, the root directory will be used.
   *
   * @generated from field: string directory = 2;
   */
  directory = "";

  /**
   * OPTIONAL. List files recursively.
   * When set to true, directory entries will not be included in the response.
   *
   * @generated from field: bool recursive = 3;
   */
  recursive = false;

  /**
   * OPTIONAL. The maximum number of results to return.
   * When not specified a default of 100 will be used. When a value greater than 1000 is specified, the value will be
   * coerced down to 1000.
   *
   * @generated from field: int32 page_size = 5;
   */
  pageSize = 0;

  /**
   * OPTIONAL. The page token to use for the next page of results.
   * When not specified, the first page of results will be returned.
   * All filter parameters must remain the same when using a page token.
   *
   * @generated from field: string page_token = 6;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListIndexFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.rag.v0.ListIndexFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "directory", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "recursive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIndexFilesRequest {
    return new ListIndexFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIndexFilesRequest {
    return new ListIndexFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIndexFilesRequest {
    return new ListIndexFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListIndexFilesRequest | PlainMessage<ListIndexFilesRequest> | undefined, b: ListIndexFilesRequest | PlainMessage<ListIndexFilesRequest> | undefined): boolean {
    return proto3.util.equals(ListIndexFilesRequest, a, b);
  }
}

/**
 * Response to the `ListIndexFiles` method.
 *
 * @generated from message ai.rag.v0.ListIndexFilesResponse
 */
export class ListIndexFilesResponse extends Message<ListIndexFilesResponse> {
  /**
   * The list of files
   *
   * @generated from field: repeated ai.rag.v0.IndexFile files = 1;
   */
  files: IndexFile[] = [];

  /**
   * The token used to retrieve the next page of files.
   * If there are no more pages, this field will be empty.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListIndexFilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.rag.v0.ListIndexFilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "files", kind: "message", T: IndexFile, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIndexFilesResponse {
    return new ListIndexFilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIndexFilesResponse {
    return new ListIndexFilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIndexFilesResponse {
    return new ListIndexFilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListIndexFilesResponse | PlainMessage<ListIndexFilesResponse> | undefined, b: ListIndexFilesResponse | PlainMessage<ListIndexFilesResponse> | undefined): boolean {
    return proto3.util.equals(ListIndexFilesResponse, a, b);
  }
}

