import {
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useModels } from "../../state/models";
import { Model, Model_InputFormats, Model_Tag } from "../../gen-ts/ai/engine_pb";
import { MODEL_ENGINES_ICONS } from "../../config";
import { MdHorizontalSplit, MdSearch } from "react-icons/md";
import FilterButton from "../FilterButton";
import { ModelFilter } from "../../types";



interface Props {
  selectedModel: string;
  onSelect: (selectedModel: string, selectedEngine: string) => void;
  isOpen: boolean;
  onClose: () => void;
}
const ChangeModelDialog: React.FC<Props> = ({ isOpen, selectedModel, onSelect, onClose }) => {
  const [ searchQuery, setSearchQuery ] = useQueryParam("query", StringParam);

  const modelsState = useModels();

  useEffect(() => {
    modelsState.load();
    //eslint-disable-next-line
  }, []);


  const modelIds = useMemo(() => {
    let list: string[] = [];
    for (const id in modelsState.models) {
      list.push(`${modelsState.modelEngines[ id ]}#${id}`);
    }

    list = list.sort((a, b) => {
      return a > b ? 1 : -1;
    });

    return list.map((id) => id.split('#')[ 1 ]);

    //eslint-disable-next-line
  }, [ modelsState.models ]);


  const searchedModelIds = useMemo(() => {
    if (!searchQuery) {
      return modelIds;
    }

    const result = modelsState.searchIndex.search(searchQuery);
    const list: string[] = [];
    for (const id of result) {

      const modelId = id.toString().split('#')[ 1 ];
      list.push(modelId);
    }

    return list;
    //eslint-disable-next-line
  }, [ modelIds, searchQuery ]);

  const filteredModelIds = useMemo(() => {
    if (modelsState.filters.length === 0) {
      return searchedModelIds;
    }

    return searchedModelIds.filter((id) => {
      const model = modelsState.models[ id ];

      if (modelsState.filters.includes(ModelFilter.RECOMMENDED) && !model.recommended) {
        return false;
      }

      if (modelsState.filters.includes(ModelFilter.IMAGE) && !model.supportedInputFormats.includes(Model_InputFormats.IMAGE)) {
        return false;
      }

      if (modelsState.filters.includes(ModelFilter.CREATIVE_WRITING) && !model.tags.includes(Model_Tag.CREATIVE_WRITING)) {
        return false;
      }

      if (modelsState.filters.includes(ModelFilter.CODING) && !model.tags.includes(Model_Tag.CODING)) {
        return false;
      }

      if (modelsState.filters.includes(ModelFilter.FAST) && !model.tags.includes(Model_Tag.FAST)) {
        return false;
      }

      if (modelsState.filters.includes(ModelFilter.ONLINE_ACCESS) && !model.tags.includes(Model_Tag.ONLINE_ACCESS)) {
        return false;
      }

      return true;
    });

    //eslint-disable-next-line
  }, [ searchedModelIds, modelsState.filters ]);

  const searchBorderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxWidth="900px" maxHeight="90vh" overflow="scroll">
        <ModalHeader>Change Model</ModalHeader>
        <ModalCloseButton />
        <ModalBody height="100%" overflow="scroll" width="100%">
          <VStack align="start">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <Icon as={MdSearch} size="lg" />
              </InputLeftElement>
              <Input
                placeholder="Search"
                borderRadius={20}
                borderColor={searchBorderColor}
                borderWidth={2}
                value={searchQuery || ''}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>

            <HStack spacing={2} overflow="scroll" width="100%" alignItems="stretch">
              <FilterButton
                icon={MdHorizontalSplit}
                title={ModelFilter.RECOMMENDED}
                isSelected={modelsState.filters.includes(ModelFilter.RECOMMENDED)}
                onClick={() => modelsState.toggleFilter(ModelFilter.RECOMMENDED)}
              />

              <FilterButton
                icon={MdHorizontalSplit}
                title={ModelFilter.IMAGE}
                isSelected={modelsState.filters.includes(ModelFilter.IMAGE)}
                onClick={() => modelsState.toggleFilter(ModelFilter.IMAGE)}
              />
              <FilterButton
                icon={MdHorizontalSplit}
                title={ModelFilter.CREATIVE_WRITING}
                isSelected={modelsState.filters.includes(ModelFilter.CREATIVE_WRITING)}
                onClick={() => modelsState.toggleFilter(ModelFilter.CREATIVE_WRITING)}
              />
              <FilterButton
                icon={MdHorizontalSplit}
                title={ModelFilter.CODING}
                isSelected={modelsState.filters.includes(ModelFilter.CODING)}
                onClick={() => modelsState.toggleFilter(ModelFilter.CODING)}
              />
              <FilterButton
                icon={MdHorizontalSplit}
                title={ModelFilter.FAST}
                isSelected={modelsState.filters.includes(ModelFilter.FAST)}
                onClick={() => modelsState.toggleFilter(ModelFilter.FAST)}
              />
              <FilterButton
                icon={MdHorizontalSplit}
                title={ModelFilter.ONLINE_ACCESS}
                isSelected={modelsState.filters.includes(ModelFilter.ONLINE_ACCESS)}
                onClick={() => modelsState.toggleFilter(ModelFilter.ONLINE_ACCESS)}
              />

            </HStack>

            <Wrap p={6} spacing={4} width="100%" mt={5} justify="space-evenly">
              {
                filteredModelIds.map((id, index) => {
                  const model = modelsState.models[ id ];
                  const engine = modelsState.modelEngines[ id ];

                  return (
                    <WrapItem key={index}>
                      <ModelCard
                        model={model}
                        engine={engine}
                        isSelected={selectedModel === id}
                        onClick={() => onSelect(id, engine)}
                      />
                    </WrapItem>
                  );
                })
              }
            </Wrap>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChangeModelDialog;



interface ModelCardProps {
  model: Model;
  engine: string;
  isSelected: boolean;
  onClick: () => void;
}

const ModelCard: React.FC<ModelCardProps> = ({ model, engine, isSelected, onClick }) => {
  const unselectedBorderColor = useColorModeValue('gray.200', 'gray.600');


  return (
    <VStack
      width="250px"
      height="100%"
      bgColor={useColorModeValue('white', 'gray.700')}
      borderRadius={10}
      boxShadow="sm"
      borderColor={isSelected ? 'primary.500' : unselectedBorderColor}
      borderWidth="1px"
      align="start"
      p={3}
      overflow="hidden"
      cursor="pointer"
      onClick={onClick}
    >
      <Image src={MODEL_ENGINES_ICONS[ engine ] as string} height="30px" />
      <Text>{engine}</Text>
      <Text fontWeight="600">{model.name}</Text>
      <Text fontSize="14px">{model.description}</Text>
    </VStack>
  )
};