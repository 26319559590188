import { create } from "zustand"
import { getApiClient } from "../api";
import {
  Assistant,
  CreateAssistantRequest,
  GetAssistantRequest,
  ListAssistantsRequest,
  TestAssistantPermissionsRequest,
  UpdateAssistantRequest,
  UpdateAssistantRequest_OwnerList,
  UpdateAssistantRequest_UploaderList,
  UpdateAssistantRequest_ViewerList,
} from "../gen-ts/ai/assistants/v0/assistant_pb";
import { AssistantFilter } from "../types";
import { Index } from "flexsearch-ts";
import LocalStorage from "../services/localStorage";
import { useUserState } from "./user";
import { useAssistantFiles } from "./assistantFiles";
import { StringList } from "../gen-ts/ai/type/list_pb";
import { ASSISTANT_PEPRMISSIONS, DEFAULT_ASSISTANT_ID } from "../config";



interface AssistantsState {
  assistants: {
    [ assistantId: string ]: Assistant;
  },
  permissions: {
    [ assistantId: string ]: {
      canEdit: boolean,
      canUpload: boolean,
    },
  },
  newAssistants: string[],
  loadingList: boolean,
  listLoaded: boolean,
  loadingById: {
    [ assistantId: string ]: boolean
  },
  searchIndex: Index,
  selectedAssistant: string;
  updateSearchIndex: () => void,
  setSelectedAssistantId: (assistantId: string) => void,
  loadList: () => void,
  loadById: (args: { assistantId: string, fallbackToDefault?: boolean, onError?: () => void }) => void,
  setAssistants: (assistants: Assistant[]) => void,
  filters: AssistantFilter[],
  toggleFilter: (filter: AssistantFilter) => void,
  createNewAssistant: () => Promise<string | null>,
  deleteAssistant: (assistantId: string) => void,
  deleteNewAssistant: (assistantId: string) => void,
  saveAssistant: (assistant: Assistant) => void,
  cloneAssistant: (assistantId: string, name: string) => Promise<string | null>,
  checkPermissions: (assistantId: string) => void,
}

export const useAssistants = create<AssistantsState>((set, get) => ({
  assistants: {},
  permissions: {},
  newAssistants: [],
  loadingList: false,
  loadingById: {},
  listLoaded: false,
  filters: [],
  favorites: [],
  selectedAssistant: LocalStorage.getSelectedAssistantId(),
  setSelectedAssistantId: (assistantId: string) => {
    set({ selectedAssistant: assistantId });
    LocalStorage.setSelectedAssistantId(assistantId);
  },
  searchIndex: new Index({
    tokenize: 'full',
  }),
  updateSearchIndex: () => {
    for (const id in get().assistants) {
      const assistant = get().assistants[ id ];
      const content = `${assistant.displayName}.\n${assistant.description}`;
      get().searchIndex.add(id, content);
    }
  },
  loadList: async () => {
    if (get().loadingList || get().listLoaded) {
      return;
    }

    set({ loadingList: true });

    try {
      const { client, headers } = getApiClient();

      let hasMoreData = true;
      let pageToken: string | undefined = undefined;

      while (hasMoreData) {
        const listAssistantsReq: ListAssistantsRequest = new ListAssistantsRequest({ pageSize: 1000, pageToken });
        const listAssistantsResp = await client.listAssistants(listAssistantsReq, { headers });
        pageToken = listAssistantsResp.nextPageToken;
        if (!pageToken) {
          hasMoreData = false;
        }

        set((state) => {
          const assistants = { ...state.assistants };
          listAssistantsResp.assistants.forEach((assistant) => {
            assistants[ assistant.id ] = assistant;
          });
          return { assistants, nextPageToken: listAssistantsResp.nextPageToken };
        });
      }


      set({ listLoaded: true });




      get().updateSearchIndex();
    } catch (e) {
      console.error(e);
    } finally {
      set({ loadingList: false });
    }
  },
  loadById: async ({ assistantId, fallbackToDefault, onError }) => {
    assistantId = assistantId.trim();
    if (get().loadingById[ assistantId ]) {
      return;
    }

    if (get().assistants[ assistantId ] && get().assistants[ assistantId ].instructions.trim() !== '') {
      return;
    }

    set({
      loadingById: {
        ...get().loadingById,
        [ assistantId ]: true,
      }
    });

    try {
      let assistant = await loadAssistantById(assistantId);
      if (!assistant) {
        if (fallbackToDefault) {
          assistant = await loadAssistantById('default');
        }
        onError && onError();
      }

      if (assistant) {
        set((state) => {
          const assistants = { ...state.assistants };

          assistants[ assistantId ] = assistant!.clone();
          return { assistants };
        });

        get().updateSearchIndex();
      }
    } catch (e) {
      console.error(e);
    } finally {
      set({
        loadingById: {
          ...get().loadingById,
          [ assistantId ]: false,
        }
      });
    }
  },
  setAssistants: (assistants: Assistant[]) => {
    const map: { [ assistantId: string ]: Assistant } = {};
    assistants.forEach((assistant) => {
      map[ assistant.id ] = assistant;
    });


    const ids = assistants.map((a) => a.id);

    set({
      assistants: {
        ...get().assistants,
        ...map,
      },
      newAssistants: get().newAssistants.filter((id) => !ids.includes(id)),
    });
    get().updateSearchIndex();
  },
  toggleFilter: (filter: AssistantFilter) => {
    set((state) => {
      const filters = state.filters.includes(filter)
        ? state.filters.filter((f) => f !== filter)
        : [ ...state.filters, filter ];
      return { filters };
    });
  },
  createNewAssistant: async () => {
    try {
      const userName = useUserState.getState().user?.name;
      if (!userName) {
        return null;
      }

      const defaultAssistant = get().assistants[ DEFAULT_ASSISTANT_ID ] || await loadAssistantById(DEFAULT_ASSISTANT_ID);
      if (!defaultAssistant) {
        return null;
      }

      const date = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      const newAssistantName = `${userName}'s Assistant (${date})`;

      const newAssistantReq = new CreateAssistantRequest({
        displayName: newAssistantName,
        description: defaultAssistant.description,
        engine: defaultAssistant.engine,
        model: defaultAssistant.model,
        instructions: defaultAssistant.instructions,
        modelSettings: defaultAssistant.modelSettings?.clone(),
        retrievalSettings: defaultAssistant.retrievalSettings?.clone(),
        subAssistants: [...defaultAssistant.subAssistants],
        subAssistantsAsTools: defaultAssistant.subAssistantsAsTools,
        tools: defaultAssistant.tools.map((tool) => tool.clone()),
        responseJsonSchema: defaultAssistant.responseJsonSchema?.clone(),
      });

      const { client, headers } = getApiClient();

      const newAssistant = await client.createAssistant(
        newAssistantReq,
        { headers },
      );

      get().setAssistants([ newAssistant ]);
      set({
        newAssistants: [ ...get().newAssistants, newAssistant.id ],
      });

      return newAssistant.id;
    }
    catch (e) {
      console.error(e);
    }

    return null;
  },
  deleteAssistant: async (assistantId: string) => {
    try {
      const { client, headers } = getApiClient();

      const request = new GetAssistantRequest({
        id: assistantId,
      });

      set((state) => {
        const assistants = { ...state.assistants };
        delete assistants[ assistantId ];

        return {
          assistants,
          newAssistants: state.newAssistants.filter((id) => id !== assistantId),
        };
      });
      get().updateSearchIndex();

      await client.deleteAssistant(request, { headers });
    } catch (e) {
      console.error(e);
    }
  },
  deleteNewAssistant: (assistantId: string) => {
    if (!get().newAssistants.includes(assistantId)) {
      return;
    }

    get().deleteAssistant(assistantId);
  },
  saveAssistant: async (assistant: Assistant) => {
    try {
      const { client, headers } = getApiClient();

      const request = new UpdateAssistantRequest({
        id: assistant.id,
        displayName: assistant.displayName,
        description: assistant.description,
        storeId: assistant.storeId,
        engine: assistant.engine,
        model: assistant.model,
        instructions: assistant.instructions,
        modelSettings: assistant.modelSettings,
        retrievalSettings: assistant.retrievalSettings,
        subAssistants: new StringList({
          values: assistant.subAssistants,
        }),
        subAssistantsAsTools: assistant.subAssistantsAsTools,
        uploaders: new UpdateAssistantRequest_UploaderList({
          values: assistant.uploaders,
        }),
        viewers: new UpdateAssistantRequest_ViewerList({
          values: assistant.viewers,
        }),
        owners: new UpdateAssistantRequest_OwnerList({
          values: assistant.owners,
        }),

      });

      client.updateAssistant(request, { headers });
      useAssistantFiles.getState().updateStore(assistant);
    } catch (e) {
      console.error(e);
    }
  },
  cloneAssistant: async (assistantId: string, name: string) => {
    try {
      const { client, headers } = getApiClient();

      const assistant = get().assistants[ assistantId ];
      if (!assistant) {
        return null;
      }

      const newAssistantReq = new CreateAssistantRequest({
        displayName: name || `Copy of ${assistant.displayName}`,
        description: assistant.description,
        engine: assistant.engine,
        model: assistant.model,
        instructions: assistant.instructions,
        modelSettings: assistant.modelSettings?.clone(),
        retrievalSettings: assistant.retrievalSettings?.clone(),
        subAssistants: [...assistant.subAssistants],
        subAssistantsAsTools: assistant.subAssistantsAsTools,
        tools: assistant.tools.map((tool) => tool.clone()),
        responseJsonSchema: assistant.responseJsonSchema?.clone(),
      });

      const newAssistant = await client.createAssistant(
        newAssistantReq,
        { headers },
      );

      get().setAssistants([ newAssistant ]);
      set({
        newAssistants: [ ...get().newAssistants, newAssistant.id ],
      });

      return newAssistant.id;
    }
    catch (e) {
      console.error(e);
    }
    return null;
  },
  checkPermissions: async (assistantId) => {
    if (get().permissions[ assistantId ]) {
      return;
    }

    try {
      const { client, headers } = getApiClient();
      const request = new TestAssistantPermissionsRequest({
        id: assistantId,
        permissions: [
          ASSISTANT_PEPRMISSIONS.assistantsUpdate,
          ASSISTANT_PEPRMISSIONS.filesUpload,
        ],
      });

      const resp = await client.testAssistantPermissions(request, { headers });


      const assistantPermissions = {
        canEdit: resp.permissions.includes(ASSISTANT_PEPRMISSIONS.assistantsUpdate),
        canUpload: resp.permissions.includes(ASSISTANT_PEPRMISSIONS.filesUpload),
      };

      set((state) => {
        const permissions = { ...state.permissions };
        permissions[ assistantId ] = assistantPermissions;
        return { permissions };
      });
    }
    catch (e) {
      console.error(e);
    }
  },
}));




const loadAssistantById = async (assistantId: string): Promise<Assistant | null> => {
  try {
    const { client, headers } = getApiClient();

    const getAssistantReq = new GetAssistantRequest({
      id: assistantId,
    });
    const assistant = await client.getAssistant(getAssistantReq, { headers });
    return assistant;
  }
  catch (e) {
    console.error(e);
    return null;
  }

}