// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/rag/v0/stores.proto (package ai.rag.v0, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// buf:lint:ignore PACKAGE_VERSION_SUFFIX

import { ListIndexFilesRequest, ListIndexFilesResponse } from "./stores_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Stores is a service for managing index stores and files within those stores.
 * buf:lint:ignore SERVICE_SUFFIX
 *
 * @generated from service ai.rag.v0.Stores
 */
export const Stores = {
  typeName: "ai.rag.v0.Stores",
  methods: {
    /**
     * @generated from rpc ai.rag.v0.Stores.ListIndexFiles
     */
    listIndexFiles: {
      name: "ListIndexFiles",
      I: ListIndexFilesRequest,
      O: ListIndexFilesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

