// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file slack/slack_config.proto (package slack, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message slack.GetConfigRequest
 */
export class GetConfigRequest extends Message<GetConfigRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<GetConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "slack.GetConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConfigRequest {
    return new GetConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConfigRequest {
    return new GetConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConfigRequest {
    return new GetConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConfigRequest | PlainMessage<GetConfigRequest> | undefined, b: GetConfigRequest | PlainMessage<GetConfigRequest> | undefined): boolean {
    return proto3.util.equals(GetConfigRequest, a, b);
  }
}

/**
 * @generated from message slack.GetConfigResponse
 */
export class GetConfigResponse extends Message<GetConfigResponse> {
  /**
   * @generated from field: slack.Config config = 1;
   */
  config?: Config;

  /**
   * @generated from field: slack.RequestDetail detail = 2;
   */
  detail?: RequestDetail;

  constructor(data?: PartialMessage<GetConfigResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "slack.GetConfigResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "config", kind: "message", T: Config },
    { no: 2, name: "detail", kind: "message", T: RequestDetail },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConfigResponse {
    return new GetConfigResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConfigResponse {
    return new GetConfigResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConfigResponse {
    return new GetConfigResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConfigResponse | PlainMessage<GetConfigResponse> | undefined, b: GetConfigResponse | PlainMessage<GetConfigResponse> | undefined): boolean {
    return proto3.util.equals(GetConfigResponse, a, b);
  }
}

/**
 * @generated from message slack.Alias
 */
export class Alias extends Message<Alias> {
  /**
   * @generated from field: string assistant_id = 2;
   */
  assistantId = "";

  constructor(data?: PartialMessage<Alias>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "slack.Alias";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Alias {
    return new Alias().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Alias {
    return new Alias().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Alias {
    return new Alias().fromJsonString(jsonString, options);
  }

  static equals(a: Alias | PlainMessage<Alias> | undefined, b: Alias | PlainMessage<Alias> | undefined): boolean {
    return proto3.util.equals(Alias, a, b);
  }
}

/**
 * @generated from message slack.Config
 */
export class Config extends Message<Config> {
  /**
   * @generated from field: string assistant_id = 5;
   */
  assistantId = "";

  /**
   * @generated from field: bool respond_to_all_conversations = 1;
   */
  respondToAllConversations = false;

  /**
   * @generated from field: map<string, slack.Alias> aliases = 6;
   */
  aliases: { [key: string]: Alias } = {};

  /**
   * @generated from field: google.protobuf.Timestamp last_update = 7;
   */
  lastUpdate?: Timestamp;

  /**
   * @generated from field: bool disable_functions = 8;
   */
  disableFunctions = false;

  /**
   * @generated from field: string store_id = 2 [deprecated = true];
   * @deprecated
   */
  storeId = "";

  /**
   * @generated from field: string prompt = 3 [deprecated = true];
   * @deprecated
   */
  prompt = "";

  /**
   * @generated from field: string mode = 4 [deprecated = true];
   * @deprecated
   */
  mode = "";

  constructor(data?: PartialMessage<Config>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "slack.Config";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 5, name: "assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "respond_to_all_conversations", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "aliases", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Alias} },
    { no: 7, name: "last_update", kind: "message", T: Timestamp },
    { no: 8, name: "disable_functions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "store_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "prompt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Config {
    return new Config().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Config {
    return new Config().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Config {
    return new Config().fromJsonString(jsonString, options);
  }

  static equals(a: Config | PlainMessage<Config> | undefined, b: Config | PlainMessage<Config> | undefined): boolean {
    return proto3.util.equals(Config, a, b);
  }
}

/**
 * @generated from message slack.RequestDetail
 */
export class RequestDetail extends Message<RequestDetail> {
  /**
   * @generated from field: string slackbot_id = 3;
   */
  slackbotId = "";

  /**
   * @generated from field: string channel_id = 5;
   */
  channelId = "";

  /**
   * @generated from field: string slackbot_name = 4;
   */
  slackbotName = "";

  /**
   * @generated from field: string channel_name = 6;
   */
  channelName = "";

  /**
   * @generated from field: string default_assistant_id = 8;
   */
  defaultAssistantId = "";

  constructor(data?: PartialMessage<RequestDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "slack.RequestDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "slackbot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "slackbot_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "channel_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "default_assistant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestDetail {
    return new RequestDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestDetail {
    return new RequestDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestDetail {
    return new RequestDetail().fromJsonString(jsonString, options);
  }

  static equals(a: RequestDetail | PlainMessage<RequestDetail> | undefined, b: RequestDetail | PlainMessage<RequestDetail> | undefined): boolean {
    return proto3.util.equals(RequestDetail, a, b);
  }
}

/**
 * @generated from message slack.SetConfigRequest
 */
export class SetConfigRequest extends Message<SetConfigRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: slack.Config config = 2;
   */
  config?: Config;

  constructor(data?: PartialMessage<SetConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "slack.SetConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "config", kind: "message", T: Config },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetConfigRequest {
    return new SetConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetConfigRequest {
    return new SetConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetConfigRequest {
    return new SetConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetConfigRequest | PlainMessage<SetConfigRequest> | undefined, b: SetConfigRequest | PlainMessage<SetConfigRequest> | undefined): boolean {
    return proto3.util.equals(SetConfigRequest, a, b);
  }
}

