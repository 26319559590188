import React, { useEffect, useRef, useState } from "react";
import AppPage from "../components/AppFrame/AppPage";
import { useBeforeUnload, useNavigate, useParams } from "react-router-dom";
import AppPageHeader from "../components/AppFrame/AppPageHeader";
import { Button, FormControl, FormLabel, HStack, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Tooltip } from "@chakra-ui/react";
import { MdAddCircle, MdDelete, MdMoreHoriz } from "react-icons/md";
import ManageAssistantForm from "../components/ManageAssistantForm/ManageAssistantForm";
import { useAssistants } from "../state/assistants";
import { useDialog } from "../components/DialogProvider";



const EditAssistantPage: React.FC = () => {
  const { assistantId: assistantIdParam } = useParams<{ assistantId: string }>();
  const assistants = useAssistants();
  const navigate = useNavigate();
  const dialog = useDialog();

  const assistantId = assistantIdParam?.trim();

  const originalAssistant = assistants.assistants[ assistantId ?? '' ] ?? null;

  const cloneInitialRef = useRef(null)
  const [ cloneDialogOpen, setCloneDialogOpen ] = useState(false)
  const [ cloneAssistantName, setCloneAssistantName ] = useState('');
  const [ clonePending, setClonePending ] = useState(false);

  const closeCloneDialog = () => setCloneDialogOpen(false);
  const openCloneDialog = () => setCloneDialogOpen(true);

  const [ assistant, setAssistant ] = useState(originalAssistant?.clone());

  const isLoading = assistants.loadingById[ assistantId ?? '' ];



  const mountTime = useRef(Date.now());


  const isNew = assistantId ? assistants.newAssistants.includes(assistantId) : false;

  useEffect(() => {
    if (assistantId) {
      assistants.loadById({assistantId});
      assistants.checkPermissions(assistantId);
    }
    // eslint-disable-next-line
  }, [ assistantId ]);

  useBeforeUnload(
    React.useCallback(() => {
      console.log('before unload');
    }, [])
  );

  useEffect(() => {

    mountTime.current = Date.now();

    return () => {
      if (Date.now() - mountTime.current < 1000) {
        return;
      }

      if (isNew && assistantId) {
        assistants.deleteNewAssistant(assistantId);
      }
    }

    // eslint-disable-next-line
  }, [ isNew ]);


  useEffect(() => {
    if (originalAssistant) {
      setAssistant(originalAssistant.clone());
    }

  }, [ originalAssistant, isLoading ]);

  // useEffect(() => {
  //   setOriginalAssistant(assistants.assistants[ assistantId ?? '' ] ?? null);
  // }, [ assistants.assistants, assistantId ]);

  const onCancel = () => {
    if (isNew && assistantId) {
      navigate(-1);
    }
    else {
      navigate('/assistants', { replace: true });
    }
  }

  const onDelete = () => {
    if (assistantId) {
      dialog.confirm(
        {
          title: 'Delete Assistant',
          subtitle: 'Are you sure you want to delete this assistant?',
          onConfirm: () => {
            assistants.deleteAssistant(assistantId);
            navigate('/assistants', { replace: true });
          }
        }
      );

    }
  }

  const onSave = () => {
    assistants.setAssistants([ assistant ]);
    assistants.saveAssistant(assistant);
  }

  const onClone = async () => {
    setClonePending(true);
    const newId = await assistants.cloneAssistant(assistantId ?? '', cloneAssistantName);
    setClonePending(false);
    closeCloneDialog();
    if (newId) {
      navigate(`/assistants/${newId}`, { replace: true });
    }
  }

  const modified = !assistant?.equals(originalAssistant) || isNew;

  const isOwner = assistants.permissions[ assistantId ?? '' ]?.canEdit ?? false;
  const isUploader = assistants.permissions[ assistantId ?? '' ]?.canUpload ?? false;

  return (
    <AppPage isLoading={assistants.loadingById[ assistantId ?? '' ]} maxWidth={1200} p={4}>
      <AppPageHeader
        title={isNew ? 'Create assistant' : 'Edit assistant'}
        actions={(
          <HStack>
            {
              !isNew && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<MdMoreHoriz />}
                    variant='ghost'
                    size="sm"
                  />
                  <MenuList>
                    <MenuItem icon={<MdAddCircle />} onClick={openCloneDialog}>
                      Clone Assistant
                    </MenuItem>
                    <MenuItem icon={<MdDelete />} onClick={onDelete} isDisabled={!isOwner}>
                      Delete Assistant
                    </MenuItem>
                  </MenuList>
                </Menu>
              )
            }
            <Button onClick={onCancel} size="sm">
              {
                modified ? 'Cancel' : 'Back'
              }
            </Button>
            <Tooltip label={
              !isOwner ? 'You do not have permissions to modify this assistant' : !modified ? 'No changes have been made.' : null}>
              <Button
                isDisabled={!modified || (!isOwner && !isUploader)}
                colorScheme="primary"
                color="black"
                onClick={onSave}
                size="sm"
              >
                {
                  isNew ? 'Save' : 'Save Changes'
                }
              </Button>
            </Tooltip>
          </HStack>
        )}
      />

      {
        assistant && (
          <ManageAssistantForm
            key={assistantId}
            data={assistant}
            isUploader={isUploader}
            isOwner={isOwner}
            onUpdate={(assistant) => {
              setAssistant(assistant);
            }}
          />
        )
      }

      <Modal
        initialFocusRef={cloneInitialRef}
        isOpen={cloneDialogOpen}
        onClose={closeCloneDialog}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Clone assistant</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>New Assistant Name</FormLabel>
              <Input
                isDisabled={clonePending}
                ref={cloneInitialRef}
                placeholder={`Copy of ${assistant?.displayName}`}
                value={cloneAssistantName}
                onChange={(e) => setCloneAssistantName(e.target.value)}
              />
            </FormControl>

            <Text mt={6} color="red">
              ⚠ This will NOT copy files, you will have to upload any files you want to use with this new assistant.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='primary'
              mr={3}
              color="black"
              isLoading={clonePending}
              onClick={onClone}
            >
              Clone
            </Button>
            <Button onClick={closeCloneDialog}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AppPage>
  );
};

export default EditAssistantPage;