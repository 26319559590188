// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/stores.proto (package ai, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateStoreRequest, DeleteFileRequest, DeleteStoreRequest, File, GetFileRequest, GetStoreRequest, ListFilesRequest, ListFilesResponse, ListStoresRequest, ListStoresResponse, Store, TestStorePermissionsRequest, TestStorePermissionsResponse, UpdateStoreRequest, UploadFileEvent, UploadFileRequest, UploadFileUnaryResponse } from "./stores_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * Stores is a service for managing file stores
 *
 * @generated from service ai.Stores
 */
export const Stores = {
  typeName: "ai.Stores",
  methods: {
    /**
     * Creates a new store for users to upload files to
     *
     * @generated from rpc ai.Stores.CreateStore
     */
    createStore: {
      name: "CreateStore",
      I: CreateStoreRequest,
      O: Store,
      kind: MethodKind.Unary,
    },
    /**
     * Gets the current state of a store
     *
     * @generated from rpc ai.Stores.GetStore
     */
    getStore: {
      name: "GetStore",
      I: GetStoreRequest,
      O: Store,
      kind: MethodKind.Unary,
    },
    /**
     * Lists the available stores
     *
     * @generated from rpc ai.Stores.ListStores
     */
    listStores: {
      name: "ListStores",
      I: ListStoresRequest,
      O: ListStoresResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Updates the state of a store
     *
     * @generated from rpc ai.Stores.UpdateStore
     */
    updateStore: {
      name: "UpdateStore",
      I: UpdateStoreRequest,
      O: Store,
      kind: MethodKind.Unary,
    },
    /**
     * Deletes a store and all of its files
     *
     * @generated from rpc ai.Stores.DeleteStore
     */
    deleteStore: {
      name: "DeleteStore",
      I: DeleteStoreRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Initiates the process of uploading a file to a store
     * The server will respond with an event indicating where the file should be uploaded
     *
     * @generated from rpc ai.Stores.UploadFile
     */
    uploadFile: {
      name: "UploadFile",
      I: UploadFileRequest,
      O: UploadFileEvent,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Initiates the process of uploading a file to a store
     *
     * @generated from rpc ai.Stores.UploadFileUnary
     */
    uploadFileUnary: {
      name: "UploadFileUnary",
      I: UploadFileRequest,
      O: UploadFileUnaryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Gets the current state of a file in a store
     *
     * @generated from rpc ai.Stores.GetFile
     */
    getFile: {
      name: "GetFile",
      I: GetFileRequest,
      O: File,
      kind: MethodKind.Unary,
    },
    /**
     * Lists the files in a store
     *
     * @generated from rpc ai.Stores.ListFiles
     */
    listFiles: {
      name: "ListFiles",
      I: ListFilesRequest,
      O: ListFilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Deletes a file from a store
     *
     * @generated from rpc ai.Stores.DeleteFile
     */
    deleteFile: {
      name: "DeleteFile",
      I: DeleteFileRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Returns permissions that a call has on the specified Store.
     * If the resource does not exist, the response will return an empty set of
     * permissions, not a `NOT_FOUND` error.
     *
     * @generated from rpc ai.Stores.TestStorePermissions
     */
    testStorePermissions: {
      name: "TestStorePermissions",
      I: TestStorePermissionsRequest,
      O: TestStorePermissionsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

