import { create } from "zustand"
import { getApiClient } from "../api";
import { Index } from "flexsearch-ts";
import { Model } from "../gen-ts/ai/engine_pb";
import { ListEnginesRequest } from "../gen-ts/ai/assistants/v0/assistant_pb";
import { ModelFilter } from "../types";


interface ModelsState {
  loading: boolean;
  load: () => void;
  models: {
    [ modelId: string ]: Model
  },
  modelEngines: {
    [ modelId: string ]: string
  },
  searchIndex: Index,
  updateSearchIndex: () => void,
  filters: ModelFilter[],
  toggleFilter: (filter: ModelFilter) => void,
}



export const useModels = create<ModelsState>((set, get) => ({
  loading: false,
  models: {},
  modelEngines: {},
  filters: [],
  toggleFilter: (filter: ModelFilter) => {
    const filters = get().filters;
    const index = filters.indexOf(filter);
    if (index === -1) {
      filters.push(filter);
    }
    else {
      filters.splice(index, 1);
    }
    set({ filters: [ ...filters ] });
  },
  load: async () => {
    if (get().loading || Object.values(get().models).length > 0) {
      return;
    }

    set({ loading: true });
    try {

      const { client, headers } = getApiClient();

      const resp = await client.listEngines(new ListEnginesRequest(), { headers });

      const models: { [ modelId: string ]: Model } = {};
      const modelEngines: { [ modelId: string ]: string } = {};

      for (const engineInfo of resp.engines) {
        for (const model of engineInfo.models) {
          modelEngines[ model.name ] = engineInfo.engine;
          models[ model.name ] = model;
        }
      }
      set({ modelEngines, models });
      get().updateSearchIndex();
    }
    catch (e) {
      console.error(e);
    }
    set({ loading: false });
  },
  searchIndex: new Index({
    tokenize: 'full',
  }),
  updateSearchIndex: () => {
    const modelEngines = get().modelEngines;
    const models = get().models;

    for (const modelId in models) {
      const engineId = modelEngines[ modelId ];
      const id = `${engineId}#${modelId}`;
      const content = `${engineId} ${models[ modelId ].name}`;
      get().searchIndex.add(id, content);
    }
  },
}));

