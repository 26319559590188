import { Box, Button, Center, Divider, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useClipboard, useToast, VStack } from "@chakra-ui/react";
import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import ToastMessage from "./ToastMessage";
import { MdOutlineContentCopy } from "react-icons/md";
import { useUserState } from "../state/user";
import { useAssistants } from "../state/assistants";
import { useParams } from "react-router-dom";
import { useChats } from "../state/chats";

interface Props {
  open: boolean;
  onClose: () => void;
  debugContext?: DebugContext;
}
const DebugDialog: React.FC<Props> = ({ open, onClose, debugContext }) => {
  const toast = useToast();
  const user = useUserState();
  const assistants = useAssistants();
  const chats = useChats();
  const params = useParams();



  const appVersion = require('../../package.json').version;

  let text = `App Version:$: ${appVersion}
Time:$: ${new Date().toLocaleString()}
Name:$: ${user.user?.name}
Email:$: ${user.user?.email}
Default assistant:$: ${assistants.selectedAssistant}
URL:$: ${window.location.pathname}`;

  if (params.assistantId) {
    text += `\nAssistant ID:$: ${params.assistantId}`;
    const assistant = assistants.assistants[ params.assistantId ];
    if (assistant) {
      text += `\nStore ID:$: ${assistant.storeId}`;
    }
  }
  if (params.threadId) {
    text += `\nThread ID:$: ${params.threadId}`;
    const chat = chats.chats[ params.threadId ];
    if (chat) {
      text += `\nThread Assistant:$: ${chat.assistantId}`;
    }
  }

  if (debugContext) {
    for (const [ key, value ] of Object.entries(debugContext)) {
      text += `\n${key}:$: ${value}`;
    }
  }

  const getCopyContent = () => {
    return text.replace(/:\$:/g, ':');
  }

  const { onCopy } = useClipboard(getCopyContent());




  const onCopyToClipboard = () => {
    onCopy();
    toast({
      position: 'top-right',
      render: (props) => {
        return (
          <ToastMessage
            message="Copied to clipboard."
            status="success"
            isCLosable={props.isClosable}
            onClose={props.onClose}
          />
        );
      },
      duration: 1000,
      isClosable: true,
    });
  }

  const lines = text.split('\n').filter((s) => {
    return s.trim() !== '';
  });

  return (
    <Modal isOpen={open} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Debug Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <VStack align="start">
              {
                lines.map((line, index) => {
                  const [ label, value ] = line.split(':$:');
                  if (!label || !value) {
                    return null;
                  }

                  let item = (
                    <HStack key={index} spacing={5} maxWidth="520px">
                      <Box width="140px" flexShrink={0} display="flex" justifyContent="end">
                        <Text isTruncated textOverflow="ellipsis" fontWeight="bold">{label}:</Text>
                      </Box>
                      <Text isTruncated textOverflow="ellipsis">{value}</Text>
                    </HStack>
                  );

                  if (index === 6 && lines.length >= 6) {
                    item = (
                      <VStack key="diivider" width="100%" align="start">
                        <Divider />
                        {item}
                      </VStack>
                    )
                  }

                  return item;
                })
              }
            </VStack>
          </Center>
        </ModalBody>

        <ModalFooter>
          <Button
            size="sm"
            onClick={onCopyToClipboard}
            leftIcon={<MdOutlineContentCopy />}
          >
            Copy to Clipboard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type DebugContext = { [ key: string ]: string };

interface IDebugDialogContext {
  openDialog: (debugContext?: DebugContext) => void;
}

const Context = createContext<IDebugDialogContext>({
  openDialog: () => { },
});

const DebugDialogProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const [ debugContext, setDebugContext ] = useState<DebugContext>();


  const closeDialog = () => setIsOpen(false);

  const openDialog = (debugContext?: DebugContext) => {
    setDebugContext(debugContext);
    setIsOpen(true);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if only Ctrl+D is pressed (on all platforms, including macOS)
      if (event.ctrlKey && event.key === 'd') {
        event.preventDefault();
        openDialog();
      }
    };

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  return (
    <Context.Provider value={{ openDialog }}>
      {children}
      <DebugDialog open={isOpen} onClose={closeDialog} debugContext={debugContext} />
    </Context.Provider>
  )
}

export default DebugDialogProvider;

export const useDebugDialog = () => {
  return React.useContext(Context);
}