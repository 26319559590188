// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=ts,import_extension=none"
// @generated from file user/preferences/v0/preferences.proto (package user.preferences.v0, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Preferences, RemoveFavoriteRequest, SetDarkModeRequest, SetFavoriteRequest } from "./preferences_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service user.preferences.v0.UserPreferences
 */
export const UserPreferences = {
  typeName: "user.preferences.v0.UserPreferences",
  methods: {
    /**
     * @generated from rpc user.preferences.v0.UserPreferences.SetFavorite
     */
    setFavorite: {
      name: "SetFavorite",
      I: SetFavoriteRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc user.preferences.v0.UserPreferences.RemoveFavorite
     */
    removeFavorite: {
      name: "RemoveFavorite",
      I: RemoveFavoriteRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc user.preferences.v0.UserPreferences.SetDarkMode
     */
    setDarkMode: {
      name: "SetDarkMode",
      I: SetDarkModeRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc user.preferences.v0.UserPreferences.Get
     */
    get: {
      name: "Get",
      I: Empty,
      O: Preferences,
      kind: MethodKind.Unary,
    },
  }
} as const;

