import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react";
import { PropsWithChildren, createContext, useContext, useRef, useState } from "react";


interface IDialogProviderContext {
  type: string;
  confirm: (args: ConfirmArgs) => void;
  alert: (args: AlertArgs) => void;
  close: () => void;
  open: boolean;
  title: string;
  subtitle: string;
  okButton: string;
  cancelButton: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DialogProviderContext = createContext<IDialogProviderContext>({
  type: '',
  confirm: (args: ConfirmArgs) => { },
  alert: (args: AlertArgs) => { },
  close: () => { },
  open: false,
  title: '',
  subtitle: '',
  okButton: 'Ok',
  cancelButton: 'Cancel',
  onConfirm: () => { },
  onCancel: () => { },
});


interface ConfirmArgs {
  title: string;
  subtitle?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  okButton?: string;
  cancelButton?: string;
}

interface AlertArgs {
  title: string;
  subtitle?: string;
  onConfirm: () => void;
  okButton?: string;
}

const DialogProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ state, setState ] = useState({
    type: '',
    open: false,
    title: '',
    subtitle: '',
    onConfirm: () => { },
    onCancel: () => { },
    okButton: 'Ok',
    cancelButton: 'Cancel',
  })

  const confirm = (args: ConfirmArgs) => {
    setState({
      type: 'confirm',
      open: true,
      title: args.title,
      subtitle: args.subtitle || '',
      onConfirm: args.onConfirm,
      onCancel: args.onCancel || (() => { }),
      okButton: args.okButton || 'Ok',
      cancelButton: args.cancelButton || 'Cancel',
    });
  }

  const alert = (args: AlertArgs) => {
    setState({
      ...state,
      type: 'alert',
      open: true,
      title: args.title,
      subtitle: args.subtitle || '',
      onConfirm: args.onConfirm,
      okButton: args.okButton || 'Ok',
    });
  }

  const close = () => {
    setState({
      ...state,
      open: false
    });
  }


  const context: IDialogProviderContext = {
    confirm,
    alert,
    type: state.type,
    open: state.open,
    title: state.title,
    subtitle: state.subtitle,
    okButton: state.okButton,
    cancelButton: state.cancelButton,
    close,
    onConfirm: state.onConfirm,
    onCancel: state.onCancel,
  }

  return (
    <DialogProviderContext.Provider value={context}>
      {children}
    </DialogProviderContext.Provider>
  );
};

export default DialogProvider;

export const useDialog = () => {
  const context = useContext(DialogProviderContext);

  return {
    confirm: context.confirm,
    alert: context.alert,
  };
}


export const DialogConsumer: React.FC = () => {
  const state = useContext(DialogProviderContext);
  const cancelRef = useRef()


  const handleClose = () => {
    state.close();
  }

  const onConfirm = () => {
    state.onConfirm();
    handleClose();
  }

  const onCancel = () => {
    state.onCancel();
    handleClose();
  }

  return (
    <AlertDialog
      isOpen={state.open}
      leastDestructiveRef={cancelRef as any}
      onClose={handleClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {state.title}
          </AlertDialogHeader>

          {
            state.subtitle && (
              <AlertDialogBody>
                {state.subtitle}
              </AlertDialogBody>
            )
          }


          <AlertDialogFooter>
            {
              state.type === 'confirm' && (
                <Button ref={cancelRef as any} onClick={onCancel}>
                  {state.cancelButton}
                </Button>
              )
            }
            <Button colorScheme='red' onClick={onConfirm} ml={3}>
              {state.okButton}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}