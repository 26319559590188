import React from "react";

import AppPage from "../AppFrame/AppPage";
import AppPageHeader from "../AppFrame/AppPageHeader";
import { Box, Button, Flex, Skeleton, Text, VStack } from "@chakra-ui/react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { Assistant } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import { useAssistants } from "../../state/assistants";
import ChatInput from "./ChatInput";
import { useChats } from "../../state/chats";
import { ChatFile } from "../../types";


interface Props {
  assistantId: string;
  assistant: Assistant;
  onSendMessage: (message: string, files: ChatFile[]) => void;
}

const EmptyChat: React.FC<Props> = ({ assistant, assistantId, onSendMessage }) => {
  const assistantsState = useAssistants();
  const chatsState = useChats();

  return (
    <AppPage
      px={4}
      py={2}>
      <AppPageHeader
        onlyMobile
        title="New Chat"
        updateTitle={false}
      />
      <VStack
        width="100%"
        height="100%"
        flexGrow={1}
        align="center"
        justify="stretch"
      >
        <Box height="50px" />
        <AssistantNameEmptyMessages
          assistant={assistant}
          isLoading={assistantsState.loadingById[ assistantId ]}
        />
        <Box flexGrow={1} />
        <Flex width="100%" justify="center">
          <ChatInput
            onSend={onSendMessage}
            onStop={() => { }}
            status="idle"
            loading={assistantsState.loadingById[ assistantId ] || chatsState.loadingById}
            newChatPending={chatsState.newChatPending}
          />
        </Flex>
      </VStack>
    </AppPage>
  );
};


const AssistantNameEmptyMessages: React.FC<{ assistant: Assistant | null, isLoading: boolean }> = ({ assistant, isLoading }) => {
  if (isLoading) {
    return (
      <VStack>
        <Skeleton width="200px" height="30px" />
        <Skeleton width="250px" height="20px" />
      </VStack>
    )
  }

  return (
    <VStack>
      <Button
        variant="ghost"
        rightIcon={<MdKeyboardArrowDown />}
        size="lg" as={Link} to="/assistants"
        maxWidth={{
          base: '300px',
          lg: '100%',
        }}
        justifyContent="start"
        isTruncated
        display="block"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        textAlign="left"
        height="50px"
        lineHeight="50px"
      >
        {assistant?.displayName}
      </Button>
      <Box maxWidth="1000px">
        <Text fontSize={14}>{assistant?.description}</Text>
      </Box>
    </VStack>
  )
}

export default EmptyChat;